import {
	Box,
	Component,
	DataBindingProvider,
	DataGrid,
	DateFieldView,
	EditPage,
	FeedbackRenderer,
	Field,
	GenericCell,
	GenericPage,
	NavigateBackButton,
	Repeater,
	TextCell,
	TitleBar,
} from '@contember/admin'
import * as React from 'react'
import { EditButton } from '../../components'
import { Page } from '../../utils'

interface DataViewProps {
	data: {
		[label: string]: string | React.ReactNode
	}
}

const DataView = Component<DataViewProps>(
	({ data }) => (
		<dl style={{ display: 'grid', gridTemplateColumns: 'auto 1fr' }}>
			{Object.entries(data).map(([key, value]) => (
				<>
					<dt style={{ fontWeight: 'bold' }}>{key}:</dt>
					<dd>{typeof value === 'string' ? <Field field={value} /> : value}</dd>
				</>
			))}
		</dl>
	),
	'DataViewProps',
)

export const ServicebookPages = new Page(
	{ path: '/servicebookServiceItem' },
	(
		<GenericPage pageName="servicebookServiceItemList">
			<DataBindingProvider stateComponent={FeedbackRenderer}>
				<TitleBar>Předměty servisu</TitleBar>
				<DataGrid entities="ServicebookServiceItem" itemsPerPage={50}>
					<TextCell header="Navision Číslo" field="serviceItemNo" initialOrder="desc" />
					<TextCell header="Popis" field="externalItemDescription" />
					<TextCell header="Kód zákazníka" field="customerCardNo" />
					<GenericCell shrunk canBeHidden={false}>
						<EditButton pageName="servicebookServiceItem" size={'small'}>
							Detail
						</EditButton>
					</GenericCell>
				</DataGrid>
			</DataBindingProvider>
		</GenericPage>
	),
)

export const ServiceboookServiceItemPage = new Page(
	{ path: '/servicebook/:id' },
	(
		<EditPage
			pageName="servicebookServiceItem"
			entity="ServicebookServiceItem(id=$id)"
			rendererProps={{
				navigation: <NavigateBackButton to="servicebookServiceItemList">Výpis</NavigateBackButton>,
				title: 'Předmět servisu',
				persistButtonComponent: () => null,
			}}
		>
			<Box heading="Detail předmětu servisu">
				<Box heading="Identifikace">
					<DataView
						data={{
							'NAV Číslo předmětu servisu': <Field field="serviceItemNo" />,
							'NAV Číslo zákaznícké karty': <Field field="customerCardNo" />,
							'Datum prodeje': <DateFieldView field="salesDate" fallback="Neznámý / Zakoupeno mimo ER" />,
							'NAV Číslo prodejní faktury': <Field field="salesInvoiceNo" />,
						}}
					/>
				</Box>
				<Box heading="Popis">
					<DataView
						data={{
							Popis: <Field field="externalItemDescription" />,
							'Kód variantu': <Field field="variant.code" />,
						}}
					/>
				</Box>
			</Box>
			<Box heading="Webový uživatel">
				<DataView
					data={{
						Jméno: (
							<>
								<Field field="user.firstName" /> <Field field="user.lastName" />
							</>
						),
						Email: (
							<>
								<Field field="user.email" />
							</>
						),
						Telefon: <Field field="user.phone" />,
						'Číslo karty v Navision': <Field field="user.navisionCardNo" />,
						'Datum registrace': <DateFieldView field="user.registeredAt" />,
					}}
				/>
			</Box>
			<Repeater
				label="Servisní objednávky"
				field="servicebookServiceOrders"
				enableRemoving={false}
				enableAddingNew={false}
				initialEntityCount={0}
				orderBy={undefined}
			>
				<Box>
					<Field field="serviceOrderNo" />
					<DataView
						data={{
							'NAV Číslo servisní faktury': <Field field="invoiceNo" />,
							'NAV Číslo zákaznické karty': <Field field="customerCardNo" />,
							'NAV Email': <Field field="customerEmail" />,
							'NAV Telefon': <Field field="customerPhone" />,
							Stav: <Field field="state" />,
							'ETA Dokončení': <DateFieldView field="expectedFinishingDate" />,
							'Notifikace o převzetí odeslána': <DateFieldView field="pickupNotificationLastSentAt" />,
						}}
					/>
				</Box>
			</Repeater>
			<Box heading="Poslední synchronizace">
				<DataView
					data={{
						Datum: <DateFieldView field="lastSyncedAt" />,
						Typ: (
							<Field
								field="isGlobalSync"
								format={(value) => {
									return value ? 'Globální' : 'Pro jednoho uživatele'
								}}
							/>
						),
					}}
				/>
			</Box>
		</EditPage>
	),
)

import {
	Checkbox,
	Component,
	DataGridCellPublicProps,
	DataGridColumn,
	DataGridHeaderCellPublicProps,
	Field,
	Filter,
	QueryLanguage,
	SugarableRelativeSingleField,
	useField,
	wrapFilterInHasOnes,
} from '@contember/admin'
import cn from 'classnames'
import { FunctionComponent, useCallback } from 'react'

export type ActiveCellProps = DataGridHeaderCellPublicProps & DataGridCellPublicProps & ActiveFieldProps
interface ActiveFieldProps {
	fromField: string | SugarableRelativeSingleField
	toField: string | SugarableRelativeSingleField
}
const ActiveField = Component<ActiveFieldProps>(
	(props) => {
		const from = useField<string>(props.fromField).value
		const to = useField<string>(props.toField).value
		const fromDate = from ? new Date(from) : null
		const toDate = to ? new Date(to) : null
		const fromActive = !fromDate || fromDate < new Date()
		const toActive = !toDate || toDate > new Date()
		const active = fromActive && toActive
		const futureActive = !fromActive && toActive
		const dateFormat = (fromDate?.toLocaleDateString() ?? 'n/a') + ' - ' + (toDate?.toLocaleDateString() ?? 'n/a')

		return (
			<span
				className={cn('activeField', active ? 'is-active' : futureActive ? 'is-future-active' : 'is-inactive')}
			>
				<span className={'activeField-content'}>
					{active ? 'Aktivní' : futureActive ? 'Připraveno' : 'Neaktivní'}{' '}
				</span>
				<span className={'activeField-date'}>{dateFormat}</span>
			</span>
		)
	},
	(props) => (
		<>
			<Field field={props.fromField} />
			<Field field={props.toField} />
		</>
	),
)
interface FilterArtifact {
	active: boolean
	inactive: boolean
	futureActive: boolean
}

export const ActiveDateCell: FunctionComponent<ActiveCellProps> = Component((props) => {
	return (
		<DataGridColumn<FilterArtifact>
			{...props}
			enableOrdering={false}
			enableFiltering={true}
			emptyFilter={{ active: true, futureActive: true, inactive: true }}
			filterRenderer={({ filter: filter, setFilter }) => {
				const toggleActive = useCallback(
					(type: keyof FilterArtifact, isChecked: boolean) =>
						setFilter({
							...filter,
							[type]: isChecked,
						}),
					[filter, setFilter],
				)
				return (
					<>
						<Checkbox value={filter.active} onChange={(val) => toggleActive('active', val)}>
							Aktivní
						</Checkbox>
						<Checkbox value={filter.futureActive} onChange={(val) => toggleActive('futureActive', val)}>
							Připraveno
						</Checkbox>
						<Checkbox value={filter.inactive} onChange={(val) => toggleActive('inactive', val)}>
							Neaktivní
						</Checkbox>
					</>
				)
			}}
			getNewFilter={(filter, { environment }) => {
				if (filter.active && filter.inactive && filter.futureActive) {
					return {}
				}
				const desugaredFrom = QueryLanguage.desugarRelativeSingleField(props.fromField, environment)
				const fromActiveFilter: Filter = wrapFilterInHasOnes(desugaredFrom.hasOneRelationPath, {
					[desugaredFrom.field]: {
						or: [{ isNull: true }, { lte: 'now' }],
					},
				})
				const desugaredTo = QueryLanguage.desugarRelativeSingleField(props.toField, environment)
				const toActiveFilter: Filter = wrapFilterInHasOnes(desugaredFrom.hasOneRelationPath, {
					[desugaredTo.field]: {
						or: [{ isNull: true }, { gte: 'now' }],
					},
				})
				let whereOr: Filter[] = []
				if (filter.active) {
					whereOr.push({ and: [fromActiveFilter, toActiveFilter] })
				}
				if (filter.futureActive) {
					whereOr.push({ and: [{ not: fromActiveFilter }, toActiveFilter] })
				}
				if (filter.inactive) {
					whereOr.push({
						[desugaredTo.field]: { lte: 'now' },
					})
				}

				return { or: whereOr }
			}}
		>
			<ActiveField fromField={props.fromField} toField={props.toField} />
		</DataGridColumn>
	)
}, 'GenericCell')

import { Component, FileUploadField, HasOne, HasOneProps } from '@contember/admin'
import * as React from 'react'

export interface FileFieldProps {
	field: string
	label: HasOneProps['field']
}

export const FileField = Component<FileFieldProps>((props: FileFieldProps) => {
	return (
		<HasOne field={props.field}>
			<FileUploadField
				label={props.label}
				field="url"
				fileNameField="name"
				fileTypeField="type"
				fileSizeField="size"
			/>
		</HasOne>
	)
})

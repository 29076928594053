import { Component, FileRepeater, FormGroupProps, getImageFileDefaults, HasOne } from '@contember/admin'
import * as React from 'react'

export interface ImageListFieldProps {
	field: string
	label?: FormGroupProps['label']
}

export const ImageListField = Component<ImageListFieldProps>((props) => (
	<HasOne field={props.field}>
		<FileRepeater
			sortableBy="order"
			field="items"
			removalType="delete"
			label={props.label}
			imageFileUrlField="image.url"
			imageWidthField="image.width"
			imageHeightField="image.height"
			{...getImageFileDefaults('image.url')}
		/>
	</HasOne>
))

import {
	Box,
	Button,
	CheckboxField,
	Component,
	Field,
	HasMany,
	MultiSelectField,
	Repeater,
	SelectField,
	TextInput,
	useCurrentContentGraphQlClient,
	useEntity,
	useEntityBeforePersist,
	useEntityList,
	useEntityListSubTree,
	ErrorList,
	DeferredSubTrees,
} from '@contember/admin'
import { CentsField, CollapsibleBox, DependentCollapsible, LoadingMessage } from '../Generic'
import { CategoryMultiSelectField } from '../Category'
import { LocaleMultiSelectField } from '../Site'
import * as React from 'react'
import { ProductMultiSelectField } from './ProductListField'
import { useCallback, useState } from 'react'

const useLoadProductsBySku = () => {
	const client = useCurrentContentGraphQlClient()
	return useCallback(
		async (codes: string[]) => {
			const cond = codes
				.filter((code: string) => code.length >= 6)
				.map((whiteListedCode) => {
					return { startsWith: whiteListedCode }
				})
			if (cond.length === 0) return []

			const result = await client.sendRequest<{
				data: {
					products: {
						id: string
					}[]
				}
			}>(
				`query($code: StringCondition!) {
					products: listProduct(filter: {variants: {skus: {sku: $code}}}) {
						id
					}
				}`,
				{ variables: { code: { or: cond } } },
			)
			return result.data.products.map((it) => it.id)
		},
		[client],
	)
}

const productListOptions = {
	entities: {
		entityName: 'Product',
		filter: {
			variants: {
				states: {
					visibleInListing: undefined,
				},
			},
		},
	},
}
const SkuProductInput: React.FC = () => {
	const [skus, setSkus] = useState('')
	const loadProducts = useLoadProductsBySku()
	const productsSelect = useEntityList('products')
	const productsToAdd = useEntityListSubTree(productListOptions)
	const processSkus = useCallback(async () => {
		const skusEntries = skus
			.split('\n')
			.map((it) => it.trim())
			.filter((it) => !!it)
		const productIds = await loadProducts(skusEntries)
		productsSelect.batchUpdates((getAccessor) => {
			const accessor = getAccessor()
			for (const id of productIds) {
				if (productsToAdd.hasEntityId(id)) {
					accessor.connectEntity(productsToAdd.getChildEntityById(id))
				}
			}
		})
		setSkus('')
	}, [loadProducts, productsSelect, skus, productsToAdd])

	return (
		<CollapsibleBox heading={'Vložit SKU'}>
			<TextInput
				value={skus}
				onChange={(e) => setSkus(e.currentTarget.value)}
				allowNewlines={true}
				placeholder={
					'Jedno SKU na řádek. SKU pod 6 znaků bude ignorováno. V případě zadání neúplného SKU budou přidány všechny produkty, kterých SKU na zadaný řetězec začínají.'
				}
			/>
			<Button onClick={processSkus}>Vložit</Button>
		</CollapsibleBox>
	)
}

export const ProductRuleFormValidator = () => {
	const entity = useEntity()
	const [ignore, setIgnore] = useState(false)
	useEntityBeforePersist(
		useCallback(
			(getAccessor) => {
				const entity = getAccessor()
				if (ignore || !entity.hasUnpersistedChanges) {
					return
				}
				const anyChecked = [
					'isPriceRule',
					'isCategoryRule',
					'isBrandRule',
					'isTagRule',
					'isAttributeRule',
					'isExactProductRule',
				].some((it) => entity.getField<boolean>(it).value)
				if (!anyChecked) {
					entity.addError('Vyberte alespoň jedno pravidlo, nebo celou položku odstraňte.')
				}
			},
			[ignore],
		),
	)
	if (entity.errors?.validation?.length && !ignore) {
		return (
			<>
				<ErrorList errors={entity.errors?.validation} />
				<Button onClick={() => setIgnore(true)}>Ignorovat chybu</Button>
			</>
		)
	}

	return null
}

export const ProductRuleForm = Component((props) => (
	<>
		<ProductRuleFormValidator />
		<Box>
			<CheckboxField field={'isPriceRule'} label={'Dle ceny'} defaultValue={false} />
			<DependentCollapsible field={'isPriceRule'} condition={(it) => !!it}>
				<Repeater field={'prices'} label={undefined} orderBy={undefined}>
					<SelectField label={'Měna'} options={'Currency.code'} field={'currency'} />
					<CentsField field={'minCents'} label={'Minimálně'} />
					<CentsField field={'maxCents'} label={'Maximálně'} />
				</Repeater>
			</DependentCollapsible>
		</Box>
		<Box>
			<CheckboxField field={'isCategoryRule'} label={'Dle kategorie'} defaultValue={false} />
			<DependentCollapsible field={'isCategoryRule'} condition={(it) => !!it}>
				<CategoryMultiSelectField field={'categories'} label={undefined} />
			</DependentCollapsible>
		</Box>
		<Box>
			<CheckboxField field={'isBrandRule'} label={'Dle značky'} defaultValue={false} />
			<DependentCollapsible field={'isBrandRule'} condition={(it) => !!it}>
				<MultiSelectField field={'brands'} label={undefined} options={'Brand.name'} />
			</DependentCollapsible>
		</Box>
		<Box>
			<CheckboxField field={'isTagRule'} label={'Dle tagu'} defaultValue={false} />
			<DependentCollapsible field={'isTagRule'} condition={(it) => !!it}>
				<MultiSelectField field={'tags'} label={undefined} options={'ProductTag.internalName'} />
			</DependentCollapsible>
		</Box>
		<Box>
			<CheckboxField field={'isAttributeRule'} label={'Dle atributu'} defaultValue={false} />
			<DependentCollapsible field={'isAttributeRule'} condition={(it) => !!it}>
				<DeferredSubTrees fallback={<LoadingMessage>Načítám hodnoty…</LoadingMessage>}>
					<LocaleMultiSelectField
						label={undefined}
						entities={'ProductAttributeOption'}
						localizedField={'name'}
						field={'attributeOptions'}
						renderOption={(accessor, children) => {
							const attributeLocales = accessor.getEntityList('attribute.locales')
							return (
								Array.from(attributeLocales, (locale) => locale.getField('name').value).join(' / ') +
								': ' +
								children
							)
						}}
						optionsStaticRender={
							<HasMany field={'attribute.locales'}>
								<Field field={'name'} />
							</HasMany>
						}
					/>
				</DeferredSubTrees>
			</DependentCollapsible>
		</Box>
		<Box>
			<CheckboxField field={'isExactProductRule'} label={'Dle produktu'} defaultValue={false} />
			<DependentCollapsible field={'isExactProductRule'} condition={(it) => !!it}>
				<ProductMultiSelectField
					field={'products'}
					label={undefined}
					filter={{ variants: { states: { visibleInListing: undefined } } }}
				>
					<SkuProductInput />
				</ProductMultiSelectField>
			</DependentCollapsible>
		</Box>
	</>
))

import { Component, GenericCell, Repeater, SelectField, TableCell, TextField } from '@contember/admin'
import { CentsField, EditButton, TableRepeaterContainer, TableRepeaterItem } from '../Generic'
import * as React from 'react'
import { DiscountPages } from '../../pages'

export const ProductDiscountsRepeater = Component((props) => (
	<Repeater
		field={'discountCalculations'}
		label={'Aktuální slevy'}
		initialEntityCount={0}
		enableAddingNew={false}
		enableRemoving={false}
		orderBy={'discount.internalName'}
		containerComponent={TableRepeaterContainer}
		itemComponent={TableRepeaterItem}
	>
		<TableCell>
			<TextField field={'discount.internalName'} label={'Název'}></TextField>
		</TableCell>
		<TableCell>
			<TextField field={'discountPercent'} label={'Sleva %'}></TextField>
		</TableCell>
		<TableCell>
			<TextField field={'channel.code'} label={'Kanál'}></TextField>
		</TableCell>
		<TableCell>
			<TextField field={'userGroup.internalName'} label={'Uživatelská skupina'}></TextField>
		</TableCell>
		<TableCell>
			<EditButton pageName={DiscountPages.link('edit')} size={'small'} entityPath={'discount'}></EditButton>
		</TableCell>
	</Repeater>
))

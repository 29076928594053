import {
	Block,
	Box,
	DiscriminatedBlocks,
	EditPage,
	HasOne,
	NumberField,
	Repeater,
	SelectField,
	TextField,
} from '@contember/admin'
import React from 'react'
import {
	CategorySelectField,
	DependentCollapsible,
	EditButton,
	LinkField,
	LocaleSelectField,
	LocaleSideDimension,
} from '../../components'
import { createCrudPages } from '../../utils'

export const ProductMenuPages = createCrudPages({
	entityName: 'ProductMenuItem',
	title: 'Produktové menu',
	form: (
		<>
			<Box>
				<LocaleSideDimension>
					<TextField field={'caption'} label={'Popisek'} />
				</LocaleSideDimension>
				<DiscriminatedBlocks field="type" label="Typ">
					<Block discriminateBy="category" label="Kategorie a odkazy">
						<CategorySelectField field={'mainCategory'} label={'Hlavní kategorie'} allowNull />
						<LocaleSideDimension>
							<LinkField field={'mainLink'} />
						</LocaleSideDimension>
					</Block>
					<Block discriminateBy="brands" label="Značky"></Block>
					<Block discriminateBy="discounts" label="Slevy"></Block>
				</DiscriminatedBlocks>
			</Box>
			<DependentCollapsible field={'type'} condition={(it) => it === 'category'} withoutWrapper>
				<Repeater field={'categories'} label={'Položky'} sortableBy={'order'}>
					<DiscriminatedBlocks field={'type'} label={undefined} defaultValue={'category'}>
						<Block discriminateBy="category" label="Kategorie">
							<CategorySelectField field={'category'} label={undefined} />
							<NumberField field={'subCategoryLimit'} label={'Počet podkategorií'} defaultValue={6} />
						</Block>
						<Block discriminateBy="link" label="Odkazy">
							<HasOne field={'links'}>
								<LocaleSideDimension>
									<TextField field={'caption'} label={'Popisek'} />
									<LinkField field={'link'} />
									<Repeater field={'items'} label={undefined} sortableBy={'order'}>
										<TextField field={'caption'} label={'Popisek'} />
										<LinkField field={'link'} />
									</Repeater>
								</LocaleSideDimension>
							</HasOne>
						</Block>
					</DiscriminatedBlocks>
				</Repeater>
				<Repeater field={'brands'} label={'Top značky'} sortableBy={'order'}>
					<SelectField field="brand" label="Značka" options="Brand.name" />
				</Repeater>
				<HasOne field={'sideLinks'}>
					<LocaleSideDimension>
						<Repeater field={'items'} label={'Odkazy vlevo'} sortableBy={'order'}>
							<TextField field={'caption'} label={'Popisek'} />
							<LinkField field={'link'} />
						</Repeater>
					</LocaleSideDimension>
				</HasOne>
			</DependentCollapsible>
			<DependentCollapsible field={'type'} condition={(it) => it === 'brands'}>
				<Repeater field={'brands'} label={undefined} sortableBy={'order'}>
					<SelectField field="brand" label="Značka" options="Brand.name" />
				</Repeater>
			</DependentCollapsible>
			<DependentCollapsible field={'type'} condition={(it) => it === 'discounts'}>
				<Repeater field={'categories'} label={undefined} sortableBy={'order'}>
					<LocaleSelectField
						field="category"
						label="Kategorie"
						entities="ProductCategory"
						localizedField={'name'}
					/>
				</Repeater>
				<HasOne field={'sideLinks'}>
					<LocaleSideDimension>
						<Repeater field={'items'} label={'Odkazy vlevo'} sortableBy={'order'}>
							<TextField field={'caption'} label={'Popisek'} />
							<LinkField field={'link'} />
						</Repeater>
					</LocaleSideDimension>
				</HasOne>
			</DependentCollapsible>
		</>
	),
	listPageFactory: (args) => (
		<EditPage
			pageName={args.pageName}
			entity="ProductMenu(unique = unique)"
			rendererProps={{
				title: 'Produktové menu',
			}}
		>
			<Repeater field={'items'} sortableBy={'order'} label={'Položky'}>
				<LocaleSideDimension>
					<TextField field={'caption'} label={'Popisek'} />
				</LocaleSideDimension>
				<SelectField
					label={'Typ'}
					options={[
						{ label: 'Kategorie a odkazy', value: 'category' },
						{ label: 'Značky', value: 'brands' },
						{ label: 'Akce', value: 'discounts' },
					]}
					field={'type'}
				/>
				<EditButton pageName={args.pages.edit.name} />
			</Repeater>
		</EditPage>
	),
})

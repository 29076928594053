import {
	Component,
	DataGridCellPublicProps,
	DataGridColumn,
	DataGridHeaderCellPublicProps,
	Field,
	Literal,
	QueryLanguage,
	SugaredRelativeSingleField,
} from '@contember/admin'
import * as React from 'react'
import { ChangeEvent, useCallback } from 'react'

export type EnumCellProps = DataGridHeaderCellPublicProps &
	DataGridCellPublicProps &
	SugaredRelativeSingleField & {
		options: Record<string, string>
	}

export const EnumCell = Component<EnumCellProps>((props) => {
	return (
		<DataGridColumn<string[]>
			{...props}
			enableOrdering={true}
			getNewOrderBy={(newDirection, { environment }) =>
				newDirection && QueryLanguage.desugarOrderBy(`${props.field as string} ${newDirection}`, environment)
			}
			enableFiltering={true}
			getNewFilter={(filterArtefact, { environment }) => ({
				or: filterArtefact.map((it) => ({
					[props.field as string]: { eq: new Literal(it) }, // TODO: desugar props.field
				})),
			})}
			emptyFilter={[]}
			filterRenderer={({ filter, setFilter, environment }) => {
				const onChange = useCallback(
					(event: ChangeEvent<HTMLInputElement>) => {
						if (event.target.checked) {
							setFilter([...filter, event.target.value])
						} else {
							setFilter(filter.filter((it) => it !== event.target.value))
						}
					},
					[filter, setFilter],
				)

				const checkboxList = Object.entries(props.options).map(([value, label]) => (
					<label key={value} style={{ display: 'block' }}>
						<input type="checkbox" value={value} checked={filter.includes(value)} onChange={onChange} />
						{label}
					</label>
				))

				return <>{checkboxList}</>
			}}
		>
			<Field<string> field={props.field} format={(value) => (value ? props.options[value] : '')} />
		</DataGridColumn>
	)
}, 'EnumCell')

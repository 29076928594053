import * as React from 'react'
import { useMemo } from 'react'
import { EntityAccessor, Field, HasMany, useEntity } from '@contember/admin'

export const baseFlags = {
	hasBrand: 'Značka',
	hasCategory: 'Kategorie',
}
export const productFlags = {
	hasImage: 'Obrázek',
}
export const baseChannelFlags = {
	hasName: 'Název',
	hasDescription: 'Popis',
}
export const variantChannelFlags = {
	hasUrl: 'URL',
	hasPrice: 'Cena',
	hasSku: 'Skladový záznam',
	hasQuantity: 'Dostupnost',
}

export const allFlags = {
	...baseFlags,
	...productFlags,
	...baseChannelFlags,
	...variantChannelFlags,
	visibleInListing: 'Zobrazeno ve výpise',
}

export type ProductState = {
	channelCode: string
	product: string
	variant: string
} & {
	[K in keyof typeof allFlags]: boolean
}

const hasManyStates = (
	<HasMany field={'states'}>
		<Field field={'channel.code'} />
		{Object.keys(allFlags).map((it) => (
			<Field field={it} key={it} />
		))}
	</HasMany>
)

export const useProductStates = (scope: 'base' | 'product' | 'variant') => {
	const entity = useEntity()
	return useMemo(() => {
		if (scope === 'variant') {
			return getVariantStates(entity, entity.getEntity('product').idOnServer!)
		}
		if (scope === 'product') {
			return getProductStates(entity)
		}
		const states: ProductState[] = []
		for (const product of Array.from(entity.getEntityList('products'))) {
			states.push(...getProductStates(product))
		}
		return states
	}, [entity, scope])
}

const getVariantStates = (variant: EntityAccessor, productId: string) => {
	const states: ProductState[] = []
	const variantId = variant.idOnServer
	for (const state of Array.from(variant.getEntityList('states'))) {
		const channelCode = state.getField<string>('channel.code').value!
		const stateInfo: Partial<ProductState> = {
			channelCode,
			product: productId,
			variant: variantId,
		}
		for (const flag of Object.keys(allFlags)) {
			stateInfo[flag as keyof typeof allFlags] = state.getField<boolean>(flag).value!
		}
		states.push(stateInfo as ProductState)
	}
	return states
}

const getProductStates = (product: EntityAccessor) => {
	const states: ProductState[] = []
	for (const variant of Array.from(product.getEntityList('variants'))) {
		states.push(...getVariantStates(variant, product.idOnServer!))
	}
	return states
}

export const createProductStateInfoRelations = (scope: 'product' | 'base' | 'variant') => {
	if (scope === 'base') {
		return (
			<HasMany field={'products'}>
				<HasMany field={'variants'}>{hasManyStates}</HasMany>
			</HasMany>
		)
	}
	if (scope === 'product') {
		return <HasMany field={'variants'}>{hasManyStates}</HasMany>
	}
	return (
		<>
			{hasManyStates}
			<Field field={'product.id'} />
		</>
	)
}

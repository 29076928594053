import { Box, Field, Repeater, SelectField, TableCell, TextField } from '@contember/admin'
import * as React from 'react'
import { CentsField, LocaleSideDimension } from '../../components'
import { createCrudPages } from '../../utils'

export const UserGroupPages = createCrudPages({
	entityName: 'UserGroup',
	title: 'Uživatelské skupiny',
	form: (
		<>
			<Box>
				<TextField field="internalName" label="Internal name" />
			</Box>
			<Box heading={'Překlady'}>
				<LocaleSideDimension>
					<TextField field="name" label="Name" />
				</LocaleSideDimension>
			</Box>
			<Repeater label={'Nastavení obratů'} field={'channels'} orderBy={undefined}>
				<SelectField field={'channel'} label={'Kanál'} options={'Channel.internalName'} />
				<CentsField field={'entryTurnoverCents'} label={'Vstupní obrat'} />
				<CentsField field={'maintainTurnoverCents'} label={'Udržovací obrat'} />
			</Repeater>
		</>
	),
	table: (
		<TableCell>
			<Field field="internalName" />
		</TableCell>
	),
})

import { ProductImportMappingTarget } from '../../../../api/model'
import * as React from 'react'
import { useEffect, useMemo, useRef } from 'react'
import { EntityAccessor, useEntity, useEntityListSubTree } from '@contember/admin'
import { tuple } from '../../utils'

const staticMapping: Record<string, { target: ProductImportMappingTarget; locale?: string }> = {
	'description cz': {
		target: 'longName',
		locale: 'cs',
	},
	'description sk': {
		target: 'longName',
		locale: 'sk',
	},
	'popis cz': {
		target: 'description',
		locale: 'cs',
	},
	'popis sk': {
		target: 'description',
		locale: 'sk',
	},
	['název cz']: {
		target: 'name',
		locale: 'cs',
	},
	['název sk']: {
		target: 'name',
		locale: 'sk',
	},
	['název cs']: {
		target: 'name',
		locale: 'cs',
	},
	['krátký popis cz']: {
		target: 'shortDescription',
		locale: 'cs',
	},
	['krátký popis cs']: {
		target: 'shortDescription',
		locale: 'cs',
	},
	['krátký popis sk']: {
		target: 'shortDescription',
		locale: 'sk',
	},
	znacka: {
		target: 'brand',
	},
	'model no': {
		target: 'productBaseCode',
	},
	'base product number': {
		target: 'productBaseCode',
	},
	'colour no': {
		target: 'colorCode',
	},
	'size no': {
		target: 'sizeCode',
	},
	kodproduktu: {
		target: 'sku',
	},
	'#geometrie#': {
		target: 'geometry',
	},
	'#technologie#': {
		target: 'technology',
	},
	ikony: {
		target: 'highlight',
	},
}

export const FillMapping: React.FC = () => {
	const attributes = useEntityListSubTree({
		entities: 'ProductAttribute',
	})
	const locales = useEntityListSubTree({
		entities: 'Locale',
	})
	const fieldToAttributeMap = useMemo(() => {
		const map = new Map<string, { attribute: EntityAccessor; locale: EntityAccessor }>()
		for (const entity of Array.from(attributes)) {
			if (['color', 'size'].includes(entity.getField<string>('code').value!)) {
				continue
			}
			for (const locale of Array.from(entity.getEntityList('locales'))) {
				const mappingField = locale.getField('mappingFieldName').value
				const localeEntity = locale.getEntity('locale')
				if (mappingField) {
					map.set((mappingField as string).trim().toLowerCase(), {
						attribute: entity,
						locale: localeEntity,
					})
				}
				const localeCode = localeEntity.getField<string>('code').value!
				const value = locale.getField<string>('name').value!.toLowerCase()
				switch (localeCode) {
					case 'cs':
						map.set(`cz ${value}`, {
							attribute: entity,
							locale: localeEntity,
						})
						break
					case 'sk':
						map.set(`sk ${value}`, {
							attribute: entity,
							locale: localeEntity,
						})
						break
				}
			}
		}
		return map
	}, [attributes])
	const localesByCode = useMemo(
		() => Object.fromEntries(Array.from(locales).map((it) => tuple(it.getField('code').value, it))),
		[locales],
	)
	const entity = useEntity()
	const mapped = useRef(false)
	useEffect(() => {
		if (mapped.current) {
			return
		}
		mapped.current = true

		const label = entity.getField<string>('label').value
		if (!label) {
			return
		}
		const normalizedLabel = label.trim()
		const mapping = fieldToAttributeMap.get(normalizedLabel.toLowerCase())
		const target = entity.getField('target')
		if (mapping) {
			if (target.value !== 'attribute') {
				target.updateValue('attribute')
			}
			if (entity.getEntity('targetAttribute').idOnServer !== mapping.attribute.idOnServer) {
				entity.connectEntityAtField('targetAttribute', mapping.attribute)
			}
			if (entity.getEntity('locale').idOnServer !== mapping.locale.idOnServer) {
				entity.connectEntityAtField('locale', mapping.locale)
			}
			return
		}
		const staticMappingTarget = staticMapping[normalizedLabel.toLowerCase()]
		if (staticMappingTarget) {
			if (target.value !== staticMappingTarget.target) {
				target.updateValue(staticMappingTarget.target)
			}
			const locale = staticMappingTarget.locale ? localesByCode[staticMappingTarget.locale] : undefined
			if (locale && entity.getEntity('locale').idOnServer !== locale.idOnServer) {
				entity.connectEntityAtField('locale', locale)
			}
		}
	}, [entity, fieldToAttributeMap, localesByCode])

	return null
}

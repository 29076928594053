import { Box, CheckboxField, Component, DateField, Repeater, SelectField, TextField } from '@contember/admin'
import { DependentCollapsible } from '../Generic'

export interface StoreOpeningHoursProps {
	heading: string
	openingHoursField: string
	openingHoursExceptionsField: string
}

export const StoreOpeningHours = Component<StoreOpeningHoursProps>((props) => (
	<Box heading={props.heading}>
		<Repeater field={props.openingHoursField} label={undefined} orderBy={undefined}>
			<div className={'horizontal-fields'}>
				<SelectField
					label={'Den'}
					options={[
						{ value: 'monday', label: 'Pondělí' },
						{ value: 'tuesday', label: 'Úterý' },
						{ value: 'wednesday', label: 'Středa' },
						{ value: 'thursday', label: 'Čtvrtek' },
						{ value: 'friday', label: 'Pátek' },
						{ value: 'saturday', label: 'Sobota' },
						{ value: 'sunday', label: 'Neděle' },
					]}
					field={'weekday'}
				/>
				<TextField field="from" label="Od" />
				<TextField field="to" label="Do" />
			</div>
		</Repeater>
		<Repeater field={props.openingHoursExceptionsField} label={'Výjimky'} orderBy={'date'} initialEntityCount={0}>
			<div className={'openinghours-exceptions-dateinput'}>
				<DateField field={'date'} label={'Datum'} />
			</div>
			<CheckboxField field={'closed'} label={'Zavřeno'} defaultValue={true} isNonbearing={true} />
			<DependentCollapsible field={'closed'} condition={(it) => !it}>
				<div className={'horizontal-fields'}>
					<TextField field={'from'} label={'Od (hh:mm)'} />
					<TextField field={'to'} label={'Do (hh:mm)'} />
				</div>
			</DependentCollapsible>
		</Repeater>
	</Box>
))

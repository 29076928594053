import { Box, CheckboxField, Field, MultiSelectField, TableCell, TextAreaField, TextField } from '@contember/admin'
import * as React from 'react'
import { LocaleSideDimension } from '../../components'
import { createCrudPages } from '../../utils'

export const PaymentMethodPages = createCrudPages({
	entityName: 'PaymentMethod',
	title: 'Platební metody',
	form: (
		<>
			<Box>
				<TextField field="code" label="Kód" />
				<TextField field="internalName" label="Interní název" />
				<CheckboxField field="enabled" label="Povoleno" defaultValue={true} />
				<MultiSelectField
					field="shippingMethods"
					label="Dopravní metody"
					options="ShippingMethod.internalName"
				/>
			</Box>
			<Box heading={'Překlady'}>
				<LocaleSideDimension>
					<TextField field="name" label="Název" />
					<TextAreaField field="description" label="Popis" />
				</LocaleSideDimension>
			</Box>
		</>
	),
	table: (
		<TableCell>
			<Field field="internalName" />
		</TableCell>
	),
})

import {
	ChangePassword,
	EditUserInProject,
	GenericPage,
	InviteUserToProject,
	LayoutInner,
	RolesConfig,
	UsersManagement,
	Page,
} from '@contember/admin'
import * as React from 'react'
import { Page as PageWrapper } from '../../utils'

const rolesConfig: RolesConfig = {
	admin: {
		name: 'Administrator',
		variables: {},
	},
}

export const ChangePasswordPage = new PageWrapper(
	{ path: '/user/change-password' },

	(
		<GenericPage pageName="tenantChangePassword">
			<ChangePassword />
		</GenericPage>
	),
)

// query is required, so send a dummy query
export const UsersManagementPage = new PageWrapper(
	{ path: '/user/list' },
	(
		<GenericPage pageName="tenantUsers">
			<UsersManagement
				rolesDataQuery={`
query {
  _info {
    description
  }
}
				`}
				roleRenderers={{
					admin: () => <>Administrator</>,
				}}
			/>
		</GenericPage>
	),
)
export const InviteUserPage = new PageWrapper(
	{ path: '/user/invite' },
	(
		<GenericPage pageName="tenantInviteUser">
			<InviteUserToProject rolesConfig={rolesConfig} />
		</GenericPage>
	),
)
export const EditUserPage = new PageWrapper(
	{ path: '/user/edit/:id' },
	(
		<Page<{ tenantEditUser: { id: string } }> name="tenantEditUser">
			{({ id }) => (
				<LayoutInner>
					<EditUserInProject rolesConfig={rolesConfig} identityId={id} />
				</LayoutInner>
			)}
		</Page>
	),
)

import * as React from 'react'
import { Component, EntityAccessor, useEntity } from '@contember/admin'
import { useContext } from 'react'

const RelativeEntityContext = React.createContext<EntityAccessor | undefined>(undefined)
export const RelativeEntityProvider = Component<{ children: React.ReactChild }>(
	(props) => {
		const entity = useEntity()
		return <RelativeEntityContext.Provider value={entity}>{props.children}</RelativeEntityContext.Provider>
	},
	({ children }) => <>{children}</>,
)
export const useRelativeEntity = () => useContext(RelativeEntityContext)

import {
	AltPersistButton,
	Box,
	CheckboxField,
	DateField,
	Field,
	HasOne,
	MultiEditPage,
	MultiSelectField,
	NavigateBackButton,
	PageLinkButton,
	Repeater,
	TableCell,
	TextAreaField,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import {
	AddressForm,
	CollapsibleBox,
	ImageField,
	LocaleSideDimension,
	SeoForm,
	StoreOpeningHours,
	VideoUploadWithDescriptionField,
} from '../../components'
import { ContentEditor } from '../../components/Content/ContentEditor'
import { PerChannelLocalizedUrlField } from '../../components/Site/PerChannelUrlField'
import { createCrudPages, Page } from '../../utils'

export const StorePages = createCrudPages({
	entityName: 'Store',
	title: 'Prodejny',
	listPageProps: {
		orderBy: 'order',
		rendererProps: {
			actions: <PageLinkButton to={'storeOrder'}>Seřadit</PageLinkButton>,
		},
	},
	sideForm: (
		<Box>
			<TextField field="code" label="Kód" />
			<TextField field="internalName" label="Interní název" />
			<PerChannelLocalizedUrlField localizedField={'name'} softPrefix={'prodejna/'} />
			<CheckboxField field="hidden" label="Skrýt" />
			<MultiSelectField field={'visibleForChannels'} label={'Zobrazeno pro:'} options={'Channel.internalName'} />
			<MultiSelectField field={'disabledForChannels'} label={'Vypnuto pro:'} options={'Channel.internalName'} />
			<CheckboxField field="disableSelling" label="Zcela vypnout prodeje" />
			<TextField field="internalContactEmail" label="Kontaktní e-mail pro služby" />
		</Box>
	),
	form: (
		<>
			<Box>
				<div className={'horizontal-fields'}>
					<ImageField field={'primaryImage'} label={'Hlavní obrázek'} />
					<ImageField field={'secondaryImage'} label={'Druhý obrázek'} />
				</div>
				<VideoUploadWithDescriptionField field="video" videoLabel={undefined} descriptionLabel="Popis" />
				<LocaleSideDimension>
					<ContentEditor
						leadingElements={[
							{
								field: 'name',
								placeholder: 'Název',
								format: 'plainText',
								render: (props) => (
									<h1 style={{ fontSize: '2.5em', fontWeight: 600 }}>{props.children}</h1>
								),
							},
							{
								field: 'lead',
								placeholder: 'Perex',
								format: 'plainText',
								render: (props) => <p style={{ fontSize: '1.5em' }}>{props.children}</p>,
							},
						]}
					/>
				</LocaleSideDimension>
			</Box>
			<Box>
				<HasOne field={'address'}>
					<AddressForm purpose={'branchAddress'} />
				</HasOne>
				<TextField field={'directionsUrl'} label={'Odkaz na navigaci'} />
				<LocaleSideDimension>
					<TextField field={'publicTransport'} label={'Veřejná doprava'} />
				</LocaleSideDimension>
				<TextField field={'facebookUrl'} label={'Facebook'} />
			</Box>
			<Repeater field={'contacts'} label={'Kontanktní údaje'} sortableBy={'order'}>
				<LocaleSideDimension>
					<TextField field="name" label="Název" />
				</LocaleSideDimension>
				<TextField field="phone" label="Telefon" />
				<TextField field="email" label="E-mail" />
				<TextField field="whatsapp" label="Whatsapp" />
			</Repeater>
			<Repeater field={'messages'} label={'Zprávy'} orderBy={'activeSince'}>
				<LocaleSideDimension>
					<TextAreaField field={'content'} label={'Text'} />
				</LocaleSideDimension>
				<div className={'horizontal-fields'}>
					<DateField field={'activeSince'} label={'Zobrazovat od'} />
					<DateField field={'activeUntil'} label={'Zobrazovat do'} />
				</div>
			</Repeater>
			<StoreOpeningHours
				heading={'Otevírací doba prodejny'}
				openingHoursField={'openingHours.openingHours'}
				openingHoursExceptionsField={'openingHours.exceptions'}
			/>
			<StoreOpeningHours
				heading={'Otevírací doba sevisu'}
				openingHoursField={'serviceOpeningHours.openingHours'}
				openingHoursExceptionsField={'serviceOpeningHours.exceptions'}
			/>
			<LocaleSideDimension>
				<CollapsibleBox heading={'Nastavení SEO'}>
					<SeoForm titleDerivedFrom={'name'} />
				</CollapsibleBox>
			</LocaleSideDimension>
		</>
	),
	table: (
		<>
			<TableCell>
				<Field field="internalName" />{' '}
				<Field<boolean> field="hidden" format={(hide) => (hide === true ? '(sryto)' : null)} />
			</TableCell>
		</>
	),
})

export const StoreOrderPage = new Page(
	{ path: '/store/order' },
	(
		<MultiEditPage
			entities={{ entityName: 'Store' }}
			pageName={'storeOrder'}
			rendererProps={{
				navigation: <NavigateBackButton to={StorePages.link('list')}>Výpis</NavigateBackButton>,
				title: 'Řazení prodejen',
				sortableBy: 'order',
				persistButtonComponent: AltPersistButton,
				enableRemoving: false,
				enableAddingNew: false,
			}}
		>
			<Field field="name" />
		</MultiEditPage>
	),
)

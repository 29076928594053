import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import pLimit from 'p-limit'
import {
	Button,
	Checkbox,
	FilePreview,
	SingleFileUploadState,
	UploadProgress,
	useAuthedContentMutation,
	useCurrentContentGraphQlClient,
	useFileUpload,
} from '@contember/admin'
import { getImagePreview, isUnique } from '../../utils'
import { MapSet } from '../../utils/MapSet'

export interface ImageUploadProps {
	files: File[]
}

interface ImageFile {
	file: File
	code: string | null
}

const useFilesWithCode = (files: File[]): ImageFile[] => {
	return useMemo(() => {
		return files
			.map(
				(file): ImageFile => {
					const match = file.name.match(/^([\w]+?)[A-Z]?\.(jpe?g|png|webp)/i)
					if (!match) {
						return { file, code: null }
					}
					return { file, code: match[1] }
				},
			)
			.sort((a, b) => {
				const aName = a.file.name.substring(0, a.file.name.lastIndexOf('.'))
				const bName = b.file.name.substring(0, b.file.name.lastIndexOf('.'))
				return aName.localeCompare(bName)
			})
	}, [files])
}

export interface ImageState {
	code: ImageStateCode
	file: File
}

enum ImageStateCode {
	starting = 'starting',
	uploading = 'uploading',
	saving = 'saving',
	finished = 'finished',
	productNotFound = 'productNotFound',
	multipleProductsFound = 'multipleProductsFound',
	failed = 'failed',
}

type ImageListItem = {
	create: {
		order: number
		image: {
			create: {
				url: string
				type: string
				width: number
				height: number
				size: number
			}
		}
	}
}
const useSingleUpload = () => {
	const [uploadStatus, { startUpload }] = useFileUpload()
	const uploadWatchers = useRef(new Map<string, { resolve: (value: SingleFileUploadState) => void }>())
	const createUploadStatusPromise = useCallback(
		(id: string) =>
			new Promise<SingleFileUploadState>((resolve) => {
				uploadWatchers.current.set(id, { resolve })
			}),
		[],
	)
	useEffect(() => {
		for (const [id, { resolve }] of uploadWatchers.current) {
			const status = uploadStatus.get(id)
			if (status && status.readyState !== 'uploading') {
				resolve(status)
				uploadWatchers.current.delete(id)
			}
		}
	}, [uploadStatus])

	return useCallback(
		(file: File): Promise<SingleFileUploadState> => {
			const promise = createUploadStatusPromise(file.name)
			startUpload([[file.name, file]])
			return promise
		},
		[createUploadStatusPromise, startUpload],
	)
}

const useListSku = () => {
	const client = useCurrentContentGraphQlClient()
	return useCallback(
		async (code: string) => {
			const result = await client.sendRequest<{
				data: {
					sku: {
						code: string
						variant: {
							product: { id: string; code: string; images?: { items: { order: number }[] } }
						}
					}[]
				}
			}>(
				`query($code: StringCondition!) {
	sku: listProductSku(filter: {sku: $code}) {
		variant {
			product {
				code
				base {
					code
				}
				images {
					items {
						order
					}
				}
			}
		}
	}
}`,
				{ variables: { code: { startsWith: code } } },
			)
			return result.data.sku
		},
		[client],
	)
}

const useSaveImage = () => {
	const [saveImage] = useAuthedContentMutation<
		{ updateProduct: { ok: boolean; errorMessage?: string } },
		{
			code: string
			images: any
		}
	>(`mutation($code: String!, $images: ProductUpdateImagesEntityRelationInput!) {
		updateProduct(by: {code: $code}, data: {images: $images}) {
			ok
			errorMessage
		}
	}`)
	return useCallback(
		async (code: string, images: ImageListItem[], override: boolean) => {
			return (
				await saveImage({
					code,
					images: override
						? { create: { items: images } }
						: {
								upsert: {
									create: {
										items: images,
									},
									update: {
										items: images,
									},
								},
						  },
				})
			).updateProduct
		},
		[saveImage],
	)
}

interface ProductState {
	codes: string[]
	imageOrder: number
}

const useFindByCodes = () => {
	const listSku = useListSku()
	return useCallback(
		async (codes: string[]) => {
			const result = new Map<string, ProductState>()
			const limit = pLimit(15)
			const promises: Promise<any>[] = []
			codes.forEach((code) => {
				promises.push(
					limit(async () => {
						const skus = await listSku(code)
						const productCodes = skus.map((it) => it.variant.product.code).filter(isUnique)
						if (productCodes.length === 0) {
							return
						}
						const product = skus[0]?.variant.product
						const imageOrder = Math.max(...(product.images?.items.map(({ order }) => order) || []), -1) + 1
						result.set(code, { codes: productCodes, imageOrder })
					}),
				)
			})
			await Promise.all(promises)
			return result
		},
		[listSku],
	)
}

const useImageUploader = (
	files: ImageFile[],
): [Record<string, ImageState>, undefined | ((override: boolean) => void)] => {
	const saveImage = useSaveImage()
	const fileUpload = useSingleUpload()
	const [imageStates, setImageState] = useState<Record<string, ImageState>>({})
	const updateImageState = useCallback((file: File, code: ImageStateCode) => {
		setImageState((current) => ({
			...current,
			[file.name]: { code, file },
		}))
	}, [])
	const [uploading, setUploading] = useState(false)
	const findByCodes = useFindByCodes()

	const doStart = useCallback(
		async (override: boolean) => {
			setUploading(true)
			const limit = pLimit(15)

			const codes = files
				.map((it) => it.code)
				.filter((it): it is string => !!it)
				.filter(isUnique)
			const codesState = await findByCodes(codes)

			const promises = []
			const imagesOrderByCode: Record<string, number> = {}
			const imagesOrderByName: Record<string, number> = {}

			const items = new MapSet<string, { image: ImageListItem; file: File }>()
			for (const file of files) {
				const productCode = file.code
				if (!productCode) {
					continue
				}
				const state = codesState.get(productCode)
				if (!state) {
					updateImageState(file.file, ImageStateCode.productNotFound)
					continue
				}
				if (state.codes.length > 1) {
					updateImageState(file.file, ImageStateCode.multipleProductsFound)
					continue
				}
				const order = imagesOrderByCode[productCode] ?? (override ? 0 : state.imageOrder)
				imagesOrderByName[file.file.name] = order
				imagesOrderByCode[productCode] = order + 1
				promises.push(
					limit(async () => {
						const fileName = file.file.name

						updateImageState(file.file, ImageStateCode.uploading)
						const upload = await fileUpload(file.file)
						if (upload.readyState !== 'success') {
							console.error(upload)
							updateImageState(file.file, ImageStateCode.failed)
							return
						}
						const previewUrl = upload.previewUrl
						const url = upload.result.fileUrl
						updateImageState(file.file, ImageStateCode.saving)
						const imagePreview = await getImagePreview(previewUrl)
						const imageOrder = imagesOrderByName[fileName]
						const image: ImageListItem = {
							create: {
								order: imageOrder,
								image: {
									create: {
										url,
										height: imagePreview.naturalHeight,
										width: imagePreview.naturalWidth,
										type: file.file.type,
										size: file.file.size,
									},
								},
							},
						}
						items.add(state.codes[0], { image, file: file.file })
					}),
				)
			}
			await Promise.all(promises)
			for (const [code, values] of items.map.entries()) {
				const valuesArr = Array.from(values.values())
				try {
					const result = await saveImage(
						code,
						valuesArr.map((it) => it.image),
						override,
					)
					if (!result.ok) {
						console.error(result.errorMessage)
					}
				} catch (e) {
					console.error(e)
					valuesArr.forEach((it) => {
						updateImageState(it.file, ImageStateCode.failed)
					})
					return
				}
				valuesArr.forEach((it) => {
					updateImageState(it.file, ImageStateCode.finished)
				})
			}
		},
		[fileUpload, files, findByCodes, saveImage, updateImageState],
	)
	return [imageStates, uploading ? undefined : doStart]
}

export const ImageUpload: React.FC<ImageUploadProps> = ({ files }) => {
	const filesWithCode = useFilesWithCode(files)
	const [states, startUpload] = useImageUploader(filesWithCode)
	const [override, setOverride] = useState(false)
	const getOverlay = (image: ImageFile) => {
		if (!image.code) {
			return <div className={'overlay-error'}>{image.file.name}: Špatný formát názvu souboru</div>
		}
		const state = states[image.file.name]
		if (!state) {
			return null
		}
		if (
			state.code === ImageStateCode.starting ||
			state.code === ImageStateCode.uploading ||
			state.code === ImageStateCode.saving
		) {
			return <UploadProgress />
		}
		if (state.code === ImageStateCode.finished) {
			return <div className={'overlay-success'}>&nbsp;</div>
		}
		if (state.code === ImageStateCode.multipleProductsFound) {
			return <div className={'overlay-error'}>{image.file.name}: Nalezeno více produktů</div>
		}
		if (state.code === ImageStateCode.productNotFound) {
			return <div className={'overlay-error'}>{image.file.name}: Produkt nenalezen</div>
		}
		return <div className={'overlay-error'}>{image.file.name}: Došlo k chybě</div>
	}

	return (
		<>
			<Checkbox value={override} onChange={setOverride}>
				Přepsat současné obrázky
			</Checkbox>
			<Button onClick={() => startUpload?.(override)} disabled={!startUpload}>
				Začít nahrávat
			</Button>
			<ul className={'fileInput'}>
				{filesWithCode.map((it) => (
					<FilePreview key={it.file.name} overlay={getOverlay(it)}>
						{it.file.name}
					</FilePreview>
				))}
			</ul>
			<Button onClick={() => startUpload?.(override)} disabled={!startUpload}>
				Začít nahrávat
			</Button>
		</>
	)
}

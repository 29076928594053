import {
	Component,
	ConcealableField,
	DateFieldInner,
	Field,
	FormGroup,
	useEnvironment,
	useMutationState,
	useField,
} from '@contember/admin'
import * as React from 'react'

const now = new Date()

export interface IsPublishedFieldProps {
	field: string
	defaultValue?: Date | 'now'
}

const getDefaultValue = (value: IsPublishedFieldProps['defaultValue']) => (value === 'now' ? now : value ?? null)

export const IsPublishedField = Component<IsPublishedFieldProps>(
	({ defaultValue, field }) => {
		const defaultValueDate = getDefaultValue(defaultValue)
		const dateField = useField<string>(field)
		const environment = useEnvironment()
		const isMutating = useMutationState()

		const renderConcealedValue = React.useCallback(() => {
			const date = dateField.value ? new Date(dateField.value) : defaultValueDate
			if (!date) {
				return 'Nepublikováno'
			}

			return new Intl.DateTimeFormat('cs-CZ', {
				day: '2-digit',
				month: 'short',
				year: 'numeric',
			}).format(date)
		}, [dateField.value, defaultValueDate])

		return (
			<FormGroup label="Datum publikace">
				<ConcealableField renderConcealedValue={renderConcealedValue} isExtended>
					{({ inputRef, onFocus, onBlur }) => (
						<DateFieldInner
							fieldMetadata={{
								field: dateField,
								environment,
								isMutating,
							}}
							onFocus={onFocus}
							onBlur={onBlur}
							ref={inputRef}
							dateFormat="dd. M. yyyy"
						/>
					)}
				</ConcealableField>
			</FormGroup>
		)
	},
	({ defaultValue, field }) => (
		<Field field={field} isNonbearing defaultValue={getDefaultValue(defaultValue)?.toISOString()} />
	),
	'IsPublishedField',
)

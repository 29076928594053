import { isUnique, tuple } from '../../utils'
import {
	Box,
	Checkbox,
	Component,
	DataGridColumn,
	EntityAccessor,
	EntityListSubTree,
	Field,
	Filter,
	HasMany,
	Select,
	useEntity,
	useEntityListSubTree,
} from '@contember/admin'
import * as React from 'react'
import { useMemo } from 'react'
import classNames from 'classnames'
import {
	allFlags,
	baseChannelFlags,
	baseFlags,
	createProductStateInfoRelations,
	productFlags,
	ProductState,
	useProductStates,
	variantChannelFlags,
} from './ProductStateInfoHelpers'

const keyOf = <T extends string>(object: { [K in T]: any }): T[] => Object.keys(object) as T[]

const StateFlag = (props: { value: React.ReactNode; count?: React.ReactNode; flagKey: string }) => (
	<span className={classNames('product-state-item', 'product-state-item-' + props.flagKey)}>
		<span className={'product-state-value'}>{props.value}</span>
		{!props.count ? null : <span className={'product-state-count'}>{props.count}</span>}
	</span>
)

const processBaseFlags = (states: ProductState[], keys: (keyof typeof allFlags)[]): React.ReactNode[] => {
	const first = states[0]
	const labels = []
	for (const key of keys) {
		if (!first[key]) {
			labels.push(<StateFlag key={key} value={allFlags[key]} flagKey={key} />)
		}
	}
	return labels
}
const processProductFlags = (
	states: ProductState[],
	keys: (keyof typeof allFlags)[],
	ignoreCounts: boolean,
): React.ReactNode[] => {
	const productCount = states.map((it) => it.product).filter(isUnique).length
	const labels = []
	for (const key of keys) {
		const missing = states
			.filter((it) => !it[key])
			.map((it) => it.product)
			.filter(isUnique).length
		if (missing) {
			labels.push(
				<StateFlag
					key={key}
					value={allFlags[key]}
					flagKey={key}
					count={ignoreCounts ? undefined : `(${productCount - missing}/${productCount})`}
				/>,
			)
		}
	}
	return labels
}

const processVariantFlags = (
	states: ProductState[],
	keys: (keyof typeof allFlags)[],
	ignoreCounts: boolean,
): React.ReactNode[] => {
	const variantCount = states.map((it) => it.variant).filter(isUnique).length
	const labels = []
	for (const key of keys) {
		const missing = states
			.filter((it) => !it[key])
			.map((it) => it.variant)
			.filter(isUnique).length
		if (missing) {
			labels.push(
				<StateFlag
					key={key}
					value={allFlags[key]}
					flagKey={key}
					count={ignoreCounts ? undefined : `(${variantCount - missing}/${variantCount})`}
				/>,
			)
		}
	}
	return labels
}

interface ProductBaseStateInfoProps {
	scope: 'base' | 'product' | 'variant'
}

const ProductStateInfo = Component<ProductBaseStateInfoProps>(
	(props) => {
		const states = useProductStates(props.scope)
		if (states.length === 0) {
			return null
		}
		const globalLabels = useMemo(() => {
			const labels = []
			labels.push(...processBaseFlags(states, keyOf(baseFlags)))
			labels.push(...processProductFlags(states, keyOf(productFlags), props.scope !== 'base'))
			return labels
		}, [props.scope, states])
		const channelLabels: Record<string, React.ReactNode[]> = useMemo(() => {
			const channels = states.map((it) => it.channelCode).filter(isUnique)
			const labels: Record<string, React.ReactNode[]> = {}
			for (const channel of channels) {
				labels[channel] = []
				const channelStates = states.filter((it) => it.channelCode === channel)
				labels[channel].push(...processBaseFlags(channelStates, keyOf(baseChannelFlags)))
				const { hasQuantity: _, ...shownVariantChannelFlags } = variantChannelFlags
				labels[channel].push(
					...processVariantFlags(channelStates, keyOf(shownVariantChannelFlags), props.scope === 'variant'),
				)
			}
			return labels
		}, [props.scope, states])
		return (
			<div className={'product-state'}>
				{globalLabels.length > 0 ? <div className={'product-state-base'}>{globalLabels}</div> : null}
				{Object.entries(channelLabels).map(([channel, labels]) => {
					if (labels.length === 0) {
						return null
					}
					return (
						<div key={channel} className={'product-state-channel'}>
							<span className={'product-state-channel-code'}>{channel}</span>
							<span className={'product-state-channel-values'}>{labels}</span>
						</div>
					)
				})}
			</div>
		)
	},
	({ scope }) => (
		<>
			{createProductStateInfoRelations(scope)}
			<EntityListSubTree entities="Channel" expectedMutation="none" alias={'channels'}>
				<Field field={'internalName'} />
			</EntityListSubTree>
		</>
	),
)

type ProductStateInfoCFilterArtifacts = {
	channel?: string
	noneVisibleInListing: boolean
	anyVariant: boolean
} & {
	[K in keyof typeof allFlags]: boolean
}

const emptyFilter = {
	...(Object.fromEntries(keyOf(allFlags).map((it) => tuple(it, false))) as Record<keyof typeof allFlags, boolean>),
	noneVisibleInListing: false,
	anyVariant: false,
}
const initialFilter = {
	...emptyFilter,
	noneVisibleInListing: true,
	anyVariant: false,
}
export interface ProductStateInfoCellProps {
	scope: 'base' | 'product' | 'variant'
}

export const ProductMissingInfoCell = Component<ProductStateInfoCellProps>((props) => (
	<DataGridColumn<ProductStateInfoCFilterArtifacts>
		{...props}
		header={'Co chybí'}
		enableOrdering={false}
		getNewFilter={(filter, { environment }) => {
			const or: Filter[] = []
			const channelCondition: Filter = filter.channel ? { channel: { id: { eq: filter.channel } } } : {}
			const createFilter = (filter: Filter): Filter => {
				let stateFilter: Filter = {
					states: {
						...channelCondition,
						...filter,
					},
				}
				if (props.scope === 'product' || props.scope === 'base') {
					stateFilter = { variants: stateFilter }
				}
				if (props.scope === 'base') {
					stateFilter = { products: stateFilter }
				}
				return stateFilter
			}
			for (const key of keyOf(allFlags)) {
				if (!filter[key]) {
					continue
				}
				if (filter.anyVariant) {
					or.push(createFilter({ [key]: { eq: false } }))
				} else {
					or.push({ not: createFilter({ [key]: { eq: true } }) })
				}
			}
			if (or.length > 0) {
				return { or }
			}
			if (filter.noneVisibleInListing) {
				return { not: createFilter({ visibleInListing: { eq: true } }) }
			}

			return {}
		}}
		enableFiltering={true}
		initialFilter={initialFilter}
		emptyFilter={emptyFilter}
		filterRenderer={({ filter, setFilter }) => {
			const entities = useEntityListSubTree('channels')
			const options = useMemo(() => {
				return [
					{ value: '', label: 'jakýkoliv web' },
					...Array.from(entities, (accessor) => ({
						value: accessor.idOnServer!,
						label: accessor.getField<string>('internalName').value!,
					})),
				]
			}, [entities])
			const { channel, noneVisibleInListing, ...flags } = filter
			const anyFlagChecked = Object.values(flags).filter((it) => it).length > 0
			return (
				<>
					<Select
						value={filter.channel || ''}
						options={options}
						onChange={(e) => {
							const value = e.currentTarget.value

							setFilter({
								...filter,
								channel: value,
							})
						}}
					/>
					{props.scope !== 'variant' && (
						<Checkbox
							isDisabled={anyFlagChecked}
							value={filter.noneVisibleInListing || false}
							onChange={(val) => {
								setFilter({
									...filter,
									noneVisibleInListing: val,
								})
							}}
						>
							Nezobrazená žádná z variant
						</Checkbox>
					)}
					<Box>
						{keyOf(allFlags).map((flag) => (
							<Checkbox
								key={flag}
								value={filter[flag] || false}
								onChange={(val) => {
									setFilter({
										...filter,
										[flag]: val,
									})
								}}
							>
								{allFlags[flag]}
							</Checkbox>
						))}
						<hr />
						{props.scope !== 'variant' && (
							<Checkbox
								isDisabled={!anyFlagChecked}
								value={filter.anyVariant || false}
								onChange={(val) => {
									setFilter({
										...filter,
										anyVariant: val,
									})
								}}
							>
								Chybí u kterékoliv z variant (jinak u všech)
							</Checkbox>
						)}
					</Box>
				</>
			)
		}}
	>
		<ProductStateInfo scope={props.scope} />
	</DataGridColumn>
))

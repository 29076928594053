import {
	Box,
	Button,
	Component,
	EntityListSubTree,
	Field,
	FormGroup,
	TextInput,
	useContentApiRequest,
	useEntityListSubTree,
	useEnvironment,
	useShowToastWithTimeout,
} from '@contember/admin'
import * as React from 'react'
import { useCallback } from 'react'
import { useForm } from '../Generic'
import { StandaloneDateField } from '../Generic'
import Select from 'react-select'
import { downloadXlsx } from '../../utils'

const codeChars = '123456789ABCDEFGHJKLMNQPRSTUVWXYZ'

const useCreateVouchers = () => {
	const [, send] = useContentApiRequest<{ data: { config: { apiToken: string } } }>()
	const baseUrl = useEnvironment().getValue('CZ_FRONTEND_BASEPATH')
	return useCallback(
		async (args: {
			type: string
			discountId: string
			userGroupId?: string
			voucherCount?: number
			codePrefix?: string
			codeSuffix?: string
			codeChars?: string
			codeLength?: number
			validSince?: Date
			validUntil?: Date
			usageLimit?: number
		}): Promise<{ ok: false; message: string } | { ok: true; codes: any[] }> => {
			const configResponse = await send(
				`query {
					config: getAdminConfig(by: {unique: unique}){
						apiToken
					}
				}`,
			)

			return await (
				await fetch(`${baseUrl}api/generate-vouchers?secret=${configResponse.data.config.apiToken}`, {
					method: 'POST',
					body: JSON.stringify(args),
					mode: 'cors',
				})
			).json()
		},
		[baseUrl, send],
	)
}

interface ProductVoucherGeneratorForm {
	type: 'global' | 'group'
}
export const ProductVoucherGeneratorForm = Component<ProductVoucherGeneratorForm>(
	({ type }) => {
		const { values, getValue, onChange } = useForm<{
			userGroupId: string
			voucherCount: number
			codePrefix: string
			codeSuffix: string
			codeChars: string
			codeLength: number
			validSince: Date
			validUntil: Date
			usageLimit: number
		}>({
			initialValues: { codeChars, codeLength: 5, usageLimit: 1 },
		})
		const toast = useShowToastWithTimeout()
		const env = useEnvironment()
		const userGroups = useEntityListSubTree('userGroups')
		const createVouchers = useCreateVouchers()
		const generateVouchers = useCallback(async () => {
			if (!values.codeChars) {
				return toast({ type: 'error', message: 'Nejsou vyplněny znaky pro voucher' })
			}
			if (!values.codeLength) {
				return toast({ type: 'error', message: 'Není nastavena délka kódu' })
			}

			const discountId = String(env.getValue('id'))
			const response = await createVouchers({
				...values,
				discountId,
				type,
			})
			if (!response.ok) {
				return toast({ type: 'error', message: response.message })
			}

			toast({
				type: 'success',
				message: 'Kódy vygenerovány.',
			})
			if (response.codes.length === 0) {
				return
			}
			const tableData: any[][] = []
			tableData.push(Object.keys(response.codes[0]))
			for (const row of response.codes) {
				tableData.push(Object.values(row))
			}
			downloadXlsx(tableData, 'endorphin-vouchers.xlsx')
		}, [createVouchers, env, toast, type, values])

		return (
			<Box>
				{type === 'global' && (
					<FormGroup label={'Počet voucherů'}>
						<TextInput
							onChange={onChange('voucherCount', { type: 'number' })}
							value={getValue('voucherCount')}
							type={'number'}
						/>
					</FormGroup>
				)}
				{type === 'group' && (
					<FormGroup label={'Skupina'}>
						<Select
							options={Array.from(userGroups, (accessor) => ({
								value: accessor.idOnServer,
								label: accessor.getField<string>('internalName').value,
							}))}
							value={
								getValue('userGroupId')
									? {
											value: getValue('userGroupId'),
											label: userGroups
												.getChildEntityById(getValue('userGroupId'))
												?.getField<string>('internalName').value,
									  }
									: null
							}
							onChange={(newValue, actionMeta) => {
								switch (actionMeta.action) {
									case 'select-option': {
										onChange('userGroupId')(newValue?.value)
										break
									}
								}
							}}
						/>
					</FormGroup>
				)}
				<div className={'horizontal-fields'}>
					<FormGroup label={'Prefix kódu'}>
						<TextInput onChange={onChange('codePrefix')} value={getValue('codePrefix')} />
					</FormGroup>
					<FormGroup label={'Suffix kódu'}>
						<TextInput onChange={onChange('codeSuffix')} value={getValue('codeSuffix')} />
					</FormGroup>
				</div>
				<div className={'horizontal-fields'}>
					<FormGroup label={'Použité znaky'}>
						<TextInput onChange={onChange('codeChars')} value={getValue('codeChars')} />
					</FormGroup>
					<FormGroup label={'Délka bez prefixu a suffixu'}>
						<TextInput onChange={onChange('codeLength')} value={getValue('codeLength')} type={'number'} />
					</FormGroup>
				</div>
				<div className={'horizontal-fields'}>
					<FormGroup label={'Platnost od'}>
						<StandaloneDateField value={getValue('validSince')} onChange={onChange('validSince')} />
					</FormGroup>
					<FormGroup label={'Platnost do'}>
						<StandaloneDateField value={getValue('validUntil')} onChange={onChange('validUntil')} />
					</FormGroup>
				</div>
				<FormGroup label={'Limit použití na voucher'}>
					<TextInput
						onChange={onChange('usageLimit', { type: 'number' })}
						value={getValue('usageLimit')}
						type={'number'}
					/>
				</FormGroup>
				<Button onClick={generateVouchers}>Vygenerovat</Button>
			</Box>
		)
	},
	() => {
		return (
			<EntityListSubTree entities="UserGroup" expectedMutation="none" alias={'userGroups'}>
				<Field field={'internalName'} />
			</EntityListSubTree>
		)
	},
)

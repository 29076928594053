import { Block, Component, DiscriminatedBlocks, HiddenField, Repeater } from '@contember/admin'
import { LocaleSelectField } from '../Site'
import * as React from 'react'

export const CategoryParentFilterView = Component(() => (
	<Repeater
		field={'filters.items'}
		label={'Filtry rodiče'}
		orderBy={'order'}
		initialEntityCount={0}
		enableAddingNew={false}
		enableRemoving={false}
	>
		<HiddenField isNonbearing={false} field={'type'} label={undefined} />
		<DiscriminatedBlocks field="type" label="Druh" allowBlockTypeChange={false}>
			<Block discriminateBy="price" label="Cena">
				Cena
			</Block>
			<Block discriminateBy="brand" label="Značka">
				Značka
			</Block>
			<Block discriminateBy="onStock" label="Značka">
				Skladem
			</Block>
			<Block discriminateBy="attribute" label="Atribut">
				<LocaleSelectField
					label={'Atribut'}
					entities="ProductAttribute"
					localizedField="name"
					field={'attribute'}
					readOnly={true}
				/>
			</Block>
		</DiscriminatedBlocks>
	</Repeater>
))

import { Component, Field, useField } from '@contember/admin'
import { useEffect } from 'react'
import * as React from 'react'

export const ProductImportBatchStatus = Component<{ onRefresh?: () => void }>(
	({ onRefresh }) => {
		const preprocessedAt = useField('preprocessedAt').value
		const approvedAt = useField('approvedAt').value
		const mappedAt = useField('mappedAt').value
		const importedAt = useField('importedAt').value
		const errorMessage = useField('errorMessage').value
		useEffect(() => {
			if (!onRefresh) {
				return
			}
			const shouldRefresh = !errorMessage && (!preprocessedAt || (approvedAt && !importedAt))
			if (!shouldRefresh) {
				return
			}
			const handle = setTimeout(onRefresh, 1000)
			return () => clearTimeout(handle)
		}, [approvedAt, errorMessage, importedAt, onRefresh, preprocessedAt])
		if (errorMessage) {
			return <>Selhalo: {errorMessage}</>
		}
		if (!mappedAt) {
			return <>Vyžadováno namapování</>
		}
		if (!preprocessedAt) {
			return <>Připravuje se ke schválení</>
		}
		if (!approvedAt) {
			return <>Čeká na schválení</>
		}
		if (!importedAt) {
			return <>Probíhá import</>
		}
		return <>Importováno</>
	},
	() => (
		<>
			<Field field={'mappedAt'} />
			<Field field={'preprocessedAt'} />
			<Field field={'approvedAt'} />
			<Field field={'importedAt'} />
			<Field field={'errorMessage'} />
		</>
	),
)

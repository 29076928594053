import * as React from 'react'
import { Fragment, ReactNode } from 'react'

export const localizedCell = (cb: (locale: string) => ReactNode): ReactNode => {
	const locales = ['cs', 'sk']
	return (
		<>
			{locales.map((locale) => (
				<Fragment key={locale}>{cb(locale)}</Fragment>
			))}
		</>
	)
}

import { createCrudPages } from '../../utils'
import {
	Box,
	Button,
	DateField,
	EntityAccessor,
	HiddenField,
	Repeater,
	SelectField,
	TextCell,
	TextField,
	useEntity,
} from '@contember/admin'
import * as React from 'react'
import { useState } from 'react'
import { AddressForm, AddressSelectField } from '../../components'

const ResendActivationEmailButton = () => {
	const user = useEntity()
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
	const [responseState, setResponseState] = useState<{ success: boolean; message: string }>()

	const ACCESS_TOKEN = 'b05733fe-382a-479d-8ed9-581a7b2d6f69' // Mozno extrahovat do docker-compose.yaml?

	const triggerDispatch = async () => {
		if (user.idOnServer === undefined) return
		const baseUrl = user.environment.getValue('CZ_FRONTEND_BASEPATH')
		const id = user.idOnServer
		const response = await fetch(`${baseUrl}api/account/activation/init`, {
			body: JSON.stringify({
				accountId: id,
				token: ACCESS_TOKEN,
			}),
			method: 'POST',
		})
		const responseJson = await response.json()
		setResponseState({
			success: responseJson.success,
			message: responseJson.message,
		})
	}

	const triggerConfirm = async () => {
		setShowConfirmation(true)
	}

	if (responseState) {
		return (
			<Box>
				<span>
					<strong>{responseState.success ? 'OK' : 'Error'}: </strong>
				</span>
				<span>{responseState.message}</span>
			</Box>
		)
	}

	if (showConfirmation) {
		return (
			<Box>
				<p>Opravdu odeslat zákazníkovi aktivační email? Tahle akce je nevratná.</p>
				<Button onClick={() => triggerDispatch()}>Ano</Button>
			</Box>
		)
	}

	return (
		<Box>
			<Button onClick={() => triggerConfirm()}>Odeslat aktivační email</Button>
		</Box>
	)
}

export const UserPages = createCrudPages({
	entityName: 'User',
	title: 'Uživatelé',
	enableAdding: __DEV_MODE__,
	form: (
		<>
			<Box>
				<DateField field="registeredAt" label="Registrován" readOnly={!__DEV_MODE__} />
				<TextField field="email" label="E-mail" readOnly={!__DEV_MODE__} />
				<DateField field="emailVerifiedAt" label="Email ověřen" readOnly={!__DEV_MODE__} />
				<TextField field="phone" label="Telefon" readOnly={!__DEV_MODE__} />
				<TextField field="firstName" label="Jméno" readOnly={!__DEV_MODE__} />
				<TextField field="lastName" label="Příjmení" readOnly={!__DEV_MODE__} />
				<SelectField
					field="gender"
					label="Pohlaví"
					options={[
						{ value: 'male', label: 'Muž' },
						{ value: 'female', label: 'Žena' },
					]}
					reactSelectProps={{ isDisabled: !__DEV_MODE__ }}
					allowNull={true}
				/>
				{__DEV_MODE__ ? (
					<HiddenField
						isNonbearing={false}
						field={'prototype.createdAt'}
						label={undefined}
						defaultValue="now"
					/>
				) : null}
			</Box>
			<Box heading={'Adresy'}>
				<AddressSelectField label={'Hlavní doručovací adresa'} field={'defaultDeliveryAddress'} />
				<AddressSelectField label={'Hlavní fakturační adresa'} field={'defaultInvoiceAddress'} />
				<Repeater label={undefined} field={'prototype.addresses'} orderBy={undefined}>
					<AddressForm purpose={'customerAddress'} heading={false} />
				</Repeater>
			</Box>
		</>
	),
	sideForm: (
		<>
			<Box heading={'Akce'}>
				<ResendActivationEmailButton />
			</Box>
		</>
	),
	grid: (
		<>
			<TextCell field={'email'} header={'E-mail'} />
			<TextCell field={'firstName'} header={'Jméno'} />
			<TextCell field={'lastName'} header={'Příjmení'} />
			<TextCell field={'phone'} header={'Telefon'} />
		</>
	),
})

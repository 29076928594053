import { Component } from '@contember/admin'
import * as React from 'react'
import { createImgixUrl } from './imageUtils'

interface ImagePreviewProps {
	src: string | null | undefined
	circle?: boolean
	standalone?: boolean
}

export const ImagePreview = Component<ImagePreviewProps>(({ src, circle, standalone }) => {
	const url = React.useMemo(() => {
		if (!src) {
			return null
		}
		return createImgixUrl(src, circle ? 54 : 96, 54)
	}, [circle, src])
	return (
		<span
			style={{
				position: 'relative',
				display: 'inline-block',
				width: '3em',
				height: '1.7em',
				backgroundColor: 'rgb(229, 229, 236)',
				verticalAlign: 'middle',
				overflow: 'hidden',
				...(circle
					? {
							borderRadius: '50%',
							width: '1.7em',
					  }
					: {}),
				...(standalone
					? {}
					: {
							marginRight: '0.7em',
					  }),
			}}
		>
			{url && (
				<img
					src={url}
					alt=""
					style={{ objectFit: 'cover', width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
					loading="lazy"
				/>
			)}
		</span>
	)
})

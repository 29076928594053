import { AltPersistButton, Box, DateCell, MultiEditPage, TextAreaField, TextCell, TextField } from '@contember/admin'
import React from 'react'
import {
	CollapsibleBox,
	ImageListField,
	IsPublishedField,
	LocaleMultiSelectField,
	LocaleSideDimension,
	SeoForm,
} from '../../components'
import { ProductListField } from '../../components/Product/ProductListField'
import { PerChannelLocalizedUrlField } from '../../components/Site/PerChannelUrlField'
import { createCrudPages, Page } from '../../utils'

export const InspirationCategoriesPage = new Page(
	{ path: '/inspiration-categories' },
	(
		<MultiEditPage
			entities="InspirationCategory"
			pageName={'inspirationCategory'}
			rendererProps={{
				title: 'Kategorie inspirací',
				persistButtonComponent: AltPersistButton,
				sortableBy: 'order',
			}}
		>
			<LocaleSideDimension>
				<TextField label="Název" field="name" />
			</LocaleSideDimension>
		</MultiEditPage>
	),
)
export const InspirationGenderPage = new Page(
	{ path: '/inspiration-genders' },
	(
		<MultiEditPage
			entities="InspirationGender"
			pageName={'inspirationGender'}
			rendererProps={{
				title: 'Pohlaví inspirací',
				persistButtonComponent: AltPersistButton,
				sortableBy: 'order',
			}}
		>
			<LocaleSideDimension>
				<TextField label="Název" field="name" />
			</LocaleSideDimension>
		</MultiEditPage>
	),
)

export const InspirationPages = createCrudPages({
	entityName: 'Inspiration',
	title: 'Inspirace',
	sideForm: (
		<>
			<Box>
				<IsPublishedField defaultValue={'now'} field={'publishedAt'} />
				<LocaleMultiSelectField
					entities={'InspirationCategory'}
					localizedField={'name'}
					field={'categories'}
					label={'Kategorie'}
				/>
				<LocaleMultiSelectField
					entities={'InspirationGender'}
					localizedField={'name'}
					field={'genders'}
					label={'Pohlaví'}
				/>
			</Box>
			<Box title={'URL'}>
				<PerChannelLocalizedUrlField localizedField={'description'} softPrefix={'inspirace/'} />
			</Box>
		</>
	),
	form: (
		<>
			<Box>
				<TextField field={'internalName'} label={'Interní název'} />
				<LocaleSideDimension>
					<TextAreaField field={'description'} label={'Popis'} />
				</LocaleSideDimension>

				<ImageListField field={'images'} label={'Obrázky'} />
				<ProductListField field={'products'} label={'Produkty'} />
			</Box>
			<LocaleSideDimension>
				<CollapsibleBox heading={'Nastavení SEO'}>
					<SeoForm titleDerivedFrom={'description'} withDynamicDescriptionFields />
				</CollapsibleBox>
			</LocaleSideDimension>
		</>
	),
	grid: (
		<>
			<TextCell field="internalName" header="Interní název" />
			<DateCell
				field="publishedAt"
				header="Datum publikace"
				initialOrder="desc"
				format={(date) => date.toLocaleDateString()}
			/>
		</>
	),
})

import { AltPersistButton, MultiEditPage, TextField } from '@contember/admin'
import React from 'react'
import { LocaleSideDimension } from '../../components'
import { Page } from '../../utils'

export const ArticleTopicsPage = new Page(
	{ path: '/article-topics' },
	(
		<MultiEditPage
			entities="ArticleTopic"
			pageName={'articleTopics'}
			rendererProps={{
				title: 'Témata článků',
				persistButtonComponent: AltPersistButton,
			}}
		>
			<LocaleSideDimension>
				<TextField label="Název" field="name" />
				<TextField label="URL" field="slug" />
			</LocaleSideDimension>
		</MultiEditPage>
	),
)

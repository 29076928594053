import { Component, useEntity } from '@contember/admin'
import { CategorySelectField } from './CategorySelect'
import * as React from 'react'
import { CategoryParentFilterView } from './CategoryParentFilterView'

export const CategoryParentSelect = Component(
	() => {
		const entity = useEntity()
		return (
			<>
				<CategorySelectField
					field={'parent'}
					label={'Rodič'}
					allowNull={true}
					withoutTree={entity.idOnServer}
				/>
			</>
		)
	},
	(props, env) => {
		return (
			<>
				<CategorySelectField
					field={'parent'}
					label={'Rodič'}
					allowNull={true}
					withoutTree={env.getValue('id')}
					optionsStaticRender={<CategoryParentFilterView />}
				/>
			</>
		)
	},
)

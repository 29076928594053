import {
	Box,
	Component,
	DerivedFieldLink,
	DerivedFieldLinkProps,
	HasOne,
	TextAreaField,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { ImageField } from '../index'

export interface SeoFormProps {
	titleDerivedFrom: DerivedFieldLinkProps['derivedField']
	withDynamicDescriptionFields?: boolean
}

export const SeoForm = Component<SeoFormProps>(
	(props) => (
		<Box heading="SEO nastavení">
			{props.titleDerivedFrom && (
				<DerivedFieldLink sourceField={props.titleDerivedFrom} derivedField="seo.title" />
			)}
			<HasOne field="seo">
				<TextField field="title" label="Title" />
				<TextAreaField field="description" label="Description" />
				{props.withDynamicDescriptionFields && (
					<TextAreaField
						field="dynamicDescription"
						label="Dynamic Description"
						description={'Pro vložení nadpisu použijte placeholder %value%'}
					/>
				)}
				<ImageField
					field="ogImage"
					label="OG image"
					description="Recommended ratio 19:10. eg. 2400×1260 px."
					allowImageDisconnect
				/>
				<TextField field="ogTitle" label="OG title" />
				<TextAreaField field="ogDescription" label="OG description" />
				{props.withDynamicDescriptionFields && (
					<TextAreaField
						field="dynamicOgDescription"
						label="Dynamic OG Description"
						description={'Pro vložení nadpisu použijte placeholder %value%'}
					/>
				)}
			</HasOne>
		</Box>
	),
	'SeoForm',
)

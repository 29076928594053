import { CheckboxField, Component, HasOne, MultiSelectField } from '@contember/admin'
import { DependentCollapsible } from '../Generic'
import * as React from 'react'

export const ProductComplementSettings = Component((props) => (
	<HasOne field={'complementSettings'}>
		<CheckboxField field={'canBuySeparately'} label={'Lze koupit samostatně'} />
		<CheckboxField field={'showInDetail'} label={'Zobrazit v detailu'} />
		<CheckboxField field={'showInCart'} label={'Zobrazit v košíku'} />
		<MultiSelectField label="Produkty" options="ProductRule.internalName" field="productRules" />

		<CheckboxField field={'isUserGroupRequired'} label={'Omezeno na uživatelskou skupinu'} defaultValue={false} />
		<DependentCollapsible field={'isUserGroupRequired'} condition={(it) => !!it}>
			<MultiSelectField label={'Skupiny'} field={'userGroups'} options={'UserGroup.internalName'} />
		</DependentCollapsible>
	</HasOne>
))

import { Component, getImageFileDefaults, Repeater, TextAreaField, UploadField } from '@contember/admin'
import * as React from 'react'
import { LinkField } from '../Site'

const imageFileDefaults = getImageFileDefaults('image.url')

export const GalleryField = Component(
	() => (
		<Repeater sortableBy="order" field="gallery.items" label={undefined} removalType="delete">
			<UploadField
				label={undefined}
				fileUrlField="image.url"
				imageWidthField="image.width"
				imageHeightField="image.height"
				fileSizeField="image.size"
				fileTypeField="image.type"
				{...imageFileDefaults}
				renderFilePreview={(file, previewUrl) => imageFileDefaults.renderFilePreview(file, previewUrl)}
				renderFile={() => imageFileDefaults.renderFile()}
			/>
			<TextAreaField field={'description'} label={'Popis'} size={'small'} />
			<LinkField field="link" />
		</Repeater>
	),
	'GalleryField',
)

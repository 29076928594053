import { Component, Field } from '@contember/admin'
import { isUnique, localizedCell } from '../../utils'
import { HasManyFilterCell } from '../Generic'
import * as React from 'react'
import { Fragment } from 'react'

interface ProductAttributeCell {
	hasMany?: 'product'
	path?: string[]
	field: string
	attributeCode: string
	header: string
}

export const ProductAttributeCell = Component<ProductAttributeCell>((props) => (
	<>
		{localizedCell((locale) => (
			<HasManyFilterCell
				fields={[...(props.path || []), `${props.field}[attribute.code = '${props.attributeCode}']`]}
				header={`${props.header}\u00A0(${locale})`}
				hidden={locale !== 'cs'}
				createWhere={(value) => ({
					optionValue: {
						locales: {
							locale: { code: { eq: locale } },
							name: value,
						},
					},
				})}
				render={({ entities }) => (
					<>
						{entities
							.map(
								(entity) =>
									entity.getField<string>(`optionValue.locales(locale.code='${locale}').name`).value,
							)
							.filter(isUnique)
							.map((it) => (
								<Fragment key={it}>
									{it}
									<br />
								</Fragment>
							))}
					</>
				)}
			>
				<Field field={`optionValue.locales(locale.code='${locale}').name`} />
			</HasManyFilterCell>
		))}
	</>
))

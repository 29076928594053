import {
	Block,
	BlockEditor,
	BlockEditorProps,
	Box,
	CheckboxField,
	Component,
	DateTimeField,
	EmbedHandlers,
	Field,
	HasOne,
	MultiSelectField,
	OptionallyVariableStaticOption,
	Repeater,
	RichEditor,
	SelectField,
	TextAreaField,
	TextField,
} from '@contember/admin'
import { ContentOutlet } from '@contember/admin/dist/src/components/bindingFacade/richText/blockEditor/templating'
import * as React from 'react'
import { CategorySelectField } from '../Category'
import { DependentCollapsible } from '../Generic'
import { IfField } from '../Generic/IfField'
import { ProductListField, ProductSelectField } from '../Product'
import { LocaleSelectField } from '../Site'
import { LinkField } from '../Site/LinkField'
import { customButtonLinkButton, customLinkButton, withCustomLinks } from './editorCustomLinks'
import { FileField } from './FileField'
import { GalleryField } from './GalleryField'
import { IconSelectField } from './IconSelectField'
import { ImageField } from './ImageField'
import { VideoUploadWithDescriptionField } from './VimeoUploadField'

const RB = RichEditor.buttons
const fullEditorInlineButtons: BlockEditorProps['inlineButtons'] = [
	[RB.bold, RB.italic, RB.underline, RB.highlight, customLinkButton, customButtonLinkButton],
	[RB.headingOne, RB.headingTwo, RB.headingThree, RB.headingFour, RB.unorderedList, RB.orderedList],
	[RB.strikeThrough, RB.code],
]

export interface ContentBlockProps {
	leadingElements: BlockEditorProps['leadingFieldBackedElements']
	disableEmbeddedContent?: boolean
}

const carouselColors: { value: string; description: string; color: string }[] = [
	{ value: 'darkGreen', description: 'tmavě zelená', color: 'rgb(115, 149, 24)' },
	{ value: 'green', description: 'zelená', color: 'rgb(149, 193, 31)' },
	{ value: 'raspberry', description: 'malinová', color: 'rgb(229, 0, 81)' },
	{ value: 'orange', description: 'oranžová', color: 'rgb(255, 110, 0)' },
	{ value: 'cyan', description: 'tyrkysová', color: 'rgb(55, 184, 192)' },
	{ value: 'yellow', description: 'žlutá', color: 'rgb(255, 237, 0)' },
	{ value: 'purple', description: 'fialová', color: 'rgb(112, 34, 131)' },
	{ value: 'black', description: 'černá', color: 'rgb(0, 0, 0)' },
	{ value: 'white', description: 'bílá', color: 'rgb(255, 255, 255)' },
	{ value: 'gray', description: 'šedá', color: 'rgb(102, 102, 102)' },
]

const carouselColorOptions: OptionallyVariableStaticOption[] = carouselColors.map(({ value, color, description }) => ({
	value,
	label: (
		<>
			<span className={'carouselColor'} style={{ background: color }}>
				&nbsp;
			</span>
			{description}
		</>
	),
}))

const embedBLock = (
	<Block discriminateBy="embed" label="Embed">
		<Block discriminateBy="youtube" label="YouTube video" />
		<Block discriminateBy="vimeo" label="Vimeo video" />
	</Block>
)
const attachmentBlock = (
	<Block discriminateBy="attachment" label="Soubor ke stažení">
		<TextField field="primaryText" label="Popisek" />
		<div className={'horizontal-fields'}>
			<FileField label={'Soubor'} field={'file'} />
			<ImageField field={'image'} label={'Náhled'} />
		</div>
	</Block>
)

const galleryBlock = (
	<Block
		discriminateBy="gallery"
		label="Galerie"
		alternate={
			<>
				<CheckboxField label={'Carousel'} field={'carousel'} />
			</>
		}
	>
		<GalleryField />
	</Block>
)

const videoBlock = (
	<Block discriminateBy="video" label="Video">
		<VideoUploadWithDescriptionField field="video" videoLabel={undefined} descriptionLabel="Popis" />
	</Block>
)
export const ContentEditor = Component<ContentBlockProps>((props) => {
	return (
		<BlockEditor
			sortableBy="order"
			field="content.parts"
			contentField="json"
			referencesField="references"
			referenceDiscriminationField="type"
			label={'Obsah'}
			inlineButtons={fullEditorInlineButtons}
			leadingFieldBackedElements={props.leadingElements || []}
			embedReferenceDiscriminateBy="embed"
			embedContentDiscriminationField="embed.type"
			augmentEditor={(e) => withCustomLinks(e)}
			embedHandlers={[
				new EmbedHandlers.YouTube({
					discriminateBy: 'youtube',
					youTubeIdField: 'embed.youtubeId',
				}),
				new EmbedHandlers.Vimeo({
					discriminateBy: 'vimeo',
					vimeoIdField: 'embed.vimeoId',
				}),
			]}
			blockButtons={[
				[
					{
						contemberIcon: 'gallery',
						discriminateBy: 'gallery',
						title: 'Galerie',
					},
					{
						blueprintIcon: 'video',
						discriminateBy: 'video',
						title: 'Video',
					},
					{
						contemberIcon: 'download',
						discriminateBy: 'attachment',
						title: 'Soubor ke stažení',
					},
					{
						contemberIcon: 'composedBlock',
						discriminateBy: 'banner',
						title: 'Banner',
					},
					{
						blueprintIcon: 'split-columns',
						discriminateBy: 'carousel',
						title: 'Velký carousel',
					},
					{
						blueprintIcon: 'list-detail-view',
						discriminateBy: 'contentWithLinks',
						title: 'Obsah s odkazy',
					},
					{
						blueprintIcon: 'bring-data',
						discriminateBy: 'scrollTarget',
						title: 'Kotva',
					},
				],
				[
					{
						blueprintIcon: 'cube',
						discriminateBy: 'product',
						title: 'Detail produktu',
					},
					{
						blueprintIcon: 'cube-add',
						discriminateBy: 'productList',
						title: 'Seznam produktů',
					},
					{
						blueprintIcon: 'shopping-cart',
						discriminateBy: 'productCategory',
						title: 'Výpis produktů z kategorie',
					},
					{
						blueprintIcon: 'layout-linear',
						discriminateBy: 'productAttributeOptions',
						title: 'Výpis produktů podle modelu',
					},
					{
						blueprintIcon: 'lightbulb',
						discriminateBy: 'inspiration',
						title: 'Inspirace',
					},
					{
						blueprintIcon: 'tag',
						discriminateBy: 'brands',
						title: 'Značky',
					},
					{
						blueprintIcon: 'layout',
						discriminateBy: 'popupGeometry',
						title: 'Popup geometrie',
					},
					{
						blueprintIcon: 'lab-test',
						discriminateBy: 'popupTechnology',
						title: 'Popup technologie',
					},
					{
						blueprintIcon: 'dollar',
						discriminateBy: 'bannerPlaceholder',
						title: 'Vložený banner',
					},
				],
				[
					RB.table,
					{
						contemberIcon: 'quote',
						discriminateBy: 'quote',
						title: 'Citace',
					},

					{
						contemberIcon: 'questionAnswer',
						discriminateBy: 'faq',
						title: 'FAQ',
					},
					{
						contemberIcon: 'questionAnswer',
						discriminateBy: 'faqLinkable',
						title: 'FAQ (s odkazy)',
					},
					{
						blueprintIcon: 'layers',
						discriminateBy: 'highlights',
						title: 'Výhody',
					},
					{
						blueprintIcon: 'grid-view',
						discriminateBy: 'linkTiles',
						title: 'Dlaždice s odkazy',
					},
					{
						blueprintIcon: 'wrench',
						discriminateBy: 'serviceGroupTiles',
						title: 'Dlaždice typů služeb',
					},
					{
						blueprintIcon: 'wrench',
						discriminateBy: 'serviceTiles',
						title: 'Dlaždice služeb',
					},
					{
						blueprintIcon: 'people',
						discriminateBy: 'people',
						title: 'Lidé',
					},
					{
						blueprintIcon: 'application',
						discriminateBy: 'orderService',
						title: 'Objednání služby',
					},
					{
						blueprintIcon: 'shop',
						discriminateBy: 'storeList',
						title: 'Seznam prodejen',
					},
					{
						contemberIcon: 'table',
						discriminateBy: 'loyaltyTariffsTable',
						title: 'Tabulka tarifů',
					},
					{
						blueprintIcon: 'text-highlight',
						discriminateBy: 'loyaltyPassLink',
						title: 'Endorphin pas link',
					},
				],
				...(!props.disableEmbeddedContent
					? [
							[
								{
									blueprintIcon: 'duplicate',
									discriminateBy: 'embeddedContent',
									title: 'Vložený blok',
								} as const,
							],
					  ]
					: []),
			]}
		>
			{embedBLock}
			{galleryBlock}
			{videoBlock}
			{attachmentBlock}
			{!props.disableEmbeddedContent && (
				<Block discriminateBy="embeddedContent" label="Vložený blok">
					<SelectField
						label={undefined}
						field="embeddedContent"
						options="EmbeddableContent"
						renderOption={(option) => option.getField('name').value}
						optionsStaticRender={
							<>
								<Field field="name" />
								<ContentEditor leadingElements={[]} disableEmbeddedContent={true} />
							</>
						}
					/>

					{/* TODO: This should work, but currently throws an error on connect */}
					{/*<CollapsibleBox heading="Editace vloženého bloku na všech stránkách">*/}
					{/*	<HasOne field="embeddedContent">*/}
					{/*		<ContentEditor leadingElements={[]} disableEmbeddedContent={true} />*/}
					{/*	</HasOne>*/}
					{/*</CollapsibleBox>*/}
				</Block>
			)}
			<Block discriminateBy="bannerPlaceholder" label="Vložený banner"></Block>
			<Block discriminateBy="quote" label="Citace">
				<BlockEditor.ContentOutlet />
				<div className={'horizontal-fields'}>
					<TextField field="primaryText" label="Autor výroku" />
					<TextField field="secondaryText" label="Titul autora" />
				</div>
			</Block>
			<Block discriminateBy="product" label="Detail produktu">
				<ProductSelectField field={'product'} />
			</Block>
			<Block discriminateBy="productList" label="Seznam produktů">
				<TextField field="primaryText" label="Nadpis" />
				<ProductListField field={'productList'} />
				<LinkField field={'link'} />
			</Block>
			<Block discriminateBy="productCategory" label="Výpis produktů z kategorie">
				<TextField field="primaryText" label="Nadpis" />
				<CategorySelectField field={'productCategory'} label={undefined} />
			</Block>
			<Block discriminateBy="productAttributeOptions" label="Výpis produktů podle modelové řady">
				<LocaleSelectField
					label={undefined}
					entities={
						{
							entityName: 'ProductAttributeOption',
							filter: { attribute: { id: { eq: 'bd7ff8c8-1067-4e8b-ac58-3a488e766bb6' } } },
						} as any
					}
					localizedField={'name'}
					field={'productAttributeOptions'}
				/>
			</Block>
			<Block discriminateBy="brands" label="Značky">
				<Repeater field="brandList.items" sortableBy="order" label={undefined}>
					<SelectField label={'Značka'} options={'Brand.name'} field={'brand'} />
				</Repeater>
				<LinkField field={'link'} />
			</Block>
			<Block discriminateBy="inspiration" label="Inspirace">
				<TextField field="primaryText" label="Nadpis" />
				<SelectField options={'Inspiration.internalName'} field={'inspiration'} label={undefined} />
			</Block>
			<Block discriminateBy="faq" label={'FAQ'}>
				<TextField field="primaryText" label="Nadpis" />
				<Repeater field={'contentList.items'} sortableBy={'order'} label={undefined}>
					<BlockEditor
						field="subContent.parts"
						referencesField="references"
						referenceDiscriminationField="type"
						label="Odpověď"
						sortableBy="order"
						contentField="json"
						blockButtons={[
							{
								contemberIcon: 'gallery',
								discriminateBy: 'gallery',
								title: 'Galerie',
							},
							{
								blueprintIcon: 'video',
								discriminateBy: 'video',
								title: 'Video',
							},
							{
								contemberIcon: 'download',
								discriminateBy: 'attachment',
								title: 'Soubor ke stažení',
							},
							RB.table,
						]}
						leadingFieldBackedElements={[
							{
								field: 'primaryText',
								placeholder: 'Dotaz',
								format: 'plainText',
								render: (props) => <p>{props.children}</p>,
							},
						]}
					>
						{embedBLock}
						{galleryBlock}
						{videoBlock}
						{attachmentBlock}
					</BlockEditor>
				</Repeater>
			</Block>
			<Block discriminateBy="faqLinkable" label={'FAQ (s odkazy)'}>
				<TextField field="primaryText" label="Nadpis" />
				<Repeater field={'contentList.items'} sortableBy={'order'} label={undefined}>
					<TextField field="primaryText" label="Dotaz" />
					<LinkField field="link" />
				</Repeater>
			</Block>
			<Block discriminateBy="loyaltyTariffsTable" label={'Tabulka tarifů'}>
				<TextField field="primaryText" label="Nadpis" />
				<Repeater field={'loyaltyTariffsTable.items'} sortableBy={'order'} label={undefined}>
					<TextAreaField field="title" label="title" />
					<TextAreaField field="info" label="info" />
					<TextAreaField field="basicTariff" label="basicTariff" />
					<TextAreaField field="bronzeTariff" label="bronzeTariff" />
					<TextAreaField field="silverTariff" label="silverTariff" />
					<TextAreaField field="goldTariff" label="goldTariff" />
				</Repeater>
			</Block>
			<Block discriminateBy="loyaltyPassLink" label={'Endorphin pas registrace'}>
				<TextField field="primaryText" label="Text tlačítka" />
				<LinkField field="link" />
				<TextField field="secondaryText" label="Text" />
			</Block>
			<Block
				discriminateBy="highlights"
				label={'Výhody'}
				alternate={
					<>
						<SelectField
							field="color"
							label="Barevné schéma"
							options={[
								{ value: 'light', label: 'Světlé' },
								{ value: 'dark', label: 'Tmavé' },
							]}
						/>
					</>
				}
			>
				<Repeater field={'contentList.items'} sortableBy={'order'} label={undefined}>
					<TextAreaField field="primaryText" label="Popis" />
					<TextAreaField field="secondaryText" label="Hodnota v boxu" />
					<LinkField field="link" />
					<IconSelectField label="Ikonka" field="icon" />
				</Repeater>
			</Block>
			<Block
				discriminateBy="linkTiles"
				label={'Dlaždice s odkazy'}
				alternate={
					<>
						<SelectField
							field="linkLayout"
							label="Rozložení odkazů"
							options={[
								{ value: 'grid', label: 'Grid' },
								{ value: 'gridWithLabels', label: 'Grid se štítky' },
								{ value: 'list', label: 'Carousel (čtverec)' },
								{ value: 'carousel', label: 'Carousel (portrét)' },
								{ value: 'helper', label: 'Rádce' },
							]}
						/>
					</>
				}
			>
				<TextField field="primaryText" label="Nadpis" />
				<Repeater field={'contentList.items'} sortableBy={'order'} label={undefined}>
					<TextAreaField field="primaryText" label="Text" />
					<TextAreaField field="secondaryText" label="Popis" />
					<ImageField field={'image'} label={'Obrázek'} />
					<LinkField field={'link'} />
				</Repeater>
			</Block>
			<Block discriminateBy="serviceGroupTiles" label={'Služby'}>
				<Repeater field={'contentList.items'} sortableBy={'order'} label={undefined}>
					<TextField field="primaryText" label="Text" />
					<LocaleSelectField
						label="Typ služby"
						entities="ServiceType"
						localizedField="name"
						field="serviceType"
					/>
					<ImageField field={'image'} label={'Obrázek'} />
					<LinkField field={'link'} />
				</Repeater>
				<Box heading="Banner">
					<ImageField label="Malý" field="banner.smallImage" />
					<ImageField label="Střední" field="banner.mediumImage" />
				</Box>
			</Block>
			<Block discriminateBy="serviceTiles" label={'Služby'}>
				<Repeater field={'contentList.items'} sortableBy={'order'} label={undefined}>
					<ImageField field={'image'} label={'Obrázek'} />
					<TextField field="primaryText" label="Název" />
					<TextAreaField field="longText" label="Text" />
					<TextField field="buttonText" label="Cena" />
					<LinkField field={'link'} />
				</Repeater>
			</Block>
			<Block discriminateBy="carousel" label={'Velký carousel'}>
				<Repeater field={'contentList.items'} sortableBy={'order'} label={undefined}>
					<TextAreaField field="primaryText" label="Text" />
					<TextAreaField field="secondaryText" label="Popis" />
					<TextAreaField field="buttonText" label="Text na tlačítku" />
					<ImageField field={'image'} label={'Obrázek'} />
					<ImageField
						field={'secondaryImage'}
						label="Obrázek (mobil)"
						labelDescription={'Doporučené rozměry obrázku: 768x434px'}
					/>
					<LinkField field={'link'} />
					<SelectField label={'Barva'} options={carouselColorOptions} field={'carouselColor'} />
					<div className={'horizontal-fields'}>
						<DateTimeField field="visibilityInterval.showSinceDate" label="Viditelné od" />
						<DateTimeField field="visibilityInterval.showUntilDate" label="Viditelné do" />
					</div>
				</Repeater>
			</Block>
			<Block discriminateBy="people" label={'Lidé'}>
				<TextField field="primaryText" label="Nadpis" />
				<Repeater field={'contentList.items'} sortableBy={'order'} label={undefined}>
					<TextField field="primaryText" label="Jméno" />
					<TextField field="secondaryText" label="Pozice" />
					<TextAreaField field="longText" label="Popis" />
					<ImageField field={'image'} label={'Obrázek'} />
				</Repeater>
			</Block>
			<Block
				discriminateBy="banner"
				label="Banner"
				alternate={
					<>
						<SelectField
							field="bannerLayout"
							label="Rozložení banneru"
							options={[
								{ value: 'simple', label: 'Text s obrázkem' },
								{ value: 'square', label: 'Čtverec' },
								{ value: 'wide', label: 'Široký' },
								{ value: 'tile', label: 'Dlaždice' },
								{ value: 'textOverImage', label: 'Text přes obrázek' },
								{ value: 'black', label: 'Černo-žlutý' },
							]}
						/>
						<SelectField
							field="color"
							label="Barevné schéma"
							options={[
								{ value: 'dark', label: 'Tmavé' },
								{ value: 'light', label: 'Světlé' },
							]}
						/>
						<SelectField
							field="bannerTextAlignment"
							label="Zarovnání textu"
							options={[
								{ value: 'left', label: 'Vlevo' },
								{ value: 'center', label: 'Vycentrovat' },
								{ value: 'right', label: 'Vpravo' },
							]}
						/>
						<SelectField
							field="bannerImagePosition"
							label="Zarovnání obrázku"
							options={[
								{ value: 'left', label: 'Vlevo' },
								{ value: 'right', label: 'Vpravo' },
							]}
						/>
					</>
				}
			>
				<TextField field="primaryText" label="Nadpis" />
				<BlockEditor.ContentOutlet />
				<IfField field="bannerLayout" match="black">
					<TextField field="secondaryText" label="Velký nápis (200 Kč)" />
				</IfField>
				<ImageField field={'image'} label={'Obrázek'} />
				<IfField field="bannerLayout" match={(v) => v !== 'black'}>
					<IconSelectField label="Ikonka" field="icon" />
				</IfField>
			</Block>
			<Block discriminateBy="popupTechnology" label="Popup technologie">
				<SelectField field="technology" options={'ProductTechnology.code'} label={undefined} />
				<TextField field="primaryText" label="Popisek tlačítka" />
			</Block>
			<Block discriminateBy="popupGeometry" label="Popup geometrie">
				<SelectField field="geometry" options={'ProductGeometry.internalName'} label={undefined} />
				<TextField field="primaryText" label="Popisek tlačítka" />
			</Block>
			<Block discriminateBy="orderService" label={'Objednat službu'}>
				<HasOne field="orderService">
					<TextField field="price" label="Cena" />

					<Box heading="Ceník">
						<LinkField field={'pricing'} />
					</Box>

					<TextField field="internalName" label="Interní název (pro maily atd)" />
					<TextField field="infoText" label="Info text" />
					<TextField field="infoHint" label="Info hint" />

					<TextField field="buttonText" label="Popisek tlačítka" />

					<SelectField
						field="type"
						label="Typ"
						options={[
							{ value: 'link', label: 'Odkaz' },
							{ value: 'modal', label: 'Modal formulář' },
						]}
					/>

					<DependentCollapsible field="type" condition={(value) => value === 'link'}>
						<LinkField field={'link'} />
					</DependentCollapsible>
					<MultiSelectField
						label={'Omezeno na prodejny'}
						options={'Store[hidden=false].internalName'}
						field={'stores'}
					/>
				</HasOne>
			</Block>
			<Block discriminateBy="storeList" label={'Seznam prodejen'}>
				<TextField field="primaryText" label="Nadpis" />
			</Block>
			<Block discriminateBy="contentWithLinks" label={'Obsah s odkazy'}>
				<TextField field="primaryText" label="Nadpis obsahového sloupce" />
				<ContentOutlet />
				<Box heading="Odkazy">
					<TextField field="secondaryText" label="Nadpis" />
					<Repeater field={'contentList.items'} sortableBy={'order'} label={undefined}>
						<TextField field="primaryText" label="Text" />
						<LinkField field="link" />
					</Repeater>
				</Box>
			</Block>
			<Block discriminateBy="scrollTarget" label="Kotva">
				<TextField field="primaryText" label="Identifikátor" />
			</Block>
		</BlockEditor>
	)
})

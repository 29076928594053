import { Box, Field, SelectField, TableCell, TextField } from '@contember/admin'
import * as React from 'react'
import { TranslationEditButton } from '../../components'
import { createCrudPages } from '../../utils'

export const TranslationCataloguePages = createCrudPages({
	entityName: 'TranslationCatalogue',
	title: 'Překladové katalogy',
	listPageProps: {
		orderBy: 'domain.name asc, name asc',
	},
	form: (
		<Box>
			<SelectField field="domain" label="Doména" options="TranslationDomain.name" />
			<TextField field="identifier" label="Identifikátor" allowNewlines={false} />
			<TextField field="name" label="Název" allowNewlines={false} />
			<SelectField field="fallback" label="Záložní katalog" options="TranslationCatalogue.name" />
		</Box>
	),
	table: (
		<>
			<TableCell>
				<Field field="domain.name" />
			</TableCell>

			<TableCell>
				<Field field="name" />
			</TableCell>

			<TableCell shrunk>
				<TranslationEditButton pageName="translationCatalogueEdit" />
			</TableCell>
		</>
	),
})

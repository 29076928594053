import { Component, Repeater, SelectField, TableCell, TextField } from '@contember/admin'
import { TableRepeaterContainer, TableRepeaterItem } from '../Generic'
import * as React from 'react'

export const ProductLinksRepeater = Component((props) => (
	<Repeater
		field={'links'}
		label={'URL'}
		initialEntityCount={0}
		orderBy={undefined}
		enableAddingNew={__DEV_MODE__}
		enableRemoving={__DEV_MODE__}
		containerComponent={TableRepeaterContainer}
		itemComponent={TableRepeaterItem}
		containerComponentExtraProps={{
			addButtonText: 'Přidat URL',
		}}
	>
		<TableCell>
			<SelectField label={'Kanál'} options={'Channel.internalName'} field={'channel'} />
		</TableCell>
		<TableCell>
			<TextField field={'url'} label={'URL'} />
		</TableCell>
	</Repeater>
))

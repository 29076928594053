import { Component, EntityListSubTree, Field, ParentEntity, StaticRender } from '@contember/admin'

export const CategoryCodeProvider = Component(() => (
	<StaticRender>
		<ParentEntity
			onInitialize={(getAccessor, { getEntityListSubTree }) => {
				const field = getAccessor().getField('code')

				if (field.value !== null) {
					return
				}

				const codeSubtree = getEntityListSubTree('codeProvider')
				const entities = Array.from(codeSubtree)
				const newCode = !entities.length ? 1 : (entities[0].getField<number>('code').value ?? 0) + 1

				field.updateValue(newCode)
			}}
		>
			<Field field="code" />
			<EntityListSubTree
				entities="ProductCategory[code != null]"
				expectedMutation="none"
				limit={1}
				orderBy="code desc"
				alias="codeProvider"
			>
				<Field field="code" />
			</EntityListSubTree>
		</ParentEntity>
	</StaticRender>
))

import { Box, Field, RichTextField, SelectField, TableCell, TextField } from '@contember/admin'
import * as React from 'react'
import { CollapsibleBox, ImageField, LinkUrlField, SeoForm } from '../../components'
import { ContentEditor } from '../../components/Content/ContentEditor'
import { createCrudPages } from '../../utils'

export const PagePages = createCrudPages({
	entityName: 'Page',
	title: 'Obsahové stránky',
	listPageProps: {
		entities: 'Page[$channelFilter]',
	},
	form: (
		<>
			<Box>
				<ImageField
					field={'image'}
					alternate={
						<Box>
							<TextField field="imageTitle" label="Nadpis" />
							<RichTextField field="imageText" label="Text" />
						</Box>
					}
				/>
				<ContentEditor
					leadingElements={[
						{
							field: 'title',
							placeholder: 'Titulek',
							format: 'plainText',
							render: (props) => <h1 style={{ fontSize: '2.5em', fontWeight: 600 }}>{props.children}</h1>,
						},
					]}
				/>
			</Box>
			<CollapsibleBox heading={'Nastavení SEO'}>
				<SeoForm titleDerivedFrom={'title'} />
			</CollapsibleBox>
		</>
	),
	sideForm: (
		<Box>
			<SelectField field={'channel'} label={'Kanál'} options={'Channel.internalName'} allowNull={true} />
			<LinkUrlField derivedFrom={'title'} label={'URL'} />
			<SelectField
				allowNull
				label={'Speciální typ stránky'}
				options={[
					{ value: 'homepage', label: 'Homepage' },
					{ value: 'faq', label: 'FAQ' },
					{ value: 'contact', label: 'Kontakt' },
					{ value: 'signUp', label: 'Registrace' },
					{ value: 'emailVerification', label: 'Ověření emailu' },
					{ value: 'signIn', label: 'Přihlášení' },
					{ value: 'passwordResetRequest', label: 'Žádost o reset hesla' },
					{ value: 'passwordReset', label: 'Reset hesla' },
					{ value: 'brandList', label: 'Značky' },
					{ value: 'userFavorites', label: 'Oblíbené' },
					{ value: 'search', label: 'Vyhledávání' },
					{ value: 'inspirationList', label: 'Inspirace' },
					{ value: 'serviceList', label: 'Služby' },
					{ value: 'loyaltyProgram', label: 'Věrnostní program' },
					{ value: 'userLoyaltyProgram', label: 'Uživatelský profil: Věrnostní program' },
					{ value: 'userService', label: 'Uživatelský profil: Servis' },
					{ value: 'sale', label: 'Výprodej' },
					{ value: 'goodsReturn', label: 'Vrácení zboží' },
					{ value: 'privacyPolicy', label: 'Ochrana osobních údajů' },
					{ value: 'termsAndConditions', label: 'Obchodní podmínky' },
					{ value: 'transportAndPayment', label: 'Doprava a platba' },
					{ value: 'comparator', label: 'Porovnávač' },
				]}
				field={'specialPage'}
			/>
			<SelectField label="Nadřazená stránka" options="Page.title" field="parent" allowNull={true} />
		</Box>
	),
	table: (
		<>
			<TableCell>
				<Field field="title" />
			</TableCell>
			<TableCell>
				<Field field="channel.internalName" />
			</TableCell>
		</>
	),
})

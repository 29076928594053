import { useAuthedContentQuery } from '@contember/admin'

const CATALOGUES_QUERY = `
	query($domain: String!, $catalogues: [String!]!) {
		list: listTranslationCatalogue(
			filter: {
				domain: { identifier: { eq: $domain } },
				identifier: { in: $catalogues }
			}
		) {
			identifier
			name
		}
	}
`

export type TranslationCatalogueQueryEntry = { identifier: string; name: string }
export type TranslationCatalogueQueryResult = { list: TranslationCatalogueQueryEntry[] }
export type TranslationCatalogueQueryVariables = { domain: string; catalogues: string[] }

export const useTranslationCatalogueQuery = (
	variables: TranslationCatalogueQueryVariables,
): TranslationCatalogueQueryEntry[] | undefined => {
	const response = useAuthedContentQuery<TranslationCatalogueQueryResult, TranslationCatalogueQueryVariables>(
		CATALOGUES_QUERY,
		variables,
	)
	return response.state.data?.list
}

import { FeedServiceEnumValues } from '../../../../api/model'
import { Component, MultiSelectField } from '@contember/admin'
import * as React from 'react'

interface CategoryFeedMappingProps {
	service: FeedServiceEnumValues
}

export const CategoryFeedMapping = Component<CategoryFeedMappingProps>(({ service }) => {
	return (
		<MultiSelectField
			field={`feedMappings[service=${service}]`}
			label={service}
			options={`FeedCategory[service=${service}].name`}
		/>
	)
})

import { Component, Field } from '@contember/admin'
import { isDefined, localizedCell } from '../../utils'
import { HasManyFilterCell } from '../Generic/HasManyFilterCell'
import { Fragment } from 'react'
import * as React from 'react'

interface ProductCategoriesCellProps {
	field: string
	onlyIfField?: string
}
export const ProductCategoriesCell = Component<ProductCategoriesCellProps>((props) => (
	<>
		{localizedCell((locale) => (
			<HasManyFilterCell
				field={props.field}
				onlyIfField={props.onlyIfField}
				header={`Kategorie\u00A0(${locale})`}
				hidden={locale !== 'cs'}
				createWhere={(query, value) => ({
					or: [
						{
							locales: {
								locale: {
									code: { eq: locale },
								},
								name: query,
							},
						},
						Number(value).toString() === value
							? {
									code: { eq: Number(value) },
							  }
							: undefined,
					].filter(isDefined),
				})}
				render={({ entities }) => (
					<>
						{entities.map((entity) => (
							<Fragment key={entity.key}>
								{entity.getField(`locales(locale.code='${locale}').name`).value} (
								{entity.getField('code').value})
								<br />
							</Fragment>
						))}
					</>
				)}
			>
				<Field field={`locales(locale.code='${locale}').name`} />
				<Field field={'code'} />
			</HasManyFilterCell>
		))}
	</>
))

import {
	Component,
	DataGridCellPublicProps,
	DataGridColumn,
	DataGridHeaderCellPublicProps,
	DataGridOrderDirection,
	Field,
	FieldFallbackView,
	FieldFallbackViewPublicProps,
	FieldValue,
	Input,
	QueryLanguage,
	Select,
	SugaredRelativeSingleField,
	TextInput,
	wrapFilterInHasOnes,
} from '@contember/admin'
import { FunctionComponent, ReactElement, ReactNode } from 'react'

export type NumberCellProps<Persisted extends FieldValue = FieldValue> = DataGridHeaderCellPublicProps &
	DataGridCellPublicProps &
	FieldFallbackViewPublicProps &
	SugaredRelativeSingleField & {
		disableOrder?: boolean
		initialOrder?: DataGridOrderDirection
		format?: (value: Persisted) => ReactNode
	}

interface NumberFilterArtifacts {
	mode: 'eq' | 'gte' | 'lte'
	query: string
}

export const NumberCell: FunctionComponent<NumberCellProps> = Component((props) => {
	return (
		<DataGridColumn<NumberFilterArtifacts>
			{...props}
			enableOrdering={!props.disableOrder as true}
			getNewOrderBy={(newDirection, { environment }) =>
				newDirection && QueryLanguage.desugarOrderBy(`${props.field as string} ${newDirection}`, environment)
			}
			getNewFilter={(filter, { environment }) => {
				if (filter.query === '') {
					return undefined
				}

				const baseOperators = {
					eq: 'eq',
					gte: 'gte',
					lte: 'lte',
				}

				const condition: Input.Condition<string> = {
					[baseOperators[filter.mode]]: Number(filter.query),
				}

				const desugared = QueryLanguage.desugarRelativeSingleField(props, environment)
				return wrapFilterInHasOnes(desugared.hasOneRelationPath, {
					[desugared.field]: condition,
				})
			}}
			emptyFilter={{
				mode: 'eq',
				query: '',
			}}
			filterRenderer={({ filter, setFilter }) => {
				const options: Array<{
					value: NumberFilterArtifacts['mode']
					label: string
				}> = [
					{ value: 'eq', label: 'rovná se' },
					{ value: 'gte', label: 'větší než' },
					{ value: 'lte', label: 'menší než' },
				]
				return (
					<div style={{ display: 'flex', gap: '0.5em', alignItems: 'center' }}>
						<Select
							value={filter.mode}
							options={options}
							onChange={(e) => {
								const value = e.currentTarget.value as NumberFilterArtifacts['mode']

								setFilter({
									...filter,
									mode: value,
								})
							}}
						/>
						<TextInput
							value={filter.query}
							placeholder="Value"
							onChange={(e) => {
								const value = e.currentTarget.value
								setFilter({
									...filter,
									query: value,
								})
							}}
						/>
					</div>
				)
			}}
		>
			<Field
				{...props}
				format={(value) => {
					if (value === null) {
						return <FieldFallbackView fallback={props.fallback} fallbackStyle={props.fallbackStyle} />
					}
					if (props.format) {
						return props.format(value as any)
					}
					return value
				}}
			/>
		</DataGridColumn>
	)
}, 'NumberCell') as <Persisted extends FieldValue = FieldValue>(props: NumberCellProps<Persisted>) => ReactElement

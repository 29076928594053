import { ProjectConfig } from '@contember/admin'
import * as React from 'react'
import { lcfirst, ucfirst } from './strings'
import { JSXElementConstructor } from 'react'

export const getPageName = (baseName: string, type: string) => {
	return `${lcfirst(baseName)}${ucfirst(type)}`
}
export class PageSet {
	constructor(
		public readonly baseName: string,
		public readonly routes: ProjectConfig['routes'],
		public readonly pages: React.ReactElement[],
	) {}

	link(type: string) {
		const pageName = getPageName(this.baseName, type)
		if (!this.routes[pageName]) {
			throw `Undefined page ${pageName}`
		}
		return pageName
	}
}

export class Page<P> {
	public readonly pageName: string
	constructor(
		public readonly route: ProjectConfig['routes'][string],
		public readonly page:
			| React.ReactElement<P, JSXElementConstructor<any> & { getPageName: (props: P) => string }>
			| React.ReactElement<{ name: string }, any>,
	) {
		this.pageName =
			'getPageName' in page.type
				? page.type.getPageName(page.props)
				: 'name' in page.props
				? page.props.name
				: (() => {
						throw new Error()
				  })()
	}
}

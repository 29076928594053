import {
	Component,
	DeferredSubTrees,
	EntityAccessor,
	Field,
	Filter,
	FormGroupProps,
	HasMany,
	HasOne,
	MultiSelectField,
	Repeater,
	SelectField,
} from '@contember/admin'
import * as React from 'react'
import { LoadingMessage } from '../Generic'

export interface ProductSelectFieldProps {
	field: string
	filter?: Filter
	label?: FormGroupProps['label']
	children?: React.ReactChild
}

const productsStaticRender = (
	<HasMany field={'base.locales'}>
		<Field field={'title'} />
	</HasMany>
)
const renderOption = (accessor: EntityAccessor) => {
	const locales = accessor.getEntityList('base.locales')
	return (
		accessor.getField('code').value +
		': ' +
		Array.from(locales, (locale) => locale.getField('title').value).join(' / ')
	)
}

const createOptions = (filter?: Filter) => ({
	entityName: 'Product',
	filter: {
		...filter,
		variants: {
			states: {
				visibleInListing: { eq: true },
				...(filter?.variants as Filter)?.states,
			},
			...filter?.variants,
		},
	},
})

const BareProductSelectField = Component<ProductSelectFieldProps>((props) => (
	<SelectField
		field={props.field}
		label={props.label}
		searchByFields={'code'}
		options={createOptions(props.filter)}
		optionsStaticRender={productsStaticRender}
		renderOption={renderOption}
	/>
))

export const ProductSelectField = Component<ProductSelectFieldProps>((props) => (
	<DeferredSubTrees fallback={<LoadingMessage>Načítám produkty…</LoadingMessage>}>
		<BareProductSelectField {...props} />
	</DeferredSubTrees>
))

export const ProductMultiSelectField = Component<ProductSelectFieldProps>((props) => (
	<DeferredSubTrees fallback={<LoadingMessage>Načítám produkty…</LoadingMessage>}>
		<MultiSelectField
			field={props.field}
			label={props.label}
			searchByFields={'code'}
			options={createOptions(props.filter)}
			optionsStaticRender={productsStaticRender}
			renderOption={renderOption}
		/>
		{props.children}
	</DeferredSubTrees>
))

export interface ProductListFieldProps {
	field: string
	productFilter?: Filter
	label?: FormGroupProps['label']
}

export const ProductListField = Component<ProductListFieldProps>((props) => (
	<HasOne field={props.field}>
		<DeferredSubTrees fallback={<LoadingMessage>Načítám produkty…</LoadingMessage>}>
			<Repeater field="items" sortableBy="order" label={props.label}>
				<BareProductSelectField field={'product'} label={'Produkt'} filter={props.productFilter} />
			</Repeater>
		</DeferredSubTrees>
	</HasOne>
))

import XLSX, { utils as xlsxUtils } from 'xlsx'

export type SpreadsheetRow = Map<string, string | undefined>

interface SpreadsheetHeader {
	key: string
	label: string
}

export interface Spreadsheet {
	name: string
	header: SpreadsheetHeader[]
	data: SpreadsheetRow[]
}

export interface SpreadsheetFile {
	fileName: string
	sheets: Spreadsheet[]
}

export const readXlsx = (fileName: string, data: Uint8Array): SpreadsheetFile => {
	const xlsx = XLSX.read(data, { type: 'array' })
	const sheets = xlsx.SheetNames.map(
		(name): Spreadsheet => {
			const sheet = xlsx.Sheets[name]
			const rangeStr = sheet['!ref']
			if (!rangeStr) {
				return { name, header: [], data: [] }
			}
			const range = xlsxUtils.decode_range(rangeStr)
			const header: SpreadsheetHeader[] = []
			const headerKeys = new Set<string>()
			const data: SpreadsheetRow[] = []
			let hasHeader = false
			for (let row = range.s.r; row <= Math.min(200000, range.e.r); ++row) {
				const rowData: SpreadsheetRow = new Map()
				const rowValues = []
				let hasValue = false
				for (let cell = range.s.c; cell <= range.e.c; ++cell) {
					const cellAddress = { c: cell, r: row }
					const cellRef = XLSX.utils.encode_cell(cellAddress)
					const cellObj = sheet[cellRef]
					const formattedValue = typeof cellObj === 'object' && 'w' in cellObj ? cellObj.w : undefined
					const rawValue = typeof cellObj === 'object' && 'v' in cellObj ? cellObj.v : undefined
					if (rawValue !== undefined && rawValue !== '') {
						hasValue = true
					}
					rowValues[cell] = formattedValue?.trim()
				}
				if (!hasValue) {
					continue
				}
				if (!hasHeader) {
					for (const cell in rowValues) {
						const headerName = rowValues[cell] || '__unnamed_' + cell
						const key = headerKeys.has(headerName) ? headerName + '__' + cell : headerName
						header.push({
							key,
							label: headerName,
						})
						headerKeys.add(key)
					}
					hasHeader = true
					continue
				}
				for (const cell in rowValues) {
					rowData.set(header[cell].key, rowValues[cell])
				}
				data.push(rowData)
			}
			return { name, header, data }
		},
	)
	return { fileName, sheets }
}

export const downloadXlsx = (data: any[][], filename: string) => {
	const wb = XLSX.utils.book_new()
	XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(data), 'List 1')
	XLSX.writeFile(wb, filename)
}

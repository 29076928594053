import { Spreadsheet, SpreadsheetFile } from '../utils'
import { useAuthedContentMutation, useShowToastWithTimeout } from '@contember/admin'
import { useCallback } from 'react'

export interface SaveSpreadsheetsMethods {
	save: (spreadsheet: Spreadsheet) => Promise<string[]>
}

const useUpdateProductImportBatch = () => {
	const [saveProducts] = useAuthedContentMutation<
		{
			update: { ok: boolean; errorMessage?: string }
		},
		{ id: string; items: { create: { data: any } }[] }
	>(`mutation ($id: UUID!, $items: [ProductImportBatchUpdateItemsEntityRelationInput!]!) {
		update: updateProductImportBatch(by: {id: $id}, data: {items: $items}) {
			ok
			errorMessage
		}
	}`)
	return saveProducts
}

const useCreateProductImportBatch = () => {
	const [create] = useAuthedContentMutation<
		{
			create: { ok: boolean; errorMessage?: string; node: { id: string } }
		},
		{ fileName: string; fields: { create: { fieldName: string; label: string; target: string } }[] }
	>(`mutation ($fileName: String!, $fields: [ProductImportBatchCreateFieldsEntityRelationInput!]!) {
		create: createProductImportBatch(data: {fileName: $fileName, fields: $fields}) {
			ok
			errorMessage
			node {
				id
			}
		}
	}`)
	return create
}

export const useSaveSpreadsheet = (): SaveSpreadsheetsMethods => {
	const createBatch = useCreateProductImportBatch()
	const updateBatch = useUpdateProductImportBatch()
	const showToast = useShowToastWithTimeout()
	return {
		save: useCallback(
			async (sheet) => {
				let anySuccess = false
				let anyError = false
				const ids = []
				const importBatchSize = 5000
				const saveBatchSize = 200
				let totalI = 0
				do {
					const name = sheet.name + ` (${totalI}+)`
					const fields = sheet.header.map((it, order) => ({
						create: { fieldName: it.key, label: it.label, target: 'omit', order },
					}))
					const result = await createBatch({ fileName: name, fields })
					if (!result.create.ok) {
						console.error(result.create.errorMessage)
						anyError = true
						showToast({
							message: `Nepovedlo se uložit: ${name} (${result.create.errorMessage})`,
							type: 'error',
						})
						continue
					}
					const id = result.create.node.id
					ids.push(id)
					for (
						let i = 0;
						totalI < sheet.data.length && i < importBatchSize;
						i += saveBatchSize, totalI += saveBatchSize
					) {
						anySuccess = true
						const items = sheet.data
							.slice(totalI, totalI + saveBatchSize)
							.map((it) => ({ create: { data: Object.fromEntries(it) } }))
						const result = await updateBatch({
							id: id,
							items,
						})
						if (!result.update.ok) {
							anyError = true
							showToast({
								message: `Nepovedlo se uložit položky ${i} - ${i + saveBatchSize} z ${name} (${
									result.update.errorMessage
								})`,
								type: 'error',
							})
						}
					}
					if (sheet.data.length > importBatchSize) {
						showToast({
							message: `Dávka ${name} importována`,
							type: 'success',
						})
					}
				} while (totalI < sheet.data.length)

				if (anySuccess && !anyError) {
					showToast({
						message: `List ${sheet.name} byl importován`,
						type: 'success',
					})
				} else if (anySuccess && anyError) {
					showToast({
						message: `List ${sheet.name} byl částečně importován`,
						type: 'warning',
					})
				} else {
					showToast({
						message: `List ${sheet.name} se nepovedlo importovat`,
						type: 'error',
					})
				}
				return ids
			},
			[createBatch, showToast, updateBatch],
		),
	}
}

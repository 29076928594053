import * as React from 'react'
import { BoxSection, CreateNewEntityButton, EmptyMessage, ErrorList, RepeaterContainerProps } from '@contember/admin'
import { CollapsibleBox } from './CollapsibleBox'

export const CollapsibleRepeaterContainer = React.memo(
	({
		accessor,
		children,
		createNewEntity,
		addButtonComponent: AddButton = CreateNewEntityButton,
		addButtonComponentExtraProps,
		addButtonProps,
		addButtonText = 'Add',
		emptyMessage = 'There is nothing here. Try adding a new item.',
		emptyMessageComponent: EmptyMessageComponent = EmptyMessage,
		emptyMessageComponentExtraProps,
		enableAddingNew = true,
		isEmpty,
		label,
	}: RepeaterContainerProps) => {
		return (
			<CollapsibleBox heading={label}>
				<ErrorList errors={accessor.errors} />
				{isEmpty && (
					<EmptyMessageComponent {...emptyMessageComponentExtraProps}> {emptyMessage} </EmptyMessageComponent>
				)}
				{isEmpty || children}
				{enableAddingNew && (
					<BoxSection heading={undefined}>
						<AddButton
							{...addButtonComponentExtraProps}
							children={addButtonText}
							{...addButtonProps}
							createNewEntity={createNewEntity}
						/>
					</BoxSection>
				)}
			</CollapsibleBox>
		)
	},
)
CollapsibleRepeaterContainer.displayName = 'CollapsibleRepeaterContainer'

import {
	Component,
	DataGridCellPublicProps,
	DataGridColumn,
	DataGridHeaderCellPublicProps,
	Field,
	FieldFallbackView,
	FieldFallbackViewPublicProps,
	FieldValue,
	Filter,
	Input,
	QueryLanguage,
	Select,
	SugarableRelativeSingleField,
	TextInput,
	useEntity,
	wrapFilterInHasOnes,
} from '@contember/admin'
import { FunctionComponent, ReactElement, ReactNode } from 'react'

interface CoalesceFieldProps<Persisted extends FieldValue = FieldValue> {
	fields: (SugarableRelativeSingleField | string)[]
	format?: (value: Persisted) => ReactNode
}
export type CoalesceTextCellProps<Persisted extends FieldValue = FieldValue> = DataGridHeaderCellPublicProps &
	DataGridCellPublicProps &
	FieldFallbackViewPublicProps &
	CoalesceFieldProps<Persisted>
interface TextFilterArtifacts {
	mode: 'matches' | 'matchesExactly' | 'startsWith' | 'endsWith' | 'doesNotMatch'
	query: string
}

const CoalesceField = Component<CoalesceFieldProps<any> & FieldFallbackViewPublicProps>(
	(props) => {
		const entity = useEntity()
		let value: any = null
		for (const field of props.fields) {
			value = entity.getField<any>(field).value
			if (value !== null) {
				break
			}
		}

		if (value === null) {
			return <FieldFallbackView fallback={props.fallback} fallbackStyle={props.fallbackStyle} />
		}
		if (props.format) {
			return props.format(value as any)
		}
		return value
	},
	(props) => {
		return (
			<>
				{props.fields.map((it) => (
					<Field field={it} />
				))}
			</>
		)
	},
)

export const CoalesceTextCell: FunctionComponent<CoalesceTextCellProps> = Component((props) => {
	return (
		<DataGridColumn<TextFilterArtifacts>
			{...props}
			enableOrdering={false}
			getNewFilter={(filter, { environment }) => {
				if (filter.query === '') {
					return undefined
				}

				const baseOperators = {
					matches: 'containsCI',
					doesNotMatch: 'containsCI',
					startsWith: 'startsWithCI',
					endsWith: 'endsWithCI',
					matchesExactly: 'eq',
				}

				let condition: Input.Condition<string> = {
					[baseOperators[filter.mode]]: filter.query,
				}

				if (filter.mode === 'doesNotMatch') {
					condition = { not: condition }
				}
				const ors: Filter[] = []
				for (const field of props.fields) {
					const desugared = QueryLanguage.desugarRelativeSingleField({ field: field }, environment)
					const fieldCondition = wrapFilterInHasOnes(desugared.hasOneRelationPath, {
						[desugared.field]: condition,
					})
					ors.push(fieldCondition)
				}
				return { or: ors }
			}}
			emptyFilter={{
				mode: 'matches',
				query: '',
			}}
			filterRenderer={({ filter, setFilter }) => {
				const options: Array<{
					value: TextFilterArtifacts['mode']
					label: string
				}> = [
					{ value: 'matches', label: 'Contains' },
					{ value: 'doesNotMatch', label: "Doesn't contain" },
					{ value: 'matchesExactly', label: 'Matches exactly' },
					{ value: 'startsWith', label: 'Starts with' },
					{ value: 'endsWith', label: 'Ends with' },
				]
				return (
					<div style={{ display: 'flex', gap: '0.5em', alignItems: 'center' }}>
						<Select
							value={filter.mode}
							options={options}
							onChange={(e) => {
								const value = e.currentTarget.value as TextFilterArtifacts['mode']

								setFilter({
									...filter,
									mode: value,
								})
							}}
						/>
						<TextInput
							value={filter.query}
							placeholder="Query"
							onChange={(e) => {
								const value = e.currentTarget.value
								setFilter({
									...filter,
									query: value,
								})
							}}
						/>
					</div>
				)
			}}
		>
			<CoalesceField {...props} />
		</DataGridColumn>
	)
}, 'CoalesceTextCell') as <Persisted extends FieldValue = FieldValue>(
	props: CoalesceTextCellProps<Persisted>,
) => ReactElement

import { Component, Repeater, TextField } from '@contember/admin'
import { CollapsibleBox } from '../Generic'
import * as React from 'react'
import { useRelativeEntity } from '../Generic/RelativeEntity'
import { CategorySelectField } from '../Category'

const CategoryMappingField = Component(
	(props) => {
		const entity = useRelativeEntity()
		if (entity?.getField('code').value !== 'size') {
			return null
		}
		return <CategorySelectField field={'category'} label={'Kategorie'} />
	},
	() => <CategorySelectField field={'category'} label={'Kategorie'} />,
)
export const ProductAttributeMappings = Component((props) => {
	return (
		<CollapsibleBox heading={'Mapování hodnot'}>
			<Repeater field={'mappings'} label={undefined} orderBy={'value'}>
				<TextField field="value" label="Hodnota" />
				<CategoryMappingField />
			</Repeater>
		</CollapsibleBox>
	)
})

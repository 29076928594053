import { ActionableBox, Component, FormGroupProps, HasOne, ImageUploadField, StaticRender } from '@contember/admin'
import * as React from 'react'
import { createImgixUrl } from './imageUtils'

export interface ImageFieldProps {
	field: string
	label?: FormGroupProps['label']
	description?: FormGroupProps['description']
	labelDescription?: FormGroupProps['labelDescription']
	allowImageDisconnect?: boolean
	alternate?: React.ReactNode
}

export const ImageField = Component<ImageFieldProps>((props: ImageFieldProps) => {
	const urlField = 'url'
	return (
		<>
			<StaticRender>{props.alternate}</StaticRender>
			<ActionableBox editContents={props.alternate}>
				<HasOne field={props.field}>
					<ImageUploadField
						label={props.label}
						field={urlField}
						description={props.description}
						labelDescription={props.labelDescription}
						imageWidthField="width"
						imageHeightField="height"
						fileSizeField="size"
						fileTypeField="type"
						formatPreviewUrl={(url) => createImgixUrl(url, 200, 0)}
					/>
				</HasOne>
			</ActionableBox>
		</>
	)
})

import { AnchorBasedButtonProps, Button, PageLinkById } from '@contember/admin'
import * as React from 'react'

export type EditButtonProps = Omit<AnchorBasedButtonProps, 'Component'> & {
	pageName: string
	entityPath?: string
}

export const EditButton: React.ComponentType<EditButtonProps> = ({
	pageName,
	children,
	entityPath,
	...outerButtonProps
}) => (
	<PageLinkById
		change={(id, accessor) => ({
			name: pageName,
			params: { id: entityPath ? accessor.getEntity(entityPath).idOnServer : id },
		})}
		Component={({ isActive, ...buttonProps }) => (
			<Button
				{...buttonProps}
				{...outerButtonProps}
				{...(outerButtonProps.target === '_blank' ? { onClick: undefined } : {})}
				Component="a"
			>
				{children || 'Edit'}
			</Button>
		)}
	/>
)

import {
	AltPersistButton,
	CreatePage,
	CreatePageProps,
	DataBindingProvider,
	DataGrid,
	DeleteEntityButton,
	EditPage,
	EditPageProps,
	FeedbackRenderer,
	GenericCell,
	GenericPage,
	NavigateBackButton,
	PageLinkButton,
	ProjectConfig,
	TableCell,
	TablePage,
	TablePageProps,
	TitleBar,
} from '@contember/admin'
import * as React from 'react'
import { EditButton } from '../components'
import { lcfirst } from './strings'
import { getPageName, PageSet } from './pages'
import { isDefined } from './isDefined'
import { SugaredQualifiedEntityList } from '@contember/binding'

class PageConfig {
	constructor(
		public readonly route: ProjectConfig['routes'][string],
		public readonly name: string,
		public readonly title: string,
	) {}
}

export interface CrudPagesArgs {
	entityName: string
	routeName?: string
	table?: React.ReactElement
	grid?: React.ReactElement
	form: React.ReactElement
	sideForm?: React.ReactElement
	listPageFactory?: (args: FactoryArgs) => React.ReactElement
	listPageProps?: Partial<TablePageProps<any, any>>
	editPageFactory?: (args: FactoryArgs) => React.ReactElement
	editPageProps?: Partial<EditPageProps>
	createPageFactory?: (args: FactoryArgs) => React.ReactElement
	createPageProps?: Partial<CreatePageProps>
	gridPageProps?: {
		enableRemoving?: boolean
		entities?: SugaredQualifiedEntityList['entities']
		editButtonTarget?: string
	}
	enableAdding?: boolean
	title: string
}

interface FactoryArgs extends CrudPagesArgs {
	title: string
	pageName: string
	pages: Record<string, PageConfig>
}

const gridPageFactory = ({ entityName, grid, pageName, title, pages, gridPageProps, enableAdding }: FactoryArgs) => {
	return (
		<GenericPage pageName={pageName}>
			<DataBindingProvider stateComponent={FeedbackRenderer}>
				<TitleBar
					actions={
						enableAdding !== false ? <PageLinkButton to={pages.create.name}>Přidat</PageLinkButton> : null
					}
				>
					{title}
				</TitleBar>
				<DataGrid entities={gridPageProps?.entities || entityName} itemsPerPage={50}>
					{grid}
					<GenericCell shrunk canBeHidden={false}>
						<EditButton
							pageName={pages.edit.name}
							size={'small'}
							target={gridPageProps?.editButtonTarget}
						/>
						{gridPageProps?.enableRemoving && <DeleteEntityButton size={'small'} immediatePersist={true} />}
					</GenericCell>
				</DataGrid>
			</DataBindingProvider>
		</GenericPage>
	)
}

const listPageFactory = ({ entityName, table, pageName, title, pages, listPageProps, enableAdding }: FactoryArgs) => {
	return (
		<TablePage
			pageName={pageName}
			entities={entityName}
			{...listPageProps}
			rendererProps={{
				title,
				...listPageProps?.rendererProps,
				actions: (
					<>
						{enableAdding !== false ? <PageLinkButton to={pages.create.name}>Přidat</PageLinkButton> : null}
						{listPageProps?.rendererProps?.actions}
					</>
				),
			}}
		>
			{table}
			<TableCell shrunk>
				<EditButton pageName={pages.edit.name} />
			</TableCell>
		</TablePage>
	)
}

const editPageFactory = ({ entityName, pageName, title, pages, form, editPageProps, sideForm }: FactoryArgs) => {
	return (
		<EditPage
			entity={{ entityName, where: '(id = $id)' }}
			pageName={pageName}
			{...editPageProps}
			rendererProps={{
				title: title,
				navigation: <NavigateBackButton to={pages.list.name}>Výpis</NavigateBackButton>,
				persistButtonComponent: AltPersistButton,
				side: sideForm,
				...editPageProps?.rendererProps,
			}}
		>
			{form}
		</EditPage>
	)
}
const createPageFactory = ({ entityName, pageName, title, pages, form, createPageProps, sideForm }: FactoryArgs) => {
	return (
		<CreatePage
			entity={entityName}
			pageName={pageName}
			redirectOnSuccess={(request) => ({ ...request, pageName: pages.list.name })}
			{...createPageProps}
			rendererProps={{
				title: title,
				navigation: <NavigateBackButton to={pages.list.name}>Výpis</NavigateBackButton>,
				persistButtonComponent: AltPersistButton,
				side: sideForm,
				...createPageProps?.rendererProps,
			}}
		>
			{form}
		</CreatePage>
	)
}

export const createCrudPages = (args: CrudPagesArgs) => {
	const baseName = args.routeName || lcfirst(args.entityName)
	const listPage = new PageConfig({ path: `/${baseName}` }, getPageName(baseName, 'list'), args.title)
	const editPage = new PageConfig({ path: `/${baseName}/:id` }, getPageName(baseName, 'edit'), args.title)
	const createPage = new PageConfig({ path: `/${baseName}/new` }, getPageName(baseName, 'create'), args.title)
	const pages = {
		list: listPage,
		create: createPage,
		edit: editPage,
	}

	const CreatePageInstance =
		args.enableAdding !== false
			? (args.createPageFactory || createPageFactory)({
					...args,
					pageName: createPage.name,
					title: createPage.title,
					pages,
			  })
			: undefined
	const EditPageInstance = (args.editPageFactory || editPageFactory)({
		...args,
		pageName: editPage.name,
		title: editPage.title,
		pages,
	})
	const ListPageInstance = (args.listPageFactory || ('grid' in args ? gridPageFactory : listPageFactory))({
		...args,
		pageName: listPage.name,
		title: listPage.title,
		pages,
	})

	const routes: ProjectConfig['routes'] = {}
	for (const page of Object.values(pages)) {
		routes[page.name] = page.route
	}

	return new PageSet(baseName, routes, [CreatePageInstance, EditPageInstance, ListPageInstance].filter(isDefined))
}

import { createCrudPages } from '../../utils'
import { Box, Field, TableCell, TextField } from '@contember/admin'
import * as React from 'react'
import { ProductRuleForm } from '../../components'

export const RulePages = createCrudPages({
	entityName: 'ProductRule',
	title: 'Produktová seskupení',
	listPageProps: {
		entities: {
			entityName: 'ProductRule',
			filter: { isInline: { eq: false } },
		},
	},
	table: (
		<TableCell>
			<Field field="internalName" />
		</TableCell>
	),
	form: (
		<>
			<Box>
				<TextField field="internalName" label="Název" />
			</Box>
			<ProductRuleForm />
		</>
	),
})

import {
	AltPersistButton,
	CheckboxField,
	Component,
	DataBindingProvider,
	EntityAccessor,
	EntitySubTree,
	FeedbackRenderer,
	Field,
	HasMany,
	HiddenField,
	MutableSingleEntityRenderer,
	NavigateBackButton,
	Repeater,
	SelectField,
	useField,
	useRedirect,
} from '@contember/admin'
import * as React from 'react'
import { useCallback } from 'react'
import { FillMapping } from './ProductImportMappingFiller'
import { ProductImportMappingTarget } from '../../../../api/model'
import { DependentCollapsible } from '../Generic/DependentCollapsible'

const FieldInfo: React.FC = ({}) => {
	// const field = useField('fieldName').value as string
	const label = useField<string>('label').value!
	// const header = sheet.header.find((it) => it.key === field)!
	// const values = sheet.data.map((it) => it.get(field)).filter((it) => it !== undefined)
	// const unique = new Set(values)
	// const valuesStr = Array.from(unique.values()).join(', ')
	const valuesStr = ''
	return (
		<div title={valuesStr}>
			<strong>{label}</strong>
			{/*({unique.size} values on {values.length} rows)*/}
		</div>
	)
}

type SheetMappingProps = { viewPageName: string; listPageName: string }
const validateMapping: EntityAccessor.BeforePersistHandler = (getAccessor) => {
	const entity = getAccessor()
	const fields = Array.from(entity.getEntityList('fields'))
	const required = ['productBaseCode']
	const singleRequired = new Set(['colorCode', 'sizeCode', 'productBaseCode'])
	for (const field of fields) {
		const target = field.getField<string>('target')
		const value = target.value as string
		if (!required.includes(value)) {
			continue
		}
		if (!singleRequired.has(value)) {
			target.addError({ message: 'Pouze jeden slupeček tohoto typu je povolen' })
		}
		singleRequired.delete(value)
	}
	if (singleRequired.size === required.length) {
		const firstTarget = fields[0].getField('target')
		firstTarget.addError({ message: 'Vyberte sloupeček s kódem' })
	}
}
const targetItems: Record<ProductImportMappingTarget, string> = {
	productBaseCode: 'Kód matky',
	colorCode: 'Kód barvy',
	sizeCode: 'Kód velikost',
	sku: 'Kód SKU',
	name: 'Název produktu',
	shortDescription: 'Krátký popis produktu',
	longName: 'Dlouhý název produktu',
	description: 'Popis produktu',
	attribute: 'Atribut',
	brand: 'Značka',
	technology: 'Technologie',
	highlight: 'Vyzdvihnuté vlastnosti',
	geometry: 'Geometrie',
	omit: 'Zahodit',
}
const FieldsRepeater = Component(() => (
	<Repeater
		field={'fields'}
		orderBy={undefined}
		label={undefined}
		enableAddingNew={false}
		enableRemoving={false}
		initialEntityCount={0}
	>
		<HiddenField field={'fieldName'} label={undefined} isNonbearing={false} />
		<HiddenField field={'label'} label={undefined} isNonbearing={false} />
		<FieldInfo />
		<div className={'horizontal-fields'}>
			<SelectField
				label={'Cíl'}
				options={Object.entries(targetItems).map(([value, label]) => ({ value, label }))}
				field={'target'}
			/>
			<DependentCollapsible
				field={'target'}
				condition={(it) =>
					['attribute', 'name', 'shortDescription', 'longName', 'description'].includes(it as string)
				}
			>
				<SelectField label={'Jazyk'} allowNull={true} field={'locale'} options={'Locale.code'} />
			</DependentCollapsible>
			<DependentCollapsible field={'target'} condition={(it) => it === 'attribute'}>
				<SelectField
					options={{
						entities: 'ProductAttribute',
					}}
					label={'Vyberte'}
					field={'targetAttribute'}
					renderOption={(accessor: EntityAccessor) => {
						const locales = accessor.getEntityList('locales')
						return Array.from(locales, (locale) => locale.getField('name').value).join(' / ')
					}}
					optionsStaticRender={
						<>
							<Field field={'code'} />
							<HasMany field={'locales'}>
								<Field field={'name'} />
								<Field field={'mappingFieldName'} />
								<Field field={'locale.id'} />
								<Field field={'locale.code'} />
							</HasMany>
						</>
					}
				/>
			</DependentCollapsible>
			<FillMapping />
		</div>
	</Repeater>
))

export const ProductImportMappingForm: React.FC<SheetMappingProps> = ({ viewPageName, listPageName }) => {
	const redirect = useRedirect()
	const onPersist: EntityAccessor.PersistSuccessHandler = useCallback(
		async (getAccessor, options) => {
			if (options.successType !== 'justSuccess') {
				return
			}
			const entity = getAccessor()
			redirect((req) => ({ ...req, pageName: viewPageName, parameters: { id: entity.idOnServer } }))
		},
		[redirect, viewPageName],
	)

	return (
		<DataBindingProvider stateComponent={FeedbackRenderer}>
			<EntitySubTree
				entity={{ entityName: 'ProductImportBatch', where: '(id = $id)' }}
				entityComponent={MutableSingleEntityRenderer}
				onBeforePersist={validateMapping}
				onPersistSuccess={onPersist}
				entityProps={
					{
						title: <>Import</>,
						navigation: <NavigateBackButton to={listPageName}>Výpis</NavigateBackButton>,
						persistButtonComponent: AltPersistButton,
					} as any
				}
			>
				<CheckboxField field={'noTextOverwrite'} label={'Nepřepisovat textové položky (název produktu atd)'} />
				<HiddenField field={'mappedAt'} label={undefined} defaultValue={'now'} isNonbearing={false} />
				<FieldsRepeater />
			</EntitySubTree>
		</DataBindingProvider>
	)
}

import { SingleLineTextInputProps, TextInput } from '@contember/admin'
import { FocusEvent as ReactFocusEvent, forwardRef, memo, Ref, useMemo } from 'react'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'

export type DateFieldProps = Omit<SingleLineTextInputProps, 'validationState' | 'value' | 'onChange'> & {
	dateFormat?: ReactDatePickerProps['dateFormat']
	showTimeSelect?: ReactDatePickerProps['showTimeSelect']
	onChange: ReactDatePickerProps['onChange']
	value?: ReactDatePickerProps['selected']
}

export const StandaloneDateField = memo(
	forwardRef((props: DateFieldProps, suppliedRef: Ref<any>) => {
		const { onFocus: outerOnFocus, onBlur: outerOnBlur } = props
		const UnderlyingTextInput = useMemo(
			() =>
				forwardRef<any, any>((innerProps, ref) => {
					const { className, onFocus, onBlur, ...legalProps } = innerProps
					return (
						<TextInput
							{...legalProps}
							ref={suppliedRef}
							onFocus={(e: ReactFocusEvent<HTMLInputElement>) => {
								outerOnFocus && outerOnFocus(e)
								innerProps.onFocus && innerProps.onFocus(e)
							}}
							onBlur={(e: ReactFocusEvent<HTMLInputElement>) => {
								outerOnBlur && outerOnBlur(e)
								innerProps.onBlur && innerProps.onBlur(e)
							}}
						/>
					)
				}),
			[outerOnBlur, outerOnFocus, suppliedRef],
		)
		return (
			<DatePicker
				selected={props.value}
				onChange={props.onChange}
				isClearable={true}
				customInput={<UnderlyingTextInput />}
				dateFormat={props.dateFormat}
				showTimeSelect={props.showTimeSelect}
			/>
		)
	}),
)

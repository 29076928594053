import React, { useState } from 'react'
import { ImageDropzone } from './ImageDropzone'
import { ImageUpload } from './ImageUpload'

export const ImageImport = () => {
	const [images, setImages] = useState<File[]>()
	if (!images) {
		return <ImageDropzone onUpload={setImages} />
	}
	return <ImageUpload files={images} />
}

import { Box, SelectField, TextCell, TextField } from '@contember/admin'
import * as React from 'react'
import { createCrudPages } from '../../utils'
import { CoalesceTextCell, DependentCollapsible, LinkableField } from '../../components'

export const RedirectPages = createCrudPages({
	title: 'Přesměrování',
	entityName: 'Redirect',
	form: (
		<Box>
			<SelectField field={'link.channel'} label={'Kanál'} options={'Channel.internalName'} />
			<TextField label="URL" field="link.url" />
			<DependentCollapsible field={'externalTarget'} condition={(it) => !it}>
				<LinkableField field={'target'} label={'Cíl'} />
			</DependentCollapsible>
			<DependentCollapsible field={'target.id'} condition={(it) => !it}>
				<TextField field={'externalTarget'} label={'Externí cíl'} />
			</DependentCollapsible>
		</Box>
	),
	gridPageProps: {
		enableRemoving: true,
	},
	grid: (
		<>
			<TextCell field="link.channel.internalName" header="Kanál" />
			<TextCell field={'link.url'} header={'Zdroj'} />
			<CoalesceTextCell fields={['target.url', 'externalTarget']} header={'Cíl'} />
		</>
	),
})

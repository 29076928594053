import {
	Component,
	EntityAccessor,
	Environment,
	Field,
	HasMany,
	MultiSelectField,
	SelectField,
	SugarableQualifiedEntityList,
	SugarableRelativeEntityList,
	SugaredRelativeSingleField,
} from '@contember/admin'
import * as React from 'react'
import { ReactNode } from 'react'

export interface LocaleSelectFieldProps {
	field: string
	entities: string | SugarableQualifiedEntityList
	localeCollectionField?: string
	localizedField: string
	label: React.ReactNode
	allowNull?: boolean
	optionsStaticRender?: React.ReactElement | ((environment: Environment) => React.ReactElement)
	readOnly?: boolean
	searchByFields?: SugaredRelativeSingleField['field'] | SugaredRelativeSingleField['field'][]
	renderOption?: (entityAccessor: EntityAccessor, children: string) => ReactNode
}

const createEntityList = (field: string, locales: string[]): SugarableRelativeEntityList => ({
	hasManyRelation: {
		field: field,
		filter: {
			locale: {
				code: {
					in: locales,
				},
			},
		},
	},
})

const createStaticRender = (
	localeField: string,
	localizedField: string,
	additionalStatic?: React.ReactElement | ((environment: Environment) => React.ReactElement),
) => {
	return (env: Environment) => {
		const additional =
			additionalStatic && typeof additionalStatic === 'function' ? additionalStatic(env) : additionalStatic
		return (
			<>
				<HasMany field={createEntityList(localeField, env.getDimension('locale'))}>
					<Field field={localizedField} />
				</HasMany>
				{additional}
			</>
		)
	}
}

export const LocaleMultiSelectField = Component<LocaleSelectFieldProps>((props) => {
	const localeField = props.localeCollectionField || 'locales'
	return (
		<MultiSelectField
			{...props}
			options={{
				entities: props.entities,
			}}
			renderOption={createLocaleRenderer(localeField, props.localizedField, props.renderOption)}
			optionsStaticRender={createStaticRender(localeField, props.localizedField, props.optionsStaticRender)}
			reactSelectProps={{
				isDisabled: props.readOnly,
			}}
		/>
	)
})

export const LocaleSelectField = Component<LocaleSelectFieldProps>((props) => {
	const localeField = props.localeCollectionField || 'locales'
	return (
		<SelectField
			{...props}
			options={{
				entities: props.entities,
			}}
			renderOption={createLocaleRenderer(localeField, props.localizedField, props.renderOption)}
			optionsStaticRender={createStaticRender(localeField, props.localizedField, props.optionsStaticRender)}
			reactSelectProps={{
				isDisabled: props.readOnly,
			}}
		/>
	)
})

export const createLocaleRenderer = (
	localeCollection: string,
	field: string,
	renderOption: LocaleSelectFieldProps['renderOption'],
) => {
	const localeRenderer = (accessor: EntityAccessor) => {
		const locales = accessor.getEntityList({
			field: createEntityList(localeCollection, accessor.environment.getDimension('locale')),
		})
		return Array.from(locales, (locale) => locale.getField(field).value).join(' / ')
	}
	if (!renderOption) {
		return localeRenderer
	}
	return (accessor: EntityAccessor) => renderOption(accessor, localeRenderer(accessor))
}

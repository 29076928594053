import { Box, CheckboxField, Field, SelectField, TableCell, TextField } from '@contember/admin'
import * as React from 'react'
import { LocaleSideDimension } from '../../components'
import { createCrudPages } from '../../utils'

export const TagPages = createCrudPages({
	entityName: 'ProductTag',
	title: 'Tagy',
	form: (
		<>
			<Box>
				<TextField field="internalName" label="Interní název" />
				<CheckboxField field={'showInListing'} label={'Zobrazit ve výpise'} />
				<CheckboxField field={'showInDetail'} label={'Zobrazit v detailu'} />
				<SelectField
					label={'Barva'}
					options={[
						{ value: 'black', label: 'Černá' },
						{ value: 'green', label: 'Zelená' },
					]}
					field={'color'}
				/>
			</Box>
			<Box heading={'Překlady'}>
				<LocaleSideDimension>
					<TextField field="name" label="Název" />
				</LocaleSideDimension>
			</Box>
		</>
	),
	table: (
		<TableCell>
			<Field field="internalName" />
		</TableCell>
	),
})

import { Box, TextCell } from '@contember/admin'
import * as React from 'react'
import { createCrudPages } from '../../utils'
import { ContentEditor } from '../../components'

export const EmbeddableContentPages = createCrudPages({
	entityName: 'EmbeddableContent',
	title: 'Vložitelné bloky',
	form: (
		<>
			<Box>
				<ContentEditor
					leadingElements={[
						{
							field: 'name',
							placeholder: 'Interní název',
							format: 'plainText',
							render: (props) => <h1 style={{ fontSize: '2.5em', fontWeight: 600 }}>{props.children}</h1>,
						},
					]}
					disableEmbeddedContent={true}
				/>
			</Box>
		</>
	),

	grid: (
		<>
			<TextCell field="name" header="Interní název" />
		</>
	),
	gridPageProps: {
		enableRemoving: true,
	},
})

import { Component, Field, Filter, useEntity } from '@contember/admin'
import { useMemo } from 'react'
import { ProductListField } from '../Product'
import * as React from 'react'

export const CategoryTopProducts = Component(
	() => {
		const firstLevel = useEntity('parent')
		const secondLevel = useEntity('parent.parent')
		const isSecondLevel = firstLevel.existsOnServer && !secondLevel.existsOnServer
		const id = useEntity().idOnServer
		const filter = useMemo((): Filter | undefined => createFilter(id), [id])
		if (!id || !isSecondLevel) {
			return null
		}
		return <ProductListField field={'topProducts'} label={'Topované produkty'} productFilter={filter} />
	},
	(props, env) => {
		const filter = createFilter(env.getValue('id'))
		return (
			<>
				<ProductListField field={'topProducts'} productFilter={filter} />
				<Field field={'parent.id'} />
				<Field field={'parent.parent.id'} />
			</>
		)
	},
)

const createFilter = (id: string | undefined): Filter | undefined => {
	if (!id) {
		return undefined
	}
	const or = []
	let filter: Filter = { id: { eq: id } }
	for (let i = 0; i < 5; i++) {
		or.push({ base: { categories: filter } })
		filter = { parent: filter }
	}
	return { or }
}

import * as React from 'react'
import {
	CreateNewEntityButton,
	EmptyMessage,
	ErrorList,
	Heading,
	RepeaterContainerProps,
	Table,
	TableCell,
	TableRow,
} from '@contember/admin'
import { DeleteEntityButton } from '@contember/admin'
import { RepeaterItemProps } from '@contember/admin'
export const TableRepeaterItem = React.memo(({ children, canBeRemoved }: RepeaterItemProps) => (
	<TableRow>
		{children}
		{canBeRemoved ? (
			<TableCell justification={'justifyEnd'}>
				<DeleteEntityButton />
			</TableCell>
		) : undefined}
	</TableRow>
))
export const TableRepeaterContainer = React.memo(
	({
		accessor,
		children,
		createNewEntity,
		addButtonComponent: AddButton = CreateNewEntityButton,
		addButtonComponentExtraProps,
		addButtonProps,
		addButtonText = 'Přidat',
		emptyMessage = 'Nejsou dostupné žádné záznamy.',
		emptyMessageComponent: EmptyMessageComponent = EmptyMessage,
		emptyMessageComponentExtraProps,
		enableAddingNew = true,
		isEmpty,
		label,
	}: RepeaterContainerProps) => {
		return (
			<div className={'table-repeater'}>
				{enableAddingNew && (
					<div className={'table-repeater-add'}>
						<AddButton
							{...addButtonComponentExtraProps}
							children={addButtonText}
							{...addButtonProps}
							createNewEntity={createNewEntity}
						/>
					</div>
				)}
				<Heading size="small">{label}</Heading>
				<ErrorList errors={accessor.errors} />
				{isEmpty && (
					<EmptyMessageComponent {...emptyMessageComponentExtraProps}> {emptyMessage} </EmptyMessageComponent>
				)}
				{isEmpty || <Table>{children}</Table>}
			</div>
		)
	},
)
TableRepeaterContainer.displayName = 'TableRepeaterContainer'

import { SyntheticEvent, useCallback, useState } from 'react'

interface FormArgs<V> {
	initialValues?: Partial<V>
}

interface FormMethods<V> {
	values: Partial<V>
	getValue: (field: keyof V) => any
	onChange: (field: keyof V, options?: { type?: 'number' }) => (args: SyntheticEvent | any) => void
}

export const useForm = <V>(args: FormArgs<V> = {}): FormMethods<V> => {
	const [values, setValues] = useState<Partial<V>>(args.initialValues || {})
	return {
		values,
		getValue: useCallback((field: keyof V) => values[field] || null, [values]),
		onChange: useCallback((field: keyof V, options: { type?: 'number' } = {}) => {
			return (arg) => {
				let value =
					typeof arg === 'object' && arg !== null && 'currentTarget' in arg ? arg.currentTarget.value : arg
				if (options.type === 'number') {
					value = Number(value).toString(10) === value ? Number(value) : null
				}
				setValues((values) => ({ ...values, [field]: value }))
			}
		}, []),
	}
}

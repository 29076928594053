import { Component, NumberField, Repeater, SelectField, TableCell, TextField } from '@contember/admin'
import { TableRepeaterContainer, TableRepeaterItem } from '../Generic'
import * as React from 'react'

export const ProductSkusRepeater = Component((props) => (
	<Repeater
		field={'skus'}
		label={'SKUs'}
		initialEntityCount={0}
		orderBy={undefined}
		enableAddingNew={__DEV_MODE__}
		enableRemoving={__DEV_MODE__}
		containerComponent={TableRepeaterContainer}
		itemComponent={TableRepeaterItem}
		containerComponentExtraProps={{
			addButtonText: 'Přidat SKU',
		}}
	>
		<TableCell>
			<TextField field={'ean'} label={'EAN'} />
		</TableCell>
		<TableCell>
			<TextField field={'sku'} label={'SKU'} />
		</TableCell>
		<TableCell>
			<SelectField label={'Obchod'} options={'Store.internalName'} field={'store'} />
		</TableCell>
		<TableCell>
			<NumberField
				field={'quantity'}
				label={'Dostupné množství'}
				onWheel={(e) => {
					e.currentTarget.blur()
				}}
			/>
		</TableCell>
	</Repeater>
))

import { Spreadsheet } from '../../utils'
import * as React from 'react'
import { Table, TableCell, TableHeaderCell, TableRow } from '@contember/admin'

export interface SheetTableProps {
	sheet: Spreadsheet
	limit?: number
}

export const SheetTable: React.FC<SheetTableProps> = ({ sheet, limit }) => {
	const data = limit ? sheet.data.slice(0, limit) : sheet.data
	return (
		<Table
			tableHead={
				<TableRow>
					{sheet.header.map((it) => (
						<TableHeaderCell key={it.key}>{it.label}</TableHeaderCell>
					))}
				</TableRow>
			}
		>
			{data.slice(0, 5).map((row, index) => (
				<TableRow key={index}>
					{sheet.header.map((header) => (
						<TableCell key={header.key}>{row.get(header.key)}</TableCell>
					))}
				</TableRow>
			))}
		</Table>
	)
}

import { Component, EntityAccessor, Field, Filter, FormGroupProps, HasOne, Repeater } from '@contember/admin'
import * as React from 'react'
import { LocaleMultiSelectField, LocaleSelectField } from '../Site'

export interface CategorySelectFieldProps {
	field: string
	label: React.ReactNode
	allowNull?: boolean
	withoutTree?: string
	optionsStaticRender?: React.ReactElement
}

const categoryRenderer = (accessor: EntityAccessor, children: string) =>
	`${accessor.getField('code').value}: ${children}`

const categorySelectProps = {
	localizedField: 'name',
	optionsStaticRender: (
		<>
			<Field field={'code'} />
			<Field field={'parent.id'} />
		</>
	),
	renderOption: categoryRenderer,
}

const createFilter = (withoutId: string | undefined): Filter | undefined => {
	if (!withoutId) {
		return undefined
	}
	const and = []
	let filter: Filter = { id: { or: [{ notEq: withoutId }, { isNull: true }] } }
	for (let i = 0; i < 5; i++) {
		and.push(filter)
		filter = { parent: filter }
	}
	return { and }
}

export const CategoryMultiSelectField = Component<CategorySelectFieldProps>((props) => {
	return (
		<LocaleMultiSelectField
			entities={{
				entityName: 'ProductCategory',
				filter: createFilter(props.withoutTree),
			}}
			{...props}
			{...categorySelectProps}
			optionsStaticRender={
				<>
					{props.optionsStaticRender}
					{categorySelectProps.optionsStaticRender}
				</>
			}
		/>
	)
})
export const CategorySelectField = Component<CategorySelectFieldProps>((props) => {
	return (
		<LocaleSelectField
			entities={{
				entityName: 'ProductCategory',
				filter: createFilter(props.withoutTree),
			}}
			{...props}
			{...categorySelectProps}
			optionsStaticRender={
				<>
					{props.optionsStaticRender}
					{categorySelectProps.optionsStaticRender}
				</>
			}
		/>
	)
})

export interface ProductCategoryListFieldProps {
	field: string
	label?: FormGroupProps['label']
}

export const ProductCategoryListField = Component<ProductCategoryListFieldProps>((props) => (
	<HasOne field={props.field}>
		<Repeater field="items" sortableBy="order" label={props.label}>
			<CategorySelectField field={'category'} label={'Kategorie'} />
		</Repeater>
	</HasOne>
))

import {
	AltPersistButton,
	Block,
	Box,
	CheckboxField,
	DiscriminatedBlocks,
	EditPage,
	Field,
	GenericCell,
	NavigateBackButton,
	PageLinkButton,
	Repeater,
	SelectField,
	SlugField,
	TextCell,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { DependentCollapsible, EditButton, LocaleSideDimension, ProductAttributeMappings } from '../../components'
import { RelativeEntityProvider } from '../../components/Generic/RelativeEntity'
import { createCrudPages, Page } from '../../utils'
import { AttributeOptionPages } from './AttributeOptionPages'

const attributeSortPageName = 'attributeSort'

export const AttributePages = createCrudPages({
	entityName: 'ProductAttribute',
	title: 'Attributy',
	listPageProps: {
		rendererProps: {
			actions: <PageLinkButton to={AttributeOptionPages.link('list')}>Hodnoty atributů</PageLinkButton>,
		},
	},
	sideForm: (
		<Box>
			<SlugField derivedFrom={"locales(locale.code='cs').name"} field="code" label="Kód atributu" />
			<CheckboxField field={'hideInDetail'} label={'Skrýt v detailu produktu'} />
			<SelectField
				label={'Řazení'}
				options={[
					{ value: 'natural', label: 'Přirozené' },
					{ value: 'manual', label: 'Ruční' },
				]}
				field={'optionsOrderStrategy'}
				allowNull
			/>
			<DependentCollapsible field={'optionsOrderStrategy'} condition={(val) => val === 'manual'}>
				<EditButton pageName={attributeSortPageName}>Seřadit</EditButton>
			</DependentCollapsible>
		</Box>
	),
	form: (
		<>
			<Box heading={'Překlady'}>
				<LocaleSideDimension>
					<TextField field="name" label="Název" />
					<TextField field="mappingFieldName" label="Název sloupečku v XLS" />
				</LocaleSideDimension>
			</Box>
			<Box heading={'Nastavení hodnot'}>
				<RelativeEntityProvider>
					<DiscriminatedBlocks field="type" label="Druh">
						<Block discriminateBy="options" label="Výber z hodnot">
							<Repeater field={'options'} label={'Hodnoty'} orderBy={'order'}>
								<LocaleSideDimension>
									<TextField field="name" label="Název" />
									<TextField field="nameNeuter" label="Název rod střední" />
								</LocaleSideDimension>
								<ProductAttributeMappings />
							</Repeater>
						</Block>
						<Block discriminateBy="numeric" label="Číselná hodnota"></Block>
					</DiscriminatedBlocks>
				</RelativeEntityProvider>
			</Box>
		</>
	),
	grid: (
		<>
			<TextCell field="code" header="Kód" initialOrder="asc" />
			<GenericCell header="Název">
				<LocaleSideDimension>
					<Field field="name" />
				</LocaleSideDimension>
			</GenericCell>
		</>
	),
})

export const AttributeSortPage = new Page(
	{ path: '/attributes/sort/:id' },
	(
		<EditPage
			entity={{ entityName: 'ProductAttribute', where: '(id = $id)' }}
			pageName={attributeSortPageName}
			rendererProps={{
				title: 'Řazení atributů',
				navigation: <NavigateBackButton to={AttributePages.link('list')}>Výpis</NavigateBackButton>,
				persistButtonComponent: AltPersistButton,
			}}
		>
			<Repeater field={'options'} label={'Hodnoty'} sortableBy={'order'}>
				<LocaleSideDimension>
					<Field field="name" />
				</LocaleSideDimension>
			</Repeater>
		</EditPage>
	),
)

import * as React from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { Button, FileDropZone, FormGroup } from '@contember/admin'

interface ImageDropzoneProps {
	onUpload: (images: File[]) => void
}

export const ImageDropzone: React.FC<ImageDropzoneProps> = ({ onUpload }) => {
	const onDrop = React.useCallback(
		async (files: File[], rejected: FileRejection[]) => {
			if (rejected.length > 0) {
				const rejectedFiles = rejected.map((it) => it.file.name).join('\n')
				alert('Přeskakuji nepodporované soubory:\n' + rejectedFiles)
			}
			if (files.length > 0) {
				onUpload(files)
			}
		},
		[onUpload],
	)

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		disabled: false,
		accept: ['image/jpeg', 'image/jpg', 'image/png'],
		multiple: true,
		noKeyboard: true,
	})

	return (
		<FormGroup label={'Import obrázků'} useLabelElement={false}>
			<div
				{...getRootProps({
					style: {},
				})}
			>
				<input {...getInputProps()} />
				<div className="fileInput">
					<FileDropZone isActive={isDragActive} className="fileInput-dropZone">
						<div className="fileInput-cta">
							<Button size="small">Vyberte obrázky k importu</Button>
							<span className="fileInput-cta-label">nebo je přetáhněte</span>
						</div>
					</FileDropZone>
				</div>
			</div>
		</FormGroup>
	)
}

import { Box, Message, Spinner } from '@contember/admin'
import { ReactNode } from 'react'

export const LoadingMessage = (props: { children: ReactNode }) => (
	<Box>
		<Message>
			<span style={{ display: 'inline-block', marginRight: '0.5em', fontSize: '1.3ex' }}>
				<Spinner />
			</span>
			{props.children}
		</Message>
	</Box>
)

export const getImagePreview = (url: string) => {
	return new Promise<HTMLImageElement>((resolve, reject) => {
		const image = new Image()
		image.addEventListener('load', () => {
			resolve(image)
		})
		image.addEventListener('error', (e) => {
			reject(e.message)
		})
		image.src = url
	})
}

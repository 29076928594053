import { Box, TextAreaField, TextCell, TextField } from '@contember/admin'
import * as React from 'react'
import { LocaleSelectField, LocaleSideDimension } from '../../components'
import { IconSelectField } from '../../components/Content/IconSelectField'
import { createCrudPages } from '../../utils'

export const HighlightPages = createCrudPages({
	entityName: 'ProductHighlight',
	title: 'Vyzdvihnuté vlastnosti',
	sideForm: (
		<Box>
			<TextField field="code" label="Kód" />
			<LocaleSelectField
				label={'Atribut'}
				entities={'ProductAttribute'}
				field={'attribute'}
				localizedField={'name'}
			/>
		</Box>
	),
	form: (
		<>
			<Box>
				<IconSelectField label="Ikonka" field="icon" />
				<LocaleSideDimension>
					<TextField
						field={'title'}
						label={'Titulek'}
						description={
							<>
								Pro vložení hodnoty atributu použijte placeholder{' '}
								<span style={{ fontStyle: 'italic' }}>%value%</span>.
								<br />
								Pro vložení názvu značky použijte placeholder{' '}
								<span style={{ fontStyle: 'italic' }}>%brand%</span>.
							</>
						}
					/>

					<TextAreaField field={'description'} label={'Popis'} />
				</LocaleSideDimension>
			</Box>
		</>
	),
	grid: <TextCell field="code" header="Kód" initialOrder="asc" />,
})

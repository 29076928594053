import { Component, Field, NumberField, Repeater, useEntity } from '@contember/admin'
import { LocaleSelectField } from '../Site/LocaleSelectField'
import * as React from 'react'
import { CollapsibleRepeaterContainer, DependentCollapsible } from '../Generic'
import { ProductAttributeOptionSelectField } from './ProductAttributeOptionSelectField'

export interface AttributesRepeaterProps {
	label: string | undefined
	field: string
}
const AttributeSelectFieldInner = Component<{ readOnly: boolean }>(({ readOnly }) => (
	<LocaleSelectField
		label={'Atribut'}
		entities={'ProductAttribute'}
		field={'attribute'}
		localizedField={'name'}
		readOnly={readOnly}
		optionsStaticRender={
			<>
				<Field field={'type'} />
			</>
		}
	/>
))

const AttributeSelectField = Component(
	(props) => {
		const attribute = useEntity('attribute')
		return <AttributeSelectFieldInner readOnly={attribute.idOnServer !== undefined} />
	},
	() => <AttributeSelectFieldInner readOnly={false} />,
)

export const ProductAttributesRepeater = Component<AttributesRepeaterProps>(({ field, label }) => (
	<Repeater field={field} orderBy={'id'} label={label} containerComponent={CollapsibleRepeaterContainer}>
		<div className={'horizontal-fields'}>
			<AttributeSelectField />
			<DependentCollapsible
				field={'attribute.type'}
				condition={(type) => type === 'options'}
				withoutWrapper={true}
			>
				<ProductAttributeOptionSelectField />
			</DependentCollapsible>
			<DependentCollapsible
				field={'attribute.type'}
				condition={(type) => type === 'numeric'}
				withoutWrapper={true}
			>
				<NumberField
					field="numericValue"
					label={undefined}
					defaultValue={null}
					onWheel={(e) => {
						e.currentTarget.blur()
					}}
				/>
			</DependentCollapsible>
		</div>
	</Repeater>
))

import { Box, BoxProps, Button, Collapsible, Component, Icon } from '@contember/admin'
import * as React from 'react'
import { CollapsibleContext, useIsCollapsed } from './CollapsibleContext'

export interface CollapsibleBoxProps extends Pick<BoxProps, 'heading' | 'distinction' | 'children'> {}

export const CollapsibleBox = Component<CollapsibleBoxProps>(
	({ heading, distinction, children }) => {
		const [isExpanded, setIsExpanded] = React.useState(false)
		const isCollapsed = useIsCollapsed()
		const toggle = React.useCallback(() => {
			setIsExpanded((val) => !val)
		}, [])

		return (
			<CollapsibleContext.Provider value={isCollapsed || !isExpanded}>
				<Box
					heading={heading}
					distinction={distinction}
					actions={
						<Button size="small" onClick={toggle}>
							{isExpanded ? 'collapse' : 'expand'}{' '}
							<Icon blueprintIcon={isExpanded ? 'chevron-up' : 'chevron-down'} />
						</Button>
					}
				>
					<Collapsible expanded={isExpanded}>{children}</Collapsible>
				</Box>
			</CollapsibleContext.Provider>
		)
	},
	({ children }) => <>{children}</>,
)

import * as React from 'react'
import { Component, Field, SelectField, useEnvironment } from '@contember/admin'

interface IconSelectField {
	label: React.ReactNode
	field: string
}

let icons: Promise<any>

async function loadIcons(basePath: string) {
	if (icons === undefined) {
		icons = fetch(`${basePath}assets/images/icons.svg`).then(
			async (res) => {
				const text = await res.text()
				const div = document.createElement('div')
				div.style.display = 'none'
				div.innerHTML = text
				document.body.insertAdjacentElement('afterbegin', div)
				return null
			},
			() => {},
		)
	}
	await icons
}

const IconSelectFieldInner = Component<IconSelectField>(({ label, field }) => {
	return (
		<SelectField
			label={label}
			options={'Icon'}
			field={field}
			allowNull={true}
			searchByFields="internalName"
			renderOption={(accessor) => (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<svg style={{ width: '24px', height: '24px', marginRight: '.5em' }}>
						<use href={`#${accessor.getField('code').value}`}></use>
					</svg>
					{accessor.getField('internalName').value}
				</div>
			)}
			optionsStaticRender={
				<>
					<Field field="code" />
					<Field field="internalName" />
				</>
			}
		/>
	)
}, 'IconSelectFieldInner')

export const IconSelectField = Component<IconSelectField>(
	(props) => {
		const baseUrl = useEnvironment().getValue<string>('CZ_FRONTEND_BASEPATH')
		React.useEffect(() => {
			loadIcons(baseUrl)
		}, [baseUrl])

		return <IconSelectFieldInner {...props} />
	},
	(props) => {
		return <IconSelectFieldInner {...props} />
	},
	'IconSelectField',
)

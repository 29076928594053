import { Box, TextCell, TextField, SelectField, MultiSelectField, Repeater, CheckboxField } from '@contember/admin'
import * as React from 'react'
import { createCrudPages } from '../../utils'
import { CategoryMultiSelectField } from '../../components'

const options = [
	{
		value: 'NonGuaranteeService',
		label: 'Negaranční',
	},
	{
		value: 'GuaranteeService',
		label: 'Garanční',
	},
]

export const ServiceboookServiceTypePages = createCrudPages({
	entityName: 'ServicebookServiceType',
	title: 'Typ servisu',
	sideForm: <></>,
	form: (
		<>
			<Box heading={undefined}>
				<TextField field="internalName" label="Interní název" />
				<Repeater
					field={'locales'}
					label={'Veřejné názvy'}
					orderBy={'name'}
					initialEntityCount={0}
					enableAddingNew={true}
					enableRemoving={true}
				>
					<SelectField label={'Jazyková verze'} options={'Locale.code'} field={'locale'} />
					<TextField field="name" label="Název" />
				</Repeater>
				<SelectField field={'guaranteeType'} label={'Záruka'} options={options} />
				<Box heading={'Kategorie'}>
					<CategoryMultiSelectField field={'categories'} label={'Kategorie'} />
					<CheckboxField
						field={'externalServiceItems'}
						label={'Povolit prodej pro předměty servisu nespárované s variantem produktu'}
					/>
				</Box>
				<Box heading={'Prodejny'}>
					<MultiSelectField label={'Prodejny'} options={'Store.internalName'} field={'stores'} />
				</Box>
			</Box>
		</>
	),
	grid: (
		<>
			<TextCell header="Interní název" field="internalName" initialOrder="asc" />
		</>
	),
})

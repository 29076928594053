import {
	Block,
	BooleanCell,
	Box,
	CheckboxField,
	DataBindingProvider,
	DataGrid,
	DiscriminatedBlocks,
	FeedbackRenderer,
	Field,
	GenericCell,
	GenericPage,
	HasMany,
	MultiSelectField,
	PageLinkButton,
	Repeater,
	RichTextField,
	SelectField,
	TextCell,
	TextField,
	TitleBar,
	useEntityPersistSuccess,
} from '@contember/admin'
import * as React from 'react'
import {
	CategoryMultiSelectField,
	CollapsibleBox,
	CollapsibleRepeaterContainer,
	ContentEditor,
	DependentCollapsible,
	EditButton,
	EnumCell,
	ImageListField,
	ImagePreview,
	LinkField,
	LocaleSideDimension,
	ProductAttributeCell,
	ProductAttributesRepeater,
	ProductCategoriesCell,
	ProductComplementSettings,
	ProductLinksRepeater,
	ProductListField,
	ProductMissingInfoCell,
	ProductPricesRepeater,
	ProductSkusRepeater,
	ProductDiscountsRepeater,
	ProductStateInfo,
	SeoForm,
	VideoUploadWithDescriptionField,
} from '../../components'
import { createCrudPages, localizedCell, Page } from '../../utils'

const PersistListener = () => {
	useEntityPersistSuccess(async (accessor) => {
		const entity = accessor()
		const baseUrl = entity.environment.getValue('CZ_FRONTEND_BASEPATH')
		const id = entity.idOnServer
		await fetch(`${baseUrl}api/reindex/enqueue-product-base/${id}`, {
			method: 'POST',
			mode: 'no-cors',
		})
	})
	return null
}
export const ProductPages = createCrudPages({
	entityName: 'ProductBase',
	title: 'Produkty',
	sideForm: (
		<>
			<PersistListener />
			<Box>
				<TextField field="code" label="Kód" readOnly={!__DEV_MODE__} />
				<SelectField
					label="Sazba DPH"
					options="VatRate.internalName"
					field="vatRate"
					reactSelectProps={{
						isDisabled: !__DEV_MODE__,
					}}
				/>
				<CheckboxField field={'discontinued'} label={'Vyřazeno'} />
				<CheckboxField field={'notDiscountable'} label={'Není možné uplatnit slevy'} />
				<DiscriminatedBlocks field="kind" label="Druh">
					<Block discriminateBy="genericProduct" label="Obecný produkt" />
					<Block discriminateBy="service" label="Služba">
						<ProductComplementSettings />
					</Block>
					<Block discriminateBy="cheque" label="Šek" />
				</DiscriminatedBlocks>
			</Box>
			<Box heading={'Zařazení'}>
				<CategoryMultiSelectField field={'categories'} label={'Kategorie'} />
				<SelectField field={'brand'} label={'Značka'} options={'Brand.name'} />
				<MultiSelectField label={'Tagy'} options={'ProductTag.internalName'} field={'tags'} />
			</Box>
		</>
	),
	form: (
		<>
			<Box heading={undefined}>
				<LocaleSideDimension>
					<ContentEditor
						leadingElements={[
							{
								field: 'title',
								placeholder: 'Název',
								format: 'plainText',
								render: (props) => (
									<h1 style={{ fontSize: '2em', fontWeight: 600 }}>{props.children}</h1>
								),
							},
							{
								field: 'lead',
								placeholder: 'Perex',
								format: 'plainText',
								render: (props) => <p style={{ fontSize: '1em', fontWeight: 400 }}>{props.children}</p>,
							},
						]}
					/>
					<CollapsibleBox heading={'Nastavení SEO'}>
						<SeoForm titleDerivedFrom={'title'} />
					</CollapsibleBox>
				</LocaleSideDimension>
			</Box>
			<DependentCollapsible field={'kind'} condition={(it) => it === 'service'}>
				<Box heading={'Tooltip služby'}>
					<LocaleSideDimension>
						<RichTextField field={'tooltipTextJson'} label={'Text'} />
						<LinkField field={'tooltipLink'} />
					</LocaleSideDimension>
				</Box>
			</DependentCollapsible>

			<ProductAttributesRepeater field={'attributes'} label={'Společné atributy'} />
			<CollapsibleBox heading={'Vlastnosti'}>
				<SelectField field={'geometry'} label={'Geometrie'} options={'ProductGeometry.code'} />
				<Repeater field={'technologies'} label={'Technologie'} sortableBy={'order'}>
					<SelectField label={undefined} options={'ProductTechnology.code'} field={'technology'} />
				</Repeater>
				<Repeater field={'highlights'} label={'Zdůrazněné vlastnosti'} sortableBy={'order'}>
					<SelectField label={undefined} options={'ProductHighlight.code'} field={'highlight'} />
				</Repeater>
			</CollapsibleBox>
			<div className={'color-variants'}>
				<Repeater
					field={'products'}
					orderBy={'code'}
					label={'Barevné varianty'}
					initialEntityCount={0}
					enableAddingNew={__DEV_MODE__}
					enableRemoving={__DEV_MODE__}
					containerComponent={CollapsibleRepeaterContainer}
					containerComponentExtraProps={{
						addButtonText: 'Přidat barevnou variantu',
					}}
				>
					<TextField field="code" label="Kód" readOnly={!__DEV_MODE__} />
					<TextField field="colorCode" label="Kód barvy" readOnly={!__DEV_MODE__} />
					<TextField field="colorDescription" label="Popis barvy" readOnly={!__DEV_MODE__} />
					<ImageListField field={'images'} label={'Obrázky'} />
					<Block discriminateBy="video" label="Video">
						<Box heading="Video">
							<VideoUploadWithDescriptionField
								field="video"
								videoLabel={undefined}
								descriptionLabel="Popis"
							/>
							<TextField
								field="youtubeId"
								label="Youtube video"
								description={
									<>
										Nahrajte pouze id videa, např. <strong>EngW7tLk6R8</strong>{' '}
										(https://www.youtube.com/watch?v=EngW7tLk6R8)
									</>
								}
							/>
						</Box>
					</Block>
					<ProductAttributesRepeater field={'attributes'} label={'Atributy barevné varianty'} />
					<ProductLinksRepeater />
					<ProductListField field={'relatedProducts'} label={'Související'} />

					<Repeater
						field={'variants'}
						orderBy={'code'}
						label={'Velikostní varianty'}
						initialEntityCount={0}
						enableAddingNew={__DEV_MODE__}
						enableRemoving={__DEV_MODE__}
						containerComponentExtraProps={{
							addButtonText: 'Přidat velikostní variantu',
						}}
					>
						<TextField field="code" label="Kód" readOnly={!__DEV_MODE__} />
						<TextField field="sizeCode" label="Kód velikost" readOnly={!__DEV_MODE__} />
						<TextField field="sizeDescription" label="Popis velikosti" readOnly={!__DEV_MODE__} />
						<ProductAttributesRepeater field={'attributes'} label={'Atributy velikosti'} />
						<ProductPricesRepeater />
						<ProductSkusRepeater />
						<ProductDiscountsRepeater />
					</Repeater>
				</Repeater>
				<Box heading="Video">
					<LocaleSideDimension>
						<Box>
							<VideoUploadWithDescriptionField
								field="video"
								videoLabel={undefined}
								descriptionLabel="Popis"
							/>
						</Box>
					</LocaleSideDimension>
				</Box>
				<Box heading="Seo odkazy">
					<LocaleSideDimension>
						<Repeater field="seoCategoryLinks.items" label="Položky" sortableBy="order">
							<TextField field="title" label="Text" />
							<LinkField field="link" />
						</Repeater>
					</LocaleSideDimension>
				</Box>
				<ProductListField field={'relatedProducts'} label={'Související'} />
			</div>
		</>
	),
	grid: (
		<>
			<TextCell field="code" header="Kód matky" shrunk />
			<GenericCell header={'Obrázek'}>
				<HasMany
					field={'products'}
					limit={1}
					listComponent={(props) => {
						const images = Array.from(props.accessor)
							.flatMap((it) =>
								Array.from(it.getEntity('images').getEntityList({ field: 'items', limit: 1 })),
							)
							.map((it) => it.getField<string>('image.url'))
						if (images.length === 0) {
							return null
						}
						return <ImagePreview src={images[0].value!} />
					}}
				>
					<HasMany field={'images.items'} limit={1}>
						<Field field={'image.url'} />
					</HasMany>
				</HasMany>
			</GenericCell>

			{localizedCell((locale) => (
				<TextCell
					field={`locales(locale.code='${locale}').title`}
					header={`Název\u00A0(${locale})`}
					hidden={locale !== 'cs'}
				/>
			))}

			<ProductCategoriesCell field={'categories'} />
			<ProductAttributeCell path={['products']} field={'attributes'} attributeCode={'season'} header={'Sezóna'} />
			<ProductAttributeCell field={'attributes'} attributeCode={'model'} header={'Modelová řada'} />

			<TextCell header="Značka" field="brand.name" shrunk />
			<ProductStateInfo scope={'base'} defaultFilter={true} />
			<BooleanCell field="discontinued" header="Vyřazeno" shrunk hidden />
			<EnumCell
				field="kind"
				header="Druh"
				options={{ service: 'Service', genericProduct: 'Product', cheque: 'Šek' }}
				shrunk
				hidden
			/>
		</>
	),
	gridPageProps: { editButtonTarget: '_blank' },
})

const actions = (
	<>
		<PageLinkButton to={'productHiddenByBase'}>Dle matky</PageLinkButton>
		<PageLinkButton to={'productHiddenByProduct'}>Dle produktu</PageLinkButton>
		<PageLinkButton to={'productHiddenByVariant'}>Dle varianty</PageLinkButton>
	</>
)
export const ProductHiddenByBase = new Page(
	{ path: '/product/hidden/by-base' },
	(
		<GenericPage pageName={'productHiddenByBase'}>
			<DataBindingProvider stateComponent={FeedbackRenderer}>
				<TitleBar actions={actions}>Nezobrazované produkty (dle matky)</TitleBar>
				<DataGrid entities={'ProductBase[products.variants.states.visibleInListing = false]'} itemsPerPage={50}>
					<TextCell field="code" header="Kód matky" shrunk />
					{localizedCell((locale) => (
						<TextCell
							field={`locales(locale.code='${locale}').title`}
							header={`Název\u00A0(${locale})`}
							hidden={locale !== 'cs'}
						/>
					))}
					<ProductCategoriesCell field={'categories'} />
					<ProductAttributeCell
						path={['products']}
						field={'attributes'}
						attributeCode={'season'}
						header={'Sezóna'}
					/>

					<TextCell header="Značka" field="brand.name" shrunk />
					<ProductStateInfo scope={'base'} defaultFilter={false} />
					<ProductMissingInfoCell scope={'base'} />
					<GenericCell shrunk canBeHidden={false}>
						<EditButton pageName={ProductPages.link('edit')} size={'small'} target={'_blank'} />
					</GenericCell>
				</DataGrid>
			</DataBindingProvider>
		</GenericPage>
	),
)

export const ProductHiddenByProduct = new Page(
	{ path: '/product/hidden/by-product' },
	(
		<GenericPage pageName={'productHiddenByProduct'}>
			<DataBindingProvider stateComponent={FeedbackRenderer}>
				<TitleBar actions={actions}>Nezobrazované produkty (dle produktu)</TitleBar>
				<DataGrid entities={'Product[variants.states.visibleInListing = false]'} itemsPerPage={50}>
					<TextCell field="code" header="Kód produktu" shrunk />
					{localizedCell((locale) => (
						<TextCell
							field={`base.locales(locale.code='${locale}').title`}
							header={`Název\u00A0(${locale})`}
							hidden={locale !== 'cs'}
						/>
					))}
					<ProductCategoriesCell field={'base.categories'} />
					<ProductAttributeCell field={'attributes'} attributeCode={'season'} header={'Sezóna'} />
					<TextCell header="Značka" field="base.brand.name" shrunk />
					<ProductStateInfo scope={'product'} defaultFilter={false} />
					<ProductMissingInfoCell scope={'product'} />
					<GenericCell shrunk canBeHidden={false}>
						<EditButton
							pageName={ProductPages.link('edit')}
							size={'small'}
							entityPath={'base'}
							target={'_blank'}
						/>
					</GenericCell>
				</DataGrid>
			</DataBindingProvider>
		</GenericPage>
	),
)

export const ProductHiddenByVariant = new Page(
	{ path: '/product/hidden/by-variant' },
	(
		<GenericPage pageName={'productHiddenByVariant'}>
			<DataBindingProvider stateComponent={FeedbackRenderer}>
				<TitleBar actions={actions}>Nezobrazované produkty (dle varianty)</TitleBar>
				<DataGrid entities={'ProductVariant[states.visibleInListing = false]'} itemsPerPage={50}>
					<TextCell field="code" header="Kód varianty" shrunk />
					{localizedCell((locale) => (
						<TextCell
							field={`product.base.locales(locale.code='${locale}').title`}
							header={`Název\u00A0(${locale})`}
							hidden={locale !== 'cs'}
						/>
					))}
					<ProductCategoriesCell field={'product.base.categories'} />
					<ProductAttributeCell field={'product.attributes'} attributeCode={'season'} header={'Sezóna'} />
					<TextCell header="Značka" field="product.base.brand.name" shrunk />
					<ProductStateInfo scope={'variant'} defaultFilter={false} />
					<ProductMissingInfoCell scope={'variant'} />
					<GenericCell shrunk canBeHidden={false}>
						<EditButton
							pageName={ProductPages.link('edit')}
							size={'small'}
							entityPath={'product.base'}
							target={'_blank'}
						/>
					</GenericCell>
				</DataGrid>
			</DataBindingProvider>
		</GenericPage>
	),
)

import {
	Block,
	Box,
	CheckboxField,
	DiscriminatedBlocks,
	Field,
	MultiSelectField,
	Repeater,
	RichTextField,
	SelectField,
	TableCell,
	TextAreaField,
	TextCell,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { DependentCollapsible, LocaleSideDimension } from '../../components'
import { createCrudPages } from '../../utils'

export const ShippingMethodPages = createCrudPages({
	entityName: 'ShippingMethod',
	title: 'Dopravní metody',
	form: (
		<>
			<Box>
				<TextField field="code" label="Kód" />
				<TextField field="internalName" label="Interní název" />
				<CheckboxField field="enabled" label="Povoleno" defaultValue={true} />

				<DiscriminatedBlocks field="type" label="Typ">
					<Block discriminateBy="delivery" label="Doručení">
						<MultiSelectField field="countries" label="Podporované země" options="Country.code" />
					</Block>
					<Block discriminateBy="pickup" label="Vyzvednutí">
						<Repeater field="addresses" label="Adresy" orderBy={undefined}>
							<TextField field="title" label="Titulek" />
							<SelectField label="Adresa" options="Address[name != ''].name" field="address" />
						</Repeater>
					</Block>
					<Block discriminateBy="pickup_zasilkovna" label="Zásilkovna">
						<MultiSelectField field="countries" label="Podporované země" options="Country.code" />
					</Block>
					<Block discriminateBy="pickup_dpd" label="DPD Pickup">
						<MultiSelectField field="countries" label="Podporované země" options="Country.code" />
					</Block>
				</DiscriminatedBlocks>

				<MultiSelectField field="paymentMethods" label="Platební metody" options="PaymentMethod.internalName" />
			</Box>
			<Box heading={'Překlady'}>
				<LocaleSideDimension>
					<TextField field="name" label="Název" />
					<DependentCollapsible field={'description'} condition={(it) => !!it}>
						<TextAreaField field="description" label="Textový popis" />
					</DependentCollapsible>
					<RichTextField field="descriptionJson" label="Popis" />
				</LocaleSideDimension>
			</Box>
		</>
	),
	grid: (
		<>
			<TextCell field="code" header="Kód" shrunk />
			<TextCell field="internalName" header="Interní název" />
		</>
	),
})

import * as React from 'react'
import {
	ContentStatus,
	SaveControl,
	useClassNamePrefix,
	useEntity,
	useMutationState,
	usePersistWithFeedback,
} from '@contember/admin'

export const ProductImportApproveButton = React.memo(() => {
	const isMutating = useMutationState()
	const triggerPersist = usePersistWithFeedback()
	const prefix = useClassNamePrefix()
	const accessor = useEntity()
	const onClick = React.useCallback(async () => {
		accessor.getField('approvedAt').updateValue(new Date().toISOString())
		try {
			await triggerPersist()
		} catch {}
	}, [accessor, triggerPersist])

	if (!triggerPersist) {
		return null
	}
	if (accessor.getField('approvedAt').value) {
		return null
	}
	if (!accessor.getField('preprocessedAt').value) {
		return null
	}

	const message = isMutating ? 'Ukládám…' : undefined

	return (
		<div className={`${prefix}persistControl`}>
			{message && (
				<div className={`${prefix}persistControl-label`}>
					<ContentStatus label={message} />
				</div>
			)}
			<SaveControl primaryAction="Schválit" onPrimary={onClick} />
		</div>
	)
})

import {
	Box,
	EditPage,
	Field,
	MultiEditPage,
	MultiSelectField,
	PageLinkButton,
	Repeater,
	SelectField,
	TableCell,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { IconSelectField } from '../../components/Content/IconSelectField'
import { createCrudPages } from '../../utils'
import { EditButton, LinkField, LocaleSideDimension } from '../../components'

export const RewardPages = createCrudPages({
	entityName: 'Reward',
	title: 'Odměny',
	listPageProps: {
		orderBy: 'order desc',
	},
	createPageProps: {
		orderField: 'order',
		newOrderFieldValue: 0,
	},
	listPageFactory: (args) => (
		<MultiEditPage
			pageName={args.pageName}
			entities="Reward"
			rendererProps={{
				enableAddingNew: false,
				sortableBy: 'order',
				title: args.title,
				actions: <PageLinkButton to={args.pages.create.name}>Přidat</PageLinkButton>,
			}}
		>
			<div className={'rewardsIn'}>
				<div>
					<Field field={'title'} />
				</div>
				<div>
					<Field field={'channel.internalName'} />
				</div>
				<EditButton pageName={args.pages.edit.name} />
			</div>
		</MultiEditPage>
	),
	sideForm: (
		<>
			<SelectField
				field={'channel'}
				label={'Kanál'}
				options={'Channel'}
				renderOption={(accessor) => accessor.getField('internalName').value}
				optionsStaticRender={
					<>
						<Field field={'code'} />
						<Field field={'internalName'} />
					</>
				}
			/>
		</>
	),
	form: (
		<>
			<Box>
				<IconSelectField field="icon" label="Ikonka" />
				<TextField field="title" label="Nadpis" />
				<TextField field="text" label="Text" />
				<MultiSelectField label={'Skupiny'} field={'userGroups'} options={'UserGroup.internalName'} />
				<SelectField
					label={'Navázaná sleva'}
					options={'ProductDiscount.internalName'}
					field={'productDiscount'}
				/>
				<LinkField field={'link'} />
			</Box>
		</>
	),
	table: (
		<>
			<TableCell>
				<Field field="title" />
			</TableCell>
			<TableCell>
				<Field field="channel.internalName" />
			</TableCell>
		</>
	),
})

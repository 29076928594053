import * as React from 'react'
import { useCallback, useState } from 'react'
import { useSaveSpreadsheet } from '../../hooks'
import { ContainerSpinner, useRedirect } from '@contember/admin'
import { Spreadsheet, SpreadsheetFile } from '../../utils'
import { ProductImportXlsUploader } from './ProductImportXlsUploader'
import { SheetSelection } from './SheetSelection'

export const ProductImportUploader: React.FC<{ mappingPageName: string; importPageName: string }> = ({
	mappingPageName,
	importPageName,
}) => {
	const [saving, setSaving] = useState(false)
	const saveSpreadsheet = useSaveSpreadsheet()
	const redirect = useRedirect()
	const [spreadsheets, setSpreadsheets] = useState<Spreadsheet[]>([])

	const uploadCallback = useCallback(async (spreadsheets: SpreadsheetFile[]) => {
		const merged = spreadsheets.flatMap((wb) =>
			wb.sheets.map((it) => ({ ...it, name: `${wb.fileName} / ${it.name}` })),
		)
		setSpreadsheets(merged)
	}, [])
	const onSave = useCallback(
		async (sheets: Spreadsheet[]) => {
			setSaving(true)
			const ids: string[] = []
			for (const spreadsheet of sheets) {
				ids.push(...(await saveSpreadsheet.save(spreadsheet)))
			}
			if (ids.length === 1) {
				redirect((req) => ({ ...req, pageName: mappingPageName, parameters: { id: ids[0] } }))
			} else {
				redirect((req) => ({ ...req, pageName: importPageName }))
			}
		},
		[importPageName, mappingPageName, redirect, saveSpreadsheet],
	)
	if (saving) {
		return <ContainerSpinner />
	}
	if (spreadsheets.length === 0) {
		return <ProductImportXlsUploader onUpload={uploadCallback} />
	}
	return <SheetSelection spreadsheets={spreadsheets} onSave={onSave} />
}

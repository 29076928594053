import {
	FieldAccessor,
	SingleLineTextInputProps,
	TextInput,
	SimpleRelativeSingleField,
	SimpleRelativeSingleFieldProps,
} from '@contember/admin'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'

export type CentsFieldProps = SimpleRelativeSingleFieldProps &
	Omit<SingleLineTextInputProps, 'value' | 'onChange' | 'validationState' | 'allowNewlines'>

export const CentsField = SimpleRelativeSingleField<CentsFieldProps, number>(
	(fieldMetadata, { defaultValue, ...props }) => {
		const selection = useRef(0)
		const inputRef = useRef<HTMLInputElement>()
		const generateOnChange = (data: FieldAccessor<number>) => (e: React.ChangeEvent<HTMLInputElement>) => {
			selection.current = e.target.selectionStart || 0
			data.updateValue(e.target.value === '' ? null : Math.round(parseFloat(e.target.value) * 100))
		}
		const [prevValue, setPrevValue] = useState(fieldMetadata.field.value)
		useEffect(() => {
			if (typeof prevValue !== 'number' && typeof fieldMetadata.field.value === 'number') {
				inputRef.current?.setSelectionRange(selection.current, selection.current)
			}
			setPrevValue(fieldMetadata.field.value)
		}, [prevValue, fieldMetadata.field.value])

		return (
			<TextInput
				ref={inputRef}
				value={
					typeof fieldMetadata.field.value === 'number' ? (fieldMetadata.field.value / 100).toFixed(2) : ''
				}
				onChange={generateOnChange(fieldMetadata.field)}
				validationState={fieldMetadata.field.errors?.validation?.length ? 'invalid' : undefined}
				{...props}
				style={props.style as any}
			/>
		)
	},
	'CentsField',
)

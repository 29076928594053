import { Box, Repeater, TextCell, TextField } from '@contember/admin'
import * as React from 'react'
import {
	CategoryMultiSelectField,
	CollapsibleBox,
	ContentEditor,
	ImageField,
	LocaleSideDimension,
	SeoForm,
} from '../../components'
import { PerChannelUrlField } from '../../components/Site/PerChannelUrlField'
import { createCrudPages } from '../../utils'

export const BrandPages = createCrudPages({
	entityName: 'Brand',
	title: 'Značky',
	sideForm: (
		<>
			<Box title={'URL'}>
				<PerChannelUrlField field={'name'} softPrefix={'znacka/'} />
			</Box>
		</>
	),
	form: (
		<>
			<Box>
				<TextField field="name" label="Název" />

				<div className={'horizontal-fields'}>
					<ImageField field={'logo'} label={'Logo'} />
					<ImageField field={'coverImage'} label={'Cover obrázek'} />
				</div>
			</Box>
			<Box heading={'Překlady'}>
				<LocaleSideDimension>
					<ContentEditor leadingElements={[]} />
				</LocaleSideDimension>
			</Box>
			<CollapsibleBox heading={'Velikostní tabulky'}>
				<Repeater field={'sizeTables'} orderBy={undefined} label={undefined}>
					<CategoryMultiSelectField field={'categories'} label={'Kategorie'} />
					<ImageField field={'image'} label={'Obrázek tabulky'} />
				</Repeater>
			</CollapsibleBox>
			<LocaleSideDimension>
				<CollapsibleBox heading={'Nastavení SEO'}>
					<SeoForm titleDerivedFrom={'brand.name'} withDynamicDescriptionFields />
				</CollapsibleBox>
			</LocaleSideDimension>
		</>
	),
	grid: <TextCell field="name" header="Název" />,
})

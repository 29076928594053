import { Component, Field, FieldValue, SugaredRelativeSingleField, useField } from '@contember/admin'
import React from 'react'

export const IfField = Component<{
	field: string
	match: FieldValue | ((value: FieldValue) => boolean)
	children: React.ReactNode
}>(
	({ field, match, children }) => {
		const discriminant = useField(field)
		if ((typeof match === 'function' && match(discriminant.value)) || match === discriminant.value) {
			return <>{children}</>
		}
		return null
	},
	({ field, children }) => (
		<>
			<Field field={field} />
			{children}
		</>
	),
	'IfField',
)

import { Box, Component, LocationField, SelectField, TextField } from '@contember/admin'
import * as React from 'react'
import { CollapsibleBox } from './Generic'

export interface AddressFormProps {
	purpose: 'customerAddress' | 'branchAddress'
	heading?: string | false
}

export const AddressForm = Component<AddressFormProps>(
	(props) => (
		<Box heading={props.heading === false ? undefined : props.heading ?? 'Adresa'}>
			{props.purpose === 'customerAddress' ? (
				<>
					<TextField field="companyName" label="Název firmy" />
					<TextField field="companyIdentifier" label="IČ" />
					<TextField field="vatIdentifier" label="DIČ" />
					<TextField field="firstName" label="Jméno" />
					<TextField field="lastName" label="Příjmení" />
					<TextField field="phone" label="Kontaktní telefon" />
				</>
			) : null}
			{props.purpose === 'branchAddress' ? (
				<>
					<TextField field="externalIdentifier" label="Navision kód" />
					<TextField field="name" label="Název" />
				</>
			) : null}
			<TextField field="addressLine1" label="Ulice a ČP" />
			<TextField field="addressLine2" label="Doplňující informace k adrese" />
			<TextField field="city" label="Město" />
			<TextField field="postalCode" label="PSČ" />
			<SelectField field="country" options="Country.code" label="Země" />
			{props.purpose === 'branchAddress' ? (
				<>
					<CollapsibleBox heading={'Vybrat pozici na mapě'}>
						<LocationField latitudeField={'gpsLat'} longitudeField={'gpsLng'} label={undefined} />
					</CollapsibleBox>
				</>
			) : null}
		</Box>
	),
	'SeoForm',
)

import { Box, Component, Field, SelectField } from '@contember/admin'
import * as React from 'react'

export interface AddressSelectFieldProps {
	label: string
	field: string
}
export const AddressSelectField = Component<AddressSelectFieldProps>((props) => (
	<SelectField
		field={props.field}
		label={props.label}
		options={'Address[owningUser.id = $id]'}
		renderOption={(accessor) => {
			const companyName = accessor.getField('companyName').value
			const name = accessor.getField('firstName').value + ' ' + accessor.getField('lastName').value
			const address = accessor.getField('addressLine1').value
			const city = accessor.getField('city').value

			return [companyName, name, address, city].filter((it) => !!it).join(' / ')
		}}
		optionsStaticRender={
			<>
				<Field field={'companyName'} />
				<Field field={'firstName'} />
				<Field field={'lastName'} />
				<Field field={'addressLine1'} />
				<Field field={'city'} />
			</>
		}
	/>
))

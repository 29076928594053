import {
	ActionableBox,
	Box,
	Button,
	Component,
	Field,
	FieldAccessor,
	FormGroup,
	HasOne,
	SimpleRelativeSingleFieldProps,
	TextAreaField,
	useAuthedContentMutation,
	useEntity,
	useEnvironment,
	useField,
	useMutationState,
} from '@contember/admin'
import * as React from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Upload as TusUpload } from 'tus-js-client'

export interface UploadFieldMetadata {
	uploadState: UploadInfo
	accessor: FieldAccessor<string>
	emptyText?: React.ReactNode
}

export type UploadFieldProps = {
	accept?: string
	emptyText?: React.ReactNode
} & SimpleRelativeSingleFieldProps

enum UploadState {
	pending = 'pending',
	initializing = 'initializing',
	uploading = 'uploading',
	failed = 'failed',
	success = 'success',
}

type UploadInfo = { state: UploadState; error?: string; progress?: number; vimeoId?: string }
export type FileUpload = [
	UploadInfo,
	{
		startUpload: (file: File) => void
	},
]

export const useFileUpload = (): FileUpload => {
	const [generateVimeoUploadUrlMutation] = useAuthedContentMutation<
		{
			generateVimeoUploadUrl: {
				ok: boolean
				errors: {
					endUserMessage: string
					developerMessage: string
					code: string
				}[]
				result?: {
					uploadUrl: string
					vimeoId: string
				}
			}
		},
		{ size: number }
	>(`
	mutation($size: Int!) {
	  generateVimeoUploadUrl(size: $size) {
		ok
		errors {
		  endUserMessage
		  developerMessage
		  code
		}
		result {
		  uploadUrl
		  vimeoId
		}
	  }
	}
	`)

	const [uploadInfo, setUploadInfo] = useState<UploadInfo>({ state: UploadState.pending })

	const tusRef = useRef<TusUpload>()

	const uploadCallback = useCallback(
		async (file: File) => {
			if (tusRef.current) {
				console.warn('Aborting TUS upload from callback')
				tusRef.current.abort(true)
				tusRef.current = undefined
			}
			setUploadInfo({ state: UploadState.initializing })
			try {
				const response = await generateVimeoUploadUrlMutation({ size: file.size })
				const vimeoResponse = response.generateVimeoUploadUrl
				if (!vimeoResponse.ok) {
					console.error(vimeoResponse.errors)
					setUploadInfo({ state: UploadState.failed, error: vimeoResponse.errors[0].endUserMessage })
					return
				}

				const uploadUrl = vimeoResponse.result!.uploadUrl
				const vimeoId = vimeoResponse.result!.vimeoId

				setUploadInfo({ state: UploadState.uploading, progress: 0, vimeoId })
				const upload = new TusUpload(file, {
					endpoint: 'none',
					retryDelays: [0, 3000, 5000, 10000, 20000],
					onError: (error: Error) => {
						setUploadInfo({ state: UploadState.failed, error: error.message })
					},
					onProgress: function (bytesUploaded, bytesTotal) {
						const progress = (bytesUploaded / bytesTotal) * 100
						setUploadInfo({ state: UploadState.uploading, progress, vimeoId })
					},
					onSuccess: function () {
						setUploadInfo({ state: UploadState.success, progress: 100, vimeoId })
					},
				})
				upload.url = uploadUrl

				// Start the upload
				tusRef.current = upload
				upload.start()
			} catch (e) {
				console.error(e)
				setUploadInfo({ state: UploadState.failed, error: 'API request has failed' })
				return
			}
		},
		[generateVimeoUploadUrlMutation],
	)

	useEffect(() => {
		return () => {
			if (tusRef.current) {
				console.warn('Aborting TUS upload from unmount')
				tusRef.current.abort(true)
				tusRef.current = undefined
			}
		}
	}, [tusRef])

	return [uploadInfo, { startUpload: uploadCallback }]
}

export const VideoUploadWithDescriptionField = Component<{
	field: string
	videoLabel: string | undefined
	descriptionLabel: string | undefined
}>(
	({ field, videoLabel, descriptionLabel }) => {
		const accessor = useEntity(field)

		const remove = React.useCallback(() => {
			accessor.getAccessor().deleteEntity()
		}, [accessor])

		const childHasVimeoId = !accessor.getField('vimeoId').getAccessor().hasValue(null)
		const onRemove = childHasVimeoId ? remove : undefined

		return (
			<ActionableBox onRemove={onRemove}>
				<HasOne field={field}>
					<div className="horizontal-fields" style={{ paddingTop: '2.5em' }}>
						<VimeoUploadField field="vimeoId" label={videoLabel} />
						<TextAreaField field="description" label={descriptionLabel} />
					</div>
				</HasOne>
			</ActionableBox>
		)
	},
	({ field }) => (
		<HasOne field={field}>
			<Field field="vimeoId" />
			<Field field="description" />
		</HasOne>
	),
	'VideoUploadWithDescriptionField',
)

export const VimeoUploadField = Component<UploadFieldProps>(
	(props) => {
		const [uploadInfo, { startUpload }] = useFileUpload()
		const environment = useEnvironment()
		const isMutating = useMutationState()
		const accessor = useField<string>(props)
		const getAccessor = accessor.getAccessor

		useEffect(() => {
			if (uploadInfo.state === UploadState.success && uploadInfo.vimeoId) {
				const vimeoId = uploadInfo.vimeoId
				getAccessor().updateValue(vimeoId)
			}
		}, [uploadInfo.state, getAccessor, uploadInfo.vimeoId])

		const onDrop = React.useCallback(
			async (files: File[]) => {
				startUpload(files[0])
			},
			[startUpload],
		)
		const { getRootProps, getInputProps } = useDropzone({
			onDrop,
			disabled: isMutating,
			accept: 'video/*',
			multiple: false,
			noKeyboard: true, // This would normally be absolutely henious but there is a keyboard-focusable button inside.
		})
		const metadata: UploadFieldMetadata = React.useMemo(
			() => ({
				emptyText: props.emptyText,
				uploadState: uploadInfo,
				accessor,
			}),
			[accessor, props.emptyText, uploadInfo],
		)

		return (
			<FormGroup
				label={environment.applySystemMiddleware('labelMiddleware', props.label)}
				labelDescription={props.labelDescription}
				labelPosition={props.labelPosition}
				description={props.description}
				errors={accessor.errors}
			>
				<div
					{...getRootProps({
						style: {},
					})}
				>
					<input {...getInputProps()} />
					<Inner metadata={metadata} {...props} />
				</div>
			</FormGroup>
		)
	},
	(props) => (
		<>
			<Field field={props.field} />
		</>
	),
	'VimeoUploadField',
)

type InnerProps = SimpleRelativeSingleFieldProps & {
	metadata: UploadFieldMetadata
	emptyText?: React.ReactNode
}

const Inner = React.memo((props: InnerProps) => {
	const renderUploadStatusMessage = (uploadState: UploadInfo) => {
		if (!uploadState || uploadState.state === UploadState.pending) {
			return (
				<>
					<Button size="small">Select a video to upload</Button>
					<span className={'fileInput-drop'}>or drag & drop</span>
				</>
			)
		}
		switch (uploadState.state) {
			case UploadState.initializing:
				return `Starting upload`
			case UploadState.uploading:
				return `Upload progress: ${uploadState.progress?.toFixed()}%`
			case UploadState.failed:
				return `Upload failed: ${uploadState.error}`
			case UploadState.success:
				return `Video has been uploaded. It may took few minutes for Vimeo to process the video.`
		}
	}
	const [i, invokeRefresh] = useState(0)
	const renderPreview = () => {
		if (!props.metadata.accessor.value) {
			return null
		}
		return (
			<iframe
				src={`https://player.vimeo.com/video/${props.metadata.accessor.value}?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;invoke_refresh=${i}`}
				width="400"
				height="300"
				frameBorder="0"
				allow="autoplay; fullscreen"
				allowFullScreen
				style={{ maxWidth: '100%', height: 'auto' }}
			/>
		)
	}

	return (
		<Box distinction="seamlessIfNested">
			{props.metadata.uploadState.state === UploadState.success && (
				<a
					style={{ display: 'block' }}
					href={'#'}
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						invokeRefresh(i + 1)
					}}
				>
					refresh preview
				</a>
			)}
			<span className="fileInput" style={{ alignItems: 'center' }}>
				<span className="fileInput-preview" style={{ width: '12rem', maxWidth: '100%' }}>
					{renderPreview()}
				</span>
				<span className="fileInput-message">{renderUploadStatusMessage(props.metadata.uploadState)}</span>
			</span>
		</Box>
	)
})

import {
	Block,
	Component,
	DeferredSubTrees,
	DiscriminatedBlocks,
	Field,
	HasOne,
	SelectField,
	TextField,
	useDynamicSingleChoiceField,
	useField,
} from '@contember/admin'
import * as React from 'react'
import { LoadingMessage } from '../Generic'
import { EntityAccessor } from '@contember/binding'

export interface LinkFieldProps {
	field: string
}

const internalLinkSelectProps = {
	options: 'Linkable[product.id = null]',
	searchByFields: ['url'],
	optionsStaticRender: (
		<>
			<Field field={'channel.code'} />
			<Field field={'url'} />
		</>
	),
	renderOption: (accessor: EntityAccessor) => {
		return accessor.getField('channel.code').value + ': ' + accessor.getField('url').value
	},
}

export const LinkableField = Component<{ label: string; field: string }>((props) => (
	<SelectField {...internalLinkSelectProps} label={props.label} field={props.field} allowNull />
))

const LinkFieldInner = Component((props) => (
	<DiscriminatedBlocks field="type" label="Druh odkazu">
		<Block discriminateBy="internal" label="Internal">
			<LinkableField label={'URL'} field={'internalLink'} />
			<TextField field="internalLinkQuery" label="Query parametry" />
			<TextField field="internalLinkScrollTarget" label="Cílová kotva" />
		</Block>
		<Block discriminateBy="external" label="External">
			<TextField field="externalLink" label="URL" />
		</Block>
	</DiscriminatedBlocks>
))

const LinkFieldMiddle = Component<LinkFieldProps>(
	(props) => {
		const typeField = useField<string>(`${props.field}.type`)
		let typeValue = typeField.value
		if (typeValue === 'default') {
			typeValue = null
		}

		const internalLinkFieldProps = React.useMemo(
			() => ({
				...internalLinkSelectProps,
				field: `${props.field}.internalLink`,
			}),
			[props.field],
		)
		const internalLink = useDynamicSingleChoiceField(internalLinkFieldProps)

		const externalLink = useField<string>(`${props.field}.externalLink`)
		const renderer = React.useCallback(() => {
			if (typeValue === 'internal' && internalLink.currentValue >= 0) {
				return (
					<div className={'cui-input endorphin-linkConcealable-placeholder'}>
						Interní odkaz {internalLink.data[internalLink.currentValue].label}
					</div>
				)
			} else if (typeValue === 'external') {
				return (
					<div className={'cui-input endorphin-linkConcealable-placeholder'}>
						Externí odkaz {externalLink.value}
					</div>
				)
			}
			return <div className={'cui-input endorphin-linkConcealable-placeholder'}>Nastavit odkaz</div>
		}, [typeValue, internalLink.currentValue, internalLink.data, externalLink.value])
		const [isEditing, setEditing] = React.useState(!typeValue)

		return (
			<div className={'endorphin-linkConcealable'}>
				<HasOne field={props.field}>
					{isEditing ? <LinkFieldInner /> : <div onClick={() => setEditing(true)}>{renderer()}</div>}
				</HasOne>
			</div>
		)
	},
	(props) => (
		<HasOne field={props.field}>
			<LinkFieldInner />
		</HasOne>
	),
)
export const LinkField = Component<LinkFieldProps>((props) => (
	<DeferredSubTrees fallback={<LoadingMessage>Načítám odkazy…</LoadingMessage>}>
		<LinkFieldMiddle {...props} />
	</DeferredSubTrees>
))

import {
	AltPersistButton,
	Box,
	CheckboxField,
	Component,
	DateField,
	DetailPage,
	EditPage,
	EntityListSubTree,
	Field,
	FormGroup,
	HasMany,
	HiddenField,
	MultiSelectField,
	NumberField,
	Repeater,
	RichTextField,
	StaticRender,
	TextCell,
	TextField,
	UnsugarableSingleEntityEventListeners,
	useEntity,
	useEntityList,
	useEnvironment,
} from '@contember/admin'
import * as React from 'react'
import {
	ActiveDateCell,
	CentsField,
	DependentCollapsible,
	EditButton,
	HasManyFilterCell,
	LocaleSideDimension,
	NumberCell,
	ProductVoucherGeneratorForm,
} from '../../components'
import { ProductRuleInlineRepeater } from '../../components/Product/ProductRuleInlineRepeater'
import { createCrudPages, Page } from '../../utils'

const voucherCodesPageName = 'voucherCodesPage'
const voucherGenerateGroupsPageName = 'vouchersGenerateForUsers'
const voucherGenerateGlobalPageName = 'voucherGenerateGlobal'

interface DiscountFormProps {
	type: 'voucher' | 'discount'
}
const priceHandler: UnsugarableSingleEntityEventListeners['onInitialize'] = (getAccessor, { getEntityListSubTree }) => {
	const currentChannels = getAccessor().getEntityList('channels')
	currentChannels.addEventListener('beforeUpdate', () => {
		const currentPrices = getAccessor().getEntityList('prices')
		const allCurrencies = getEntityListSubTree('currencies')

		const channelCurrencies = Array.from(currentChannels, (field) => field.getField<string>('currency.id').value)
		const channelCurrenciesSet = new Set(channelCurrencies)
		const existingCurrencies = Array.from(currentPrices, (price) => price.getField<string>('currency.id').value)
		const existingCurrenciesSet = new Set(existingCurrencies)
		const hasPriceToRemove = existingCurrencies.some((id) => !channelCurrenciesSet.has(id))
		const hasPriceToAdd = channelCurrencies.some((id) => !existingCurrenciesSet.has(id))
		if (!(hasPriceToRemove || hasPriceToAdd)) {
			return
		}

		currentPrices.batchUpdates((getAccessor) => {
			for (const price of getAccessor()) {
				const id = price.getField<string>('currency.id').value
				if (!channelCurrenciesSet.has(id)) {
					price.deleteEntity()
				} else {
					channelCurrenciesSet.delete(id)
				}
			}
			for (const id of channelCurrenciesSet) {
				if (!id) {
					continue
				}
				getAccessor().createNewEntity((getAccessor) => {
					getAccessor().connectEntityAtField('currency', allCurrencies.getChildEntityById(id))
				})
			}
		})
	})
}

const validator: UnsugarableSingleEntityEventListeners['onBeforePersist'] = (getAccessor) => {
	const accessor = getAccessor()
	const hasAbsoluteDiscount =
		Array.from(
			accessor.getEntityList('prices'),
			(accessor) => accessor.getField<number>('absoluteDiscountCents').value,
		).filter((value) => value && value > 0).length > 0
	const discountField = accessor.getField<number>('productDiscountPercent')
	const hasRelativeDiscount = (discountField.value ?? 0) > 0
	if (hasRelativeDiscount && hasAbsoluteDiscount) {
		discountField.addError({ message: 'Vyplňte pouze relativní nebo absolutní slevu' })
	}
}

const CurrencyList = Component(() => (
	<StaticRender>
		<EntityListSubTree entities="Currency" expectedMutation="none" alias={'currencies'}>
			<Field field={'code'} />
		</EntityListSubTree>
	</StaticRender>
))
const VoucherCodesGenerationActions = () => {
	return (
		<>
			<EditButton pageName={voucherGenerateGlobalPageName}>Generovat vouchery</EditButton>
			<EditButton pageName={voucherGenerateGroupsPageName}>Generovat vouchery pro uživatelské skupiny</EditButton>
		</>
	)
}
const VoucherCodesActions: React.FC = () => {
	const entity = useEntity()
	if (!entity.idOnServer) {
		return null
	}
	return (
		<Box heading={'Vouchery'}>
			<VoucherCodesGenerationActions />
			<EditButton pageName={voucherCodesPageName}>Ruční správa voucherů</EditButton>
		</Box>
	)
}

const DiscountForm = Component((props: DiscountFormProps) => (
	<>
		<Box>
			<TextField field="internalName" label="Interní popis" />
			<MultiSelectField
				field={'channels'}
				label={'Kanály'}
				options={{
					entityName: 'Channel',
				}}
				renderOption={(accessor) => accessor.getField('internalName').value}
				optionsStaticRender={() => (
					<>
						<Field field={'internalName'} />
						<Field field={'currency.id'} />
						<Field field={'code'} />
					</>
				)}
			/>
			<LocaleSideDimension>
				<TextField field="name" label="Název" />
				<RichTextField field="description" label="Popis" />
				<TextField field="errorMessage" label="Chybová hláška při nesplnění podmínek voucheru" />
			</LocaleSideDimension>
		</Box>
		<Box heading={'Sleva'}>
			<NumberField
				field={'productDiscountPercent'}
				label={'Sleva v %'}
				defaultValue={0}
				min={0}
				max={100}
				onWheel={(e) => {
					e.currentTarget.blur()
				}}
			/>
			<ProductRuleInlineRepeater field={'discountedProductRules'} label={'Zlevněné produkty'} />
			{props.type === 'voucher' ? (
				<>
					<HasMany field={'prices'}>
						<div className={'horizontal-fields'}>
							<CurrencyList />
							<TextField field={'currency.code'} label={'Měna'} readOnly={true} />
							<CentsField field={'absoluteDiscountCents'} label={'Absolutní sleva'} defaultValue={0} />
							<CentsField field={'minOrderCents'} label={'Minimální objednávka'} defaultValue={0} />
						</div>
					</HasMany>
					<CheckboxField
						field={'isFreeShippingVoucher'}
						label={'Voucher na dopravu zdarma'}
						defaultValue={false}
					/>
					<DependentCollapsible field={'isFreeShippingVoucher'} condition={(it) => !!it}>
						<MultiSelectField
							field="freeShippingMethods"
							label="Dopravní metody"
							options="ShippingMethod.internalName"
						/>
					</DependentCollapsible>
				</>
			) : null}
		</Box>
		<Box heading={'Platnost'}>
			<div className={'horizontal-fields'}>
				<DateField field={'activeSince'} label={'Platná od'} />
				<DateField field={'activeUntil'} label={'Platná do'} description={'(bez koncového dne)'} />
			</div>

			<CheckboxField
				field={'isUserGroupDiscount'}
				label={'Omezeno na uživatelskou skupinu'}
				defaultValue={false}
			/>
			<DependentCollapsible field={'isUserGroupDiscount'} condition={(it) => !!it}>
				<MultiSelectField label={'Skupiny'} field={'userGroups'} options={'UserGroup.internalName'} />
			</DependentCollapsible>

			{props.type === 'voucher' ? (
				<>
					<HiddenField field={'isRequiredProductDiscount'} defaultValue={false} label={undefined} />
					<HiddenField field={'maxDiscountedProductsMultiplier'} defaultValue={0} label={undefined} />
					<CheckboxField
						field={'allowVoucherWithGeneralDiscountCombination'}
						label={'Povolit kombinaci s běžnými slevami'}
						defaultValue={false}
					/>
					<CheckboxField
						field={'allowVoucherWithUserGroupDiscountCombination'}
						label={'Povolit kombinaci s věrnostními slevami'}
						defaultValue={false}
					/>
					<CheckboxField
						field={'allowVoucherWithComplementaryDiscountCombination'}
						label={'Povolit kombinaci s komplementárními slevami'}
						defaultValue={false}
					/>
				</>
			) : (
				<>
					<HiddenField
						field={'allowVoucherWithGeneralDiscountCombination'}
						defaultValue={false}
						label={undefined}
					/>
					<HiddenField
						field={'allowVoucherWithUserGroupDiscountCombination'}
						defaultValue={false}
						label={undefined}
					/>
					<HiddenField
						field={'allowVoucherWithComplementaryDiscountCombination'}
						defaultValue={false}
						label={undefined}
					/>
					<HiddenField field={'isFreeShippingVoucher'} defaultValue={false} label={undefined} />

					<CheckboxField
						field={'isRequiredProductDiscount'}
						label={'Pouze při nákupu jiných produktů'}
						defaultValue={false}
					/>

					<DependentCollapsible field={'isRequiredProductDiscount'} condition={(it) => !!it}>
						<ProductRuleInlineRepeater field={'requiredProductRules'} label={'Vyžadované produkty'} />
						<NumberField
							field={'maxDiscountedProductsMultiplier'}
							label={'Násobitel maximálního počtu zlevněných produktů'}
							defaultValue={1}
							onWheel={(e) => {
								e.currentTarget.blur()
							}}
						/>
					</DependentCollapsible>
				</>
			)}
		</Box>

		<HiddenField field={'isVoucherDiscount'} defaultValue={props.type === 'voucher'} label={undefined} />
		<HiddenField field={'generateAutomatically'} defaultValue={false} label={undefined} />
		{props.type === 'voucher' && (
			<>
				{/*<CheckboxField*/}
				{/*	field={'generateAutomatically'}*/}
				{/*	defaultValue={false}*/}
				{/*	label={'Generovat automaticky pro nové uživatele'}*/}
				{/*/>*/}
				<VoucherCodesActions />
			</>
		)}
	</>
))
const grid = (
	<>
		<>
			<TextCell header={'Název'} field={'internalName'} />
			<NumberCell header={'Sleva v %'} field={'productDiscountPercent'} />
			<ActiveDateCell fromField={'activeSince'} toField={'activeUntil'} header={'Aktivní'} />
			<HasManyFilterCell
				header={'Web'}
				field={'channels'}
				render={({ entities }) => {
					const channels = entities.map((it) => it.getField<string>('internalName').value).join('/')
					return <>{channels}</>
				}}
			>
				<Field field={'internalName'} />
			</HasManyFilterCell>
		</>
	</>
)

const DiscountPreviewUrl = Component(
	(props) => {
		const entity = useEntity()
		const currentChannels = useEntityList('channels')
		const environment = useEnvironment()
		if (!entity.idOnServer) {
			return null
		}
		const urls = Array.from(currentChannels, (channel) => {
			const channelCode = channel.getField<string>('code').value
			const baseUrl = environment.getValue(channelCode === 'SK' ? 'SK_FRONTEND_BASEPATH' : 'CZ_FRONTEND_BASEPATH')
			return (
				<div key={channelCode}>
					<a href={`${baseUrl}discount-preview/${entity.idOnServer}`} target="_blank" rel="noopener">
						<small>Otevřít náhled: </small> {channelCode}
					</a>
				</div>
			)
		})
		return (
			<FormGroup
				label={'Náhled odpovídajích produktů (nutno uložit)'}
				labelDescription={'URL je pouze pro neveřejný náhled. Nesdílejte ji.'}
			>
				{urls}
			</FormGroup>
		)
	},
	() => {
		return (
			<>
				<HasMany field={'channels'}>
					<Field field={'code'} />
				</HasMany>
			</>
		)
	},
)

export const DiscountPages = createCrudPages({
	entityName: 'ProductDiscount',
	title: 'Slevy',
	gridPageProps: {
		entities: 'ProductDiscount[isVoucherDiscount = false && $multiChannelFilter]',
	},
	form: <DiscountForm type={'discount'} />,
	grid: grid,
	sideForm: (
		<Box>
			<DiscountPreviewUrl />
		</Box>
	),
})
export const VoucherPages = createCrudPages({
	entityName: 'ProductDiscount',
	routeName: 'Voucher',
	title: 'Vouchery',
	gridPageProps: {
		entities: 'ProductDiscount[isVoucherDiscount = true && $multiChannelFilter]',
	},
	editPageProps: {
		onBeforePersist: validator,
	},
	createPageProps: {
		onInitialize: priceHandler,
		onBeforePersist: validator,
		redirectOnSuccess: (request, id) => ({ ...request, pageName: voucherCodesPageName, parameters: { id } }),
	},
	form: <DiscountForm type={'voucher'} />,
	grid: grid,
	sideForm: (
		<Box>
			<DiscountPreviewUrl />
		</Box>
	),
})

export const GenerateUserGroupVouchersPage = new Page(
	{ path: '/vouchers/generate-group/:id' },
	(
		<DetailPage
			entity={{ entityName: 'ProductDiscount', where: '(id = $id)' }}
			pageName={voucherGenerateGroupsPageName}
			rendererProps={{
				title: 'Generování voucherů pro skupiny',
				navigation: (
					<>
						<EditButton pageName={VoucherPages.link('edit')}>Zpět na voucher</EditButton>
						<EditButton pageName={voucherCodesPageName}>Správa kódů</EditButton>
					</>
				),
			}}
		>
			<ProductVoucherGeneratorForm type={'group'} />
		</DetailPage>
	),
)

export const GenerateGlobalVouchersPage = new Page(
	{ path: '/vouchers/generate-global/:id' },
	(
		<DetailPage
			entity={{ entityName: 'ProductDiscount', where: '(id = $id)' }}
			pageName={voucherGenerateGlobalPageName}
			rendererProps={{
				title: 'Generování voucherů',
				navigation: (
					<>
						<EditButton pageName={VoucherPages.link('edit')}>Zpět na voucher</EditButton>
						<EditButton pageName={voucherCodesPageName}>Správa kódů</EditButton>
					</>
				),
			}}
		>
			<ProductVoucherGeneratorForm type={'global'} />
		</DetailPage>
	),
)

export const VoucherCodesPage = new Page(
	{ path: '/vouchers/codes/:id' },
	(
		<EditPage
			entity={{ entityName: 'ProductDiscount', where: '(id = $id)' }}
			pageName={voucherCodesPageName}
			rendererProps={{
				title: 'Kódy voucherů',
				navigation: (
					<>
						<EditButton pageName={VoucherPages.link('edit')}>Zpět na voucher</EditButton>
						<VoucherCodesGenerationActions />
					</>
				),
				persistButtonComponent: AltPersistButton,
			}}
		>
			<Repeater field={'vouchers'} label={undefined} orderBy={'createdAt'}>
				<TextField field={'code'} label={'Kód'} />
				<div className={'horizontal-fields'}>
					<DateField field={'activeSince'} label={'Platný od'} />
					<DateField field={'activeUntil'} label={'Platný do'} />
				</div>
				<div className={'horizontal-fields'}>
					<NumberField
						field={'usageLimit'}
						label={'Limit použití'}
						onWheel={(e) => {
							e.currentTarget.blur()
						}}
					/>
					<NumberField
						field={'usageLimitPerUser'}
						label={'Limit použití na uživatele'}
						onWheel={(e) => {
							e.currentTarget.blur()
						}}
					/>
					<NumberField readOnly={true} field={'currentUsages'} label={'Aktualní počet užití'} />
				</div>
				<FormGroup label={'Navázaný uživatel'}>
					<Field field={'user.email'} format={(val) => val || 'Není'} />
				</FormGroup>
			</Repeater>
		</EditPage>
	),
)

import { Component, Repeater, HiddenField } from '@contember/admin'
import { ProductRuleForm } from './ProductRuleForm'

export interface ProductRuleInlineRepeaterProps {
	field: string
	label: string | undefined
}
const now = new Date().toISOString()
export const ProductRuleInlineRepeater = Component<ProductRuleInlineRepeaterProps>((props) => (
	<Repeater field={props.field} orderBy={undefined} label={props.label} initialEntityCount={0}>
		<HiddenField field={'isInline'} defaultValue={true} label={undefined} isNonbearing={false} />
		<HiddenField field={'internalName'} defaultValue={`Inline rule ${now}`} label={undefined} />
		<ProductRuleForm />
	</Repeater>
))

import {
	Box,
	Button,
	Component,
	Dropdown,
	Icon,
	InitializeReferenceToolbarButton,
	useEntity,
	useField,
} from '@contember/admin'
import * as React from 'react'
import './editorLinks.sass'
import { LinkField } from '../Site/LinkField'

const customLinkElementType = 'internalAnchor'

const InsertLink = Component<{
	referenceId: string
	editor: any
	selection: any
	onSuccess: () => void
	onCancel: () => void
}>(
	({ editor, selection, referenceId, onSuccess, onCancel }) => {
		const link = useEntity('link')
		return (
			<Box heading="Vložit odkaz">
				<LinkField field={'link'} />
				<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1em', minWidth: '350px' }}>
					<Button onClick={onCancel}>Zrušit</Button>
					<Button
						intent="primary"
						onClick={() => {
							if (
								link.getField('internalLink.id').value === null &&
								link.getField('externalLink').value === null
							) {
								return onCancel()
							}
							editor.slate.Transforms.select(editor, selection)
							editor.slate.Transforms.wrapNodes(
								editor,
								{
									type: customLinkElementType,
									children: [{ text: '' }],
									referenceId,
								},
								{ split: true },
							)
							editor.slate.Transforms.collapse(editor, { edge: 'end' })
							onSuccess()
						}}
					>
						Vložit
					</Button>
				</div>
			</Box>
		)
	},
	() => (
		<>
			<LinkField field={'link'} />
		</>
	),
	'InsertLink',
)

const InternalLinkInner = (props: any) => {
	return (
		<span {...props.attributes} style={{ color: '#0094FF' }}>
			{props.children}

			<span contentEditable={false}>
				<Dropdown
					renderToggle={({ ref, onClick }) => (
						<button ref={ref as any} onClick={onClick} className="editorLinks-button">
							<Icon blueprintIcon="link" />
						</button>
					)}
				>
					<Box>
						<LinkField field={'link'} />
					</Box>
				</Dropdown>
			</span>
		</span>
	)
}

const InternalLink = Component(
	(props: any) => {
		const type = useField('type')
		if (type.value === 'button') {
			return (
				<span className={'editor-button-link'}>
					<InternalLinkInner {...props} />
				</span>
			)
		}
		return <InternalLinkInner {...props} />
	},
	(props: any) => <InternalLinkInner {...props} />,
)

export const withCustomLinks = <E extends any>(editor: E): E => {
	const { renderElement, isInline } = editor as any
	const isInternalLink = (element: any) => element.type === customLinkElementType

	Object.assign(editor, {
		renderElement: (props: any) =>
			isInternalLink(props.element) ? React.createElement(InternalLink, props) : renderElement(props),
		isInline: (element: any) => isInternalLink(element) || isInline(element),
	})

	return editor
}

export const customLinkButton: InitializeReferenceToolbarButton = {
	discriminateBy: 'link',
	initializeReference: (getEntity) => {
		getEntity().getField('link.type').updateValue('default')
	},
	referenceContent: InsertLink,
	label: 'Vložit odkaz',
	title: 'Vložit odkaz',
	blueprintIcon: 'link',
}

export const customButtonLinkButton: InitializeReferenceToolbarButton = {
	discriminateBy: 'button',
	initializeReference: (getEntity) => {
		getEntity().getField('link.type').updateValue('default')
	},
	referenceContent: InsertLink,
	label: 'Vložit tlačítko',
	title: 'Vložit tlačítko',
	blueprintIcon: 'widget-button',
}

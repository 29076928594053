import {
	Component,
	Entity,
	EntityAccessor,
	Field,
	HasOne,
	MarkerFactory,
	PRIMARY_KEY_NAME,
	TreeRootIdProvider,
	useBindingOperations,
	useEntity,
} from '@contember/admin'
import { LocaleSelectField } from '../Site/LocaleSelectField'
import * as React from 'react'
import { useEffect } from 'react'
import { useIsCollapsed } from '../Generic'

const OptionSelectFieldInner = Component<{ attribute: string }>(({ attribute }) => {
	return (
		<LocaleSelectField
			label={'Hodnota'}
			entities={
				{
					entityName: 'ProductAttributeOption',
					filter: { attribute: { id: { eq: attribute } } },
				} as any
			}
			localizedField={'name'}
			field={'optionValue'}
		/>
	)
})

const PassEntity = Component(
	({ accessor, children }: { accessor: EntityAccessor; children: React.ReactChild }) => {
		return <Entity accessor={accessor}>{children}</Entity>
	},
	{
		generateBranchMarker: (props, fields, environment) => {
			// if (props.accessor.idOnServer) {
			// 	return MarkerFactory.createEntitySubTreeMarker(
			// 		environment,
			// 		{
			// 			entity: {
			// 				entityName: props.accessor.name,
			// 				where: {
			// 					id: props.accessor.idOnServer,
			// 				},
			// 			},
			// 		},
			// 		fields,
			// 	)
			// }
			return MarkerFactory.createUnconstrainedEntitySubTreeMarker(
				{
					entity: props.accessor.name,
					isCreating: true,
					expectedMutation: 'none',
				},
				fields,
				environment,
			)
		},
		staticRender: (props) => (
			<>
				<Entity accessor={props.accessor}>
					<Field field={PRIMARY_KEY_NAME} />
					{props.children}
				</Entity>
			</>
		),
	},
	'DirectEntity',
)

export const ProductAttributeOptionSelectField = Component(
	() => {
		const attribute = useEntity('attribute').idOnServer
		const isMountedRef = React.useRef(true)
		const { extendTree } = useBindingOperations()
		const isCollapsed = useIsCollapsed()

		const [displayedState, setDisplayedState] = React.useState<{
			attribute: string | undefined
			treeRootId: string | undefined
		}>({
			attribute: undefined,
			treeRootId: undefined,
		})

		const loadAbortControllerRef = React.useRef<AbortController | undefined>(undefined)
		const entity = useEntity()

		useEffect(() => {
			if (isCollapsed) {
				return
			}
			const newController = new AbortController()
			;(async () => {
				if (!attribute) {
					return
				}
				if (attribute === displayedState.attribute) {
					return
				}
				loadAbortControllerRef.current = newController

				const treeRootId = await extendTree(
					<PassEntity accessor={entity}>
						<OptionSelectFieldInner attribute={attribute} />
					</PassEntity>,
					{ signal: newController.signal },
				)

				if (!isMountedRef.current) {
					return
				}
				setDisplayedState({
					attribute,
					treeRootId,
				})
			})()
			return () => newController.abort()
		}, [attribute, entity, extendTree, displayedState.attribute, isCollapsed])

		React.useEffect(
			() => () => {
				isMountedRef.current = false
			},
			[],
		)
		if (!displayedState.attribute) {
			return null
		}
		return (
			<PassEntity accessor={entity}>
				<TreeRootIdProvider treeRootId={displayedState.treeRootId}>
					<OptionSelectFieldInner attribute={displayedState.attribute} />
				</TreeRootIdProvider>
			</PassEntity>
		)
	},
	() => {
		return (
			<HasOne field={'optionValue'}>
				<Field field={'id'} />
			</HasOne>
		)
	},
)

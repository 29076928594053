import { AltPersistButton, MultiEditPage, TextField } from '@contember/admin'
import * as React from 'react'

export const currencyPageName = 'currency'

export const currencyRoutes = {
	[currencyPageName]: { path: '/settings/currencies' },
}

export const CurrencyPage = (
	<MultiEditPage
		entities="Currency"
		pageName={currencyPageName}
		rendererProps={{
			title: 'Měny',
			persistButtonComponent: AltPersistButton,
		}}
	>
		<TextField label="Kód (ISO 4217)" field="code" />
	</MultiEditPage>
)

import {
	Box,
	Component,
	DataBindingProvider,
	DataGrid,
	EditPage,
	FeedbackRenderer,
	Field,
	GenericCell,
	GenericPage,
	NavigateBackButton,
	TextCell,
	TitleBar,
} from '@contember/admin'
import * as React from 'react'
import { Page } from '../../utils'
import { EditButton } from '../../components'

interface DataViewProps {
	data: {
		[label: string]: string | React.ReactNode
	}
}

const DataView = Component<DataViewProps>(
	({ data }) => (
		<dl style={{ display: 'grid', gridTemplateColumns: 'auto 1fr' }}>
			{Object.entries(data).map(([key, value]) => (
				<>
					<dt style={{ fontWeight: 'bold' }}>{key}:</dt>
					<dd>{typeof value === 'string' ? <Field field={value} /> : value}</dd>
				</>
			))}
		</dl>
	),
	'DataViewProps',
)

export const ServicebookServiceOrderFormPages = new Page(
	{ path: '/servicebookServiceOrderForm' },
	(
		<GenericPage pageName="servicebookServiceOrderForm">
			<DataBindingProvider stateComponent={FeedbackRenderer}>
				<TitleBar>Webové objednávky servisní knížky</TitleBar>
				<DataGrid entities="ServicebookServiceOrderFormEntry" itemsPerPage={50}>
					<TextCell header="Číslo předmětu servisu" field="serviceItem.serviceItemNo" />
					<TextCell header="Jméno" field="firstName" initialOrder="desc" />
					<TextCell header="Příjmění" field="lastName" />
					<TextCell header="Email" field="email" />
					<TextCell header="Telefon" field="phone" />
					<TextCell header="Vytvořeno" field="createdAt" />
					<GenericCell shrunk canBeHidden={false}>
						<EditButton pageName="servicebookServiceOrderFormEntry" size={'small'}>
							Detail
						</EditButton>
					</GenericCell>
				</DataGrid>
			</DataBindingProvider>
		</GenericPage>
	),
)

export const ServicebookServiceOrderFormPage = new Page(
	{ path: '/servicebookServiceOrderForm/:id' },
	(
		<EditPage
			pageName="servicebookServiceOrderFormEntry"
			entity="ServicebookServiceOrderFormEntry(id=$id)"
			rendererProps={{
				navigation: <NavigateBackButton to="servicebookServiceOrderForm">Výpis</NavigateBackButton>,
				title: 'Webové objednávky servisní knížky',
				persistButtonComponent: () => null,
			}}
		>
			<Box heading="Detail objednávky">
				<Box heading="Identifikace">
					<DataView
						data={{
							Jméno: <Field field="firstName" />,
							Příjmění: <Field field="lastName" />,
							Email: <Field field="email" />,
							Telefon: <Field field="phone" />,
							Poznámka: <Field field="note" />,
							Vytvořeno: <Field field="createdAt" />,
						}}
					/>
				</Box>
				<Box heading="Prodejna">
					<DataView
						data={{
							Název: <Field field="store.internalName" />,
						}}
					/>
				</Box>
				<Box heading="Předmět servisu">
					<DataView
						data={{
							Číslo: <Field field="serviceItem.serviceItemNo" />,
							Název: <Field field="serviceItem.externalItemDescription" />,
						}}
					/>
				</Box>
				<Box heading="Úkon">
					<DataView
						data={{
							Název: <Field field="serviceType.internalName" />,
						}}
					/>
				</Box>
			</Box>
		</EditPage>
	),
)

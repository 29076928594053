import { GenericPage, PageLinkButton } from '@contember/admin'
import * as React from 'react'
import { Page } from '../../utils'
import {
	ProductImportBatchList,
	ProductImportBatchPreview,
	ProductImportMappingForm,
	ProductImportUploader,
} from '../../components'

const importPageName = 'productImport'
const importUploadPageName = 'productImportUpload'
const importViewPageName = 'productImportView'
const importMappingPageName = 'importMapping'

export const ImportPage = new Page(
	{ path: '/product-import' },
	(
		<GenericPage pageName={importPageName}>
			<h2>Import</h2>
			<ProductImportBatchList mappingFieldName={importMappingPageName} viewPageName={importViewPageName} />
			<PageLinkButton to={importUploadPageName}>Nový import</PageLinkButton>
		</GenericPage>
	),
)

export const ImportUploadPage = new Page(
	{ path: '/product-import/upload' },
	(
		<GenericPage pageName={importUploadPageName}>
			<h2>Import</h2>
			<ProductImportUploader importPageName={importPageName} mappingPageName={importMappingPageName} />
		</GenericPage>
	),
)
export const ImportMappingPage = new Page(
	{ path: '/product-import/mapping/:id' },
	(
		<GenericPage pageName={importMappingPageName}>
			<ProductImportMappingForm viewPageName={importViewPageName} listPageName={importPageName} />,
		</GenericPage>
	),
)
export const ImportViewPage = new Page(
	{ path: '/product-import/view/:id' },
	(
		<GenericPage pageName={importViewPageName}>
			<ProductImportBatchPreview listPageName={importPageName} />
		</GenericPage>
	),
)

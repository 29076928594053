import { Box, Checkbox, Component, DataGridColumn, Filter } from '@contember/admin'
import * as React from 'react'
import { createProductStateInfoRelations, useProductStates } from './ProductStateInfoHelpers'
import { isUnique } from '../../utils'
import classNames from 'classnames'

export interface ProductStockCell {
	defaultFilter: boolean
	scope: 'product' | 'variant' | 'base'
}

const ProductStockInfo = Component<{ scope: 'product' | 'variant' | 'base' }>(
	(props) => {
		const states = useProductStates(props.scope)
		const variantCount = states.map((it) => it.variant).filter(isUnique).length
		const withStock = states
			.filter((it) => it.hasQuantity)
			.map((it) => it.variant)
			.filter(isUnique).length
		const visibleCount = states
			.filter((it) => it.visibleInListing)
			.map((it) => it.variant)
			.filter(isUnique).length
		return (
			<>
				<span
					title={`Ve výpise viditelných ${visibleCount} z ${variantCount} variant`}
					className={classNames(
						'product-visible',
						visibleCount ? 'product-visible-yes' : 'product-visible-no',
					)}
				/>
				<span title={'Dostupných variant z celkového počtu'}>
					{withStock}/{variantCount}
				</span>
			</>
		)
	},
	(props) => {
		return <>{createProductStateInfoRelations(props.scope)}</>
	},
)

interface ProductStockCellArtifacts {
	stock: boolean
	futureSeason: boolean
	currentSeason: boolean
	visibleInListing: boolean
}

const wrapFilter = (where: Filter, scope: 'product' | 'base' | 'variant') => {
	if (scope === 'product' || scope === 'base') {
		where = { variants: where }
	}
	if (scope === 'base') {
		where = { products: where }
	}
	return where
}
const emptyFilter = { currentSeason: false, futureSeason: false, stock: false, visibleInListing: false }
const initFilter = { currentSeason: false, stock: true, futureSeason: true, visibleInListing: false }

export const ProductStateInfo = Component<ProductStockCell>((props) => {
	return (
		<DataGridColumn<ProductStockCellArtifacts>
			{...props}
			header={'Stav'}
			enableOrdering={false}
			getNewFilter={(filter, { environment }) => {
				const or: Filter[] = []
				if (filter.stock) {
					or.push({ hasQuantity: { eq: true } })
				}
				if (filter.futureSeason) {
					or.push({ isFutureSeason: { eq: true } })
				}
				if (filter.currentSeason) {
					or.push({ isCurrentSeason: { eq: true } })
				}
				const and: Filter[] = []
				if (or.length > 0) {
					and.push({ or })
				}
				if (filter.visibleInListing) {
					and.push({ visibleInListing: { eq: true } })
				}

				return wrapFilter({ states: { and } }, props.scope)
			}}
			enableFiltering={true}
			initialFilter={props.defaultFilter ? initFilter : undefined}
			emptyFilter={emptyFilter}
			filterRenderer={({ filter, setFilter }) => {
				return (
					<>
						<Checkbox
							value={filter.visibleInListing}
							onChange={(val) => {
								setFilter({ ...filter, visibleInListing: val })
							}}
						>
							Viditelné ve výpisu
						</Checkbox>
						<Box>
							Jedno z:
							<br />
							<Checkbox
								value={filter.stock}
								onChange={(val) => {
									setFilter({ ...filter, stock: val })
								}}
							>
								Pouze skladem
							</Checkbox>
							<Checkbox
								value={filter.futureSeason}
								onChange={(val) => {
									setFilter({ ...filter, futureSeason: val })
								}}
							>
								Budoucí sezóna
							</Checkbox>
							<Checkbox
								value={filter.currentSeason}
								onChange={(val) => {
									setFilter({ ...filter, currentSeason: val })
								}}
							>
								Současná sezóna
							</Checkbox>
						</Box>
					</>
				)
			}}
		>
			<ProductStockInfo scope={props.scope} />
		</DataGridColumn>
	)
})

import {
	Box,
	Component,
	DataBindingProvider,
	DataGrid,
	DateCell,
	DateFieldView,
	EditPage,
	FeedbackRenderer,
	Field,
	GenericCell,
	GenericPage,
	HasMany,
	HasOne,
	NavigateBackButton,
	Repeater,
	TextCell,
	TitleBar,
	useEntity,
	useEntityList,
	useField,
} from '@contember/admin'
import * as React from 'react'
import { CoalesceTextCell, EditButton, EnumCell } from '../../components'
import { Page } from '../../utils'

interface DataViewProps {
	data: {
		[label: string]: string | React.ReactNode
	}
}

const DataView = Component<DataViewProps>(
	({ data }) => (
		<dl style={{ display: 'grid', gridTemplateColumns: 'auto 1fr' }}>
			{Object.entries(data).map(([key, value]) => (
				<>
					<dt style={{ fontWeight: 'bold' }}>{key}:</dt>
					<dd>{typeof value === 'string' ? <Field field={value} /> : value}</dd>
				</>
			))}
		</dl>
	),
	'DataViewProps',
)

const OrderConfirmedField = Component(
	() => {
		const kind = useField('kind')
		const paidAt = useField('payment.paidAt')
		return <>{kind.value === 'reservation' || paidAt.value ? 'Ano' : 'Ne'}</>
	},
	() => {
		return (
			<>
				<Field field={'kind'} />
				<Field field={'payment.paidAt'} />
			</>
		)
	},
)

const OrderPrice = Component(
	() => {
		const order = useEntity()
		const itemsPrice = Array.from(useEntityList('items'), (accessor) => {
			return accessor.getField<number>('unitPriceCents').value! * accessor.getField<number>('quantity').value!
		}).reduce((acc, val) => acc + val, 0)
		const vouchers = Array.from(useEntityList('vouchers[type = check]'), (accessor) => {
			return accessor.getField<number>('valueCents').value!
		}).reduce((acc, val) => acc + val, 0)

		return (
			<>
				{(
					(itemsPrice +
						-1 * vouchers +
						order.getField<number>('shipping.priceCents').value! +
						order.getField<number>('payment.priceCents').value!) /
					100
				).toFixed(2)}
			</>
		)
	},
	() => {
		return (
			<>
				<HasMany field={'items'}>
					<Field field={'unitPriceCents'} />
					<Field field={'quantity'} />
				</HasMany>
				<HasMany field={'vouchers[type=check]'}>
					<Field field={'valueCents'} />
				</HasMany>
				<Field field={'shipping.priceCents'} />
				<Field field={'payment.priceCents'} />
			</>
		)
	},
)

const AddressView = Component<{ field: string }>(
	({ field }) => (
		<HasOne field={field}>
			<DataView
				data={{
					Název: 'name',
					Jméno: 'firstName',
					Příjmení: 'lastName',
					'První řádek': 'addressLine1',
					'Druhý řádek': 'addressLine2',
					Město: 'city',
					PSČ: 'postalCode',
					'Kód země': 'country.code',
					Email: 'email',
					Telefon: 'phone',
					'Název společnosti': 'companyName',
					IČO: 'companyIdentifier',
					DIČ: 'vatIdentifier',
					'IČ DPH': 'vatIdentifierSecondary',
					'Zeměpisná šířka': 'gpsLat',
					'Zeměpisná výška': 'gpsLng',
					'Externí identifikátor': 'externalIdentifier',
				}}
			/>
		</HasOne>
	),
	'AddressView',
)

export const OrderListPage = new Page(
	{ path: '/order' },
	(
		<GenericPage pageName="orderList">
			<DataBindingProvider stateComponent={FeedbackRenderer}>
				<TitleBar>Objednávky</TitleBar>
				<DataGrid entities="Order" itemsPerPage={50}>
					<DateCell header="Datum" field="createdAt" initialOrder="desc" />
					<CoalesceTextCell header="E-mail" fields={['billingAddress.email', 'user.email']} />
					<TextCell header="Telefon" field="billingAddress.phone" hidden />
					<TextCell header="Jméno" field="billingAddress.firstName" />
					<TextCell header="Příjmení" field="billingAddress.lastName" />

					<TextCell header="Uživatel: email" field="user.email" hidden />
					<TextCell header="Uživatel: telefon" field="user.phone" hidden />
					<TextCell header="Uživatel: jméno" field="user.firstName" hidden />
					<TextCell header="Uživatel: příjmení" field="user.lastName" hidden />

					<TextCell header="Měna" field="currency.code" />
					<EnumCell
						header="Druh"
						field="kind"
						options={{
							order: 'Objednávka',
							reservation: 'Rezervace',
						}}
					/>
					<GenericCell header="Potvrzeno">
						<OrderConfirmedField />
					</GenericCell>
					<TextCell header="Navision: Číslo dokumentu" field="navisionDocumentNo" hidden />
					<TextCell header="Navision: Číslo objednávky" field="navisionOrderNo" />
					<GenericCell shrunk canBeHidden={false}>
						<EditButton pageName="orderDetail" size={'small'}>
							Detail
						</EditButton>
					</GenericCell>
				</DataGrid>
			</DataBindingProvider>
		</GenericPage>
	),
)

export const OrderDetailPage = new Page(
	{ path: '/order/:id' },
	(
		<EditPage
			pageName="orderDetail"
			entity="Order(id=$id)"
			rendererProps={{
				navigation: <NavigateBackButton to="orderList">Výpis</NavigateBackButton>,
				title: 'Detail objednávky',
				persistButtonComponent: () => null,
			}}
		>
			<Box heading="Uživatel">
				<DataView
					data={{
						Jméno: (
							<>
								<Field field="user.firstName" /> <Field field="user.lastName" />
							</>
						),
						Email: (
							<>
								<Field field="user.email" /> (ověřený:{' '}
								<DateFieldView field="user.emailVerifiedAt" fallback="Ne" />)
							</>
						),
						Telefon: <Field field="user.phone" />,
						'Číslo karty v Navision': <Field field="user.navisionCardNo" />,
						Pohlaví: (
							<Field
								field="user.gender"
								format={(value) => {
									return value === 'male' ? 'Muž' : value === 'female' ? 'Žena' : ''
								}}
							/>
						),
						'Datum registrace': <DateFieldView field="user.registeredAt" />,
					}}
				/>
			</Box>

			<Box heading="Objednávka">
				<DataView
					data={{
						Potvrzeno: <Field field={'payment.paidAt'} format={(it) => (it ? 'Ano' : 'Ne')} />,
						Kanál: 'channel.internalName',
						Měna: 'currency.code',
						Poznámka: 'note',
						'Cena objednávky': <OrderPrice />,
						'Navision - číslo dokumentu': 'navisionDocumentNo',
						'Navision - číslo objednávky': 'navisionOrderNo',
					}}
				/>
				<Repeater
					label="Vouchery"
					field="vouchers[type=voucher]"
					enableRemoving={false}
					enableAddingNew={false}
					initialEntityCount={0}
					orderBy={undefined}
				>
					<DataView
						data={{
							Kód: 'code',
						}}
					/>
				</Repeater>
				<Repeater
					label="Šeky"
					field="vouchers[type=check]"
					enableRemoving={false}
					enableAddingNew={false}
					initialEntityCount={0}
					orderBy={undefined}
				>
					<DataView
						data={{
							Kód: 'code',
							SKU: 'sku',
							Hodnota: <Field<number> field="valueCents" format={(it) => it && (it / 100).toFixed(2)} />,
						}}
					/>
				</Repeater>
				<Repeater
					label="Položky"
					field="items"
					enableRemoving={false}
					enableAddingNew={false}
					initialEntityCount={0}
					orderBy={undefined}
				>
					<DataView
						data={{
							Počet: 'quantity',
							'Jednotková cena': (
								<Field<number> field="unitPriceCents" format={(it) => it && (it / 100).toFixed(2)} />
							),
							Položka: (
								<>
									<Field field="variant.code" /> (
									<Field field="variant.product.base.locales(locale.code='cs').title" />)
								</>
							),
							SKU: (
								<>
									<HasMany field={'skus'}>
										<Field field={'sku'} />
										<span>&nbsp;</span>
									</HasMany>
								</>
							),
						}}
					/>
				</Repeater>

				<Box heading="Doprava">
					<DataView
						data={{
							Metoda: 'shipping.method.internalName',
							Cena: (
								<Field<number>
									field="shipping.priceCents"
									format={(it) => it && (it / 100).toFixed(2)}
								/>
							),
							Adresa: <AddressView field="shipping.address" />,
						}}
					/>
				</Box>

				<Box heading="Fakturační adresa">
					<AddressView field="billingAddress" />
				</Box>

				<Box heading="Platba">
					<DataView
						data={{
							'Platební metoda': 'payment.method.internalName',
							ID: 'payment.seqId',
							Cena: (
								<Field<number>
									field="payment.priceCents"
									format={(it) => it && (it / 100).toFixed(2)}
								/>
							),
							Proběhlo: <DateFieldView field="payment.paidAt" fallback="Ne" />,
							Selhalo: <DateFieldView field="payment.failedAt" fallback="Ne" />,
						}}
					/>
				</Box>
			</Box>
		</EditPage>
	),
)

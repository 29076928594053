import { useCallback, useMemo, useState } from 'react'
import {
	Button,
	Component,
	DataBindingProvider,
	EntitySubTree,
	FeedbackRenderer,
	Field,
	HasMany,
	MutableSingleEntityRenderer,
	NavigateBackButton,
	Table,
	TableCell,
	TableHeaderCell,
	TableRow,
	Tag,
	useAuthedContentQuery,
	useEntity,
	useField,
} from '@contember/admin'
import { ProductImportApproveButton } from './ProductImportApproveButton'
import { DependentCollapsible } from '../Generic/DependentCollapsible'
import * as React from 'react'
import { ProductImportBatchStatus } from './ProductImportBatchStatus'
import { downloadXlsx } from '../../utils'

type ItemsResult = {
	items: {
		productBaseCode?: string
		productCode?: string
		variantCode?: string
		skuCode?: string
		warnings: Record<string, string>[]
		data: Record<string, string>
	}[]
	fields: {
		label: string
		fieldName: string
	}[]
}

const printWarning = (warning: Record<string, string>) => {
	if (!('type' in warning)) {
		return 'Neznámý error'
	}
	switch (warning.type) {
		case 'undefined_primary_attribute_value':
			return `Nedefinovaná primární hodnota atributu ${warning.attribute} (${JSON.stringify(warning.values)})`
		case 'numeric_attribute_mismatch':
			return `Numerické hodnoty atributu ${warning.attributeCode} se v jednotlivých jazycích neshodují: ${warning.primaryValue} vs ${warning.localeValue}`
		case 'multiple_attribute_options_found':
			return `Nalezeno více možných hodnot atributu ${warning.attributeCode} s hodnotami ${JSON.stringify(
				warning.localeValues,
			)}`
		case 'same_variant_attributes_mismatch':
			return `Varianta s více SKU má rozdílné atributy`
		case 'product_base_not_found':
			return `Matka nenalezena`
		case 'product_not_found':
			return `Produkt nenalezen`
		case 'product_variant_not_found':
			return `Varianta nenalezena`
		case 'product_sku_not_found':
			return `SKU nenalezeno`
		case 'product_primary_identifier_not_exists':
			return 'Neexistuje hlavni identifikator produktu'
		default:
			return 'Neznámý error'
	}
}

export const ErrorsTable: React.FC = () => {
	const entity = useEntity()
	const variables = useMemo(
		() => ({
			id: entity.idOnServer || '00000000-0000-0000-0000-000000000000',
		}),
		[entity.idOnServer],
	)
	const { state } = useAuthedContentQuery<ItemsResult, { id: string }>(
		`
	query ($id: UUID!) {
		items: listProductImportItem(filter: {batch: {id: {eq: $id}}, warnings: {isNull: false}}) {
			productBaseCode
			productCode
			variantCode
			skuCode
			warnings
			data
		}
		fields: listProductImportField(filter: {batch: {id: {eq: $id}}}, orderBy: [{order: asc}]) {
			label
			fieldName
		}
	}`,
		variables,
	)
	const downloadWarnings = useCallback(() => {
		if (!state.data) {
			return
		}
		const data = []
		data.push(['chyby', ...state.data.fields.map((it) => it.label)])
		for (const item of state.data.items) {
			const row = [
				item.warnings.map(printWarning).join('\n'),
				...state.data.fields.map((it) => item.data[it.fieldName] || undefined),
			]
			data.push(row)
		}
		downloadXlsx(data, 'endorphin import.xlsx')
	}, [state.data])
	if (!state.finished || !state.data) {
		return null
	}
	if (state.data.items.length === 0) {
		return <p>Žádné chyby v importu</p>
	}

	return (
		<>
			<Button onClick={downloadWarnings}>Stáhnout XLSX s chybními řádky</Button>
			<Table
				heading={<>Chyby ({state.data.items.length})</>}
				tableHead={
					<TableRow>
						<TableHeaderCell>ID</TableHeaderCell>
						<TableHeaderCell>Chyby</TableHeaderCell>
					</TableRow>
				}
			>
				{state.data.items.map((it) => (
					<TableRow>
						<TableCell>
							{it.skuCode ?? it.variantCode ?? it.productCode ?? it.productBaseCode ?? 'neznámý'}
						</TableCell>
						<TableCell>
							{it.warnings.map(printWarning).map((it) => (
								<div>{it}</div>
							))}
						</TableCell>
					</TableRow>
				)) || null}
			</Table>
		</>
	)
}

export const ProductImportBatchPreview: React.FC<{ listPageName: string }> = ({ listPageName }) => {
	const [key, setKey] = useState(0)
	const invalidate = useCallback(() => setKey((val) => val + 1), [])

	return (
		<DataBindingProvider stateComponent={FeedbackRenderer} key={key}>
			<EntitySubTree
				entity={{ entityName: 'ProductImportBatch', where: '(id = $id)' }}
				entityComponent={MutableSingleEntityRenderer}
				entityProps={
					{
						title: <>Import</>,
						navigation: <NavigateBackButton to={listPageName}>Výpis</NavigateBackButton>,
						persistButtonComponent: ProductImportApproveButton,
					} as any
				}
			>
				<h2>
					<ProductImportBatchStatus onRefresh={invalidate} />
				</h2>
				<Field field={'fileName'} />
				<Field field={'approvedAt'} format={() => null} />
				<DependentCollapsible field={'preprocessedAt'} condition={(it) => !!it}>
					<ErrorsTable />
				</DependentCollapsible>
			</EntitySubTree>
		</DataBindingProvider>
	)
}

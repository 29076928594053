import { Box, TextCell, TextField } from '@contember/admin'
import * as React from 'react'
import { LocaleSideDimension } from '../../components'
import { ContentEditor } from '../../components/Content/ContentEditor'
import { createCrudPages } from '../../utils'

export const TechnologyPages = createCrudPages({
	entityName: 'ProductTechnology',
	title: 'Technologie',
	sideForm: (
		<Box>
			<TextField field="code" label="Kód" />
		</Box>
	),
	form: (
		<>
			<Box>
				<LocaleSideDimension>
					<ContentEditor leadingElements={[]} />
				</LocaleSideDimension>
			</Box>
		</>
	),
	grid: <TextCell field="code" header="Kód" />,
})

import { Component, Field, FormGroupProps, HasOne, SlugField, SlugFieldProps, useEntity } from '@contember/admin'
import * as React from 'react'
import { useCallback } from 'react'

export interface LinkUrlFieldProps extends Partial<SlugFieldProps> {
	derivedFrom: SlugFieldProps['derivedFrom']
	softPrefix?: string
	label: FormGroupProps['label']
}

const SlugFieldInner = Component<Omit<LinkUrlFieldProps, 'channelDerivedFrom'>>((props) => (
	<SlugField field="link.url" {...props} />
))

export const LinkUrlField = Component<LinkUrlFieldProps>(
	(props) => {
		const entity = useEntity()
		entity.addEventListener(
			'beforeUpdate',
			useCallback((entityAccessor) => {
				const channel = entityAccessor().getEntity('channel')
				const link = entityAccessor().getEntity('link')
				const linkChannel = link.getEntity('channel')
				if (linkChannel.idOnServer !== channel.idOnServer) {
					link.connectEntityAtField('channel', channel)
				}
			}, []),
		)
		return <SlugFieldInner {...props} />
	},
	(props) => (
		<>
			<HasOne field={'link.channel'} expectedMutation="connectOrDisconnect">
				<Field field={'id'} />
			</HasOne>
			<SlugFieldInner {...props} />
		</>
	),
	'LinkUrlField',
)

import {
	AltPersistButton,
	Block,
	Box,
	CheckboxField,
	DeleteEntityButton,
	DiscriminatedBlocks,
	Field,
	HasOne,
	HiddenField,
	ListPage,
	MultiEditPage,
	MultiSelectField,
	NavigateBackButton,
	NumberField,
	PageLinkButton,
	Repeater,
	SelectField,
	SlugField,
	TextAreaField,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import {
	CategoryCodeProvider,
	CategoryFeedMapping,
	CategoryParentFilterView,
	CategoryParentSelect,
	CategoryTopProducts,
	CategoryTreeContainer,
	CollapsibleBox,
	ContentEditor,
	DependentCollapsible,
	EditButton,
	ImageField,
	LinkField,
	LocaleSelectField,
	LocaleSideDimension,
	ProductCategoryListField,
	SeoForm,
} from '../../components'
import { PerChannelLocalizedUrlField } from '../../components/Site/PerChannelUrlField'
import { createCrudPages, Page } from '../../utils'

export const ProductCategoryPages = createCrudPages({
	entityName: 'ProductCategory',
	title: 'Kategorie',
	form: (
		<>
			<Box heading={'Překlady'}>
				<LocaleSideDimension>
					<ContentEditor
						leadingElements={[
							{
								field: 'name',
								placeholder: 'Název',
								format: 'plainText',
								render: (props) => (
									<h1 style={{ fontSize: '2em', fontWeight: 600 }}>{props.children}</h1>
								),
							},
						]}
					/>
					<TextField field="shortName" label="Krátký název" />
					<TextField field="shortDescription" label="Krátký popis" />
					<SlugField field="slug" derivedFrom={'name'} label="Unikátní klíč pro filtrování do URL" />
					<CheckboxField field="isNeuter" label="Střední rod" />
				</LocaleSideDimension>
			</Box>
			<Box heading={'Mapování pro feedy'}>
				<CategoryFeedMapping service={'google'} />
				<CategoryFeedMapping service={'heureka'} />
				<CategoryFeedMapping service={'zbozi'} />
			</Box>

			<div
				style={{
					display: 'flex',
					gap: '1em',
					alignItems: 'flex-start',
					justifyContent: 'stretch',
					marginTop: '1.3em',
				}}
			>
				<div style={{ flexGrow: 1 }}>
					<Repeater field={'filters.items'} label={'Filtry'} sortableBy={'order'}>
						<HiddenField isNonbearing={false} field={'type'} label={undefined} />
						<DiscriminatedBlocks field="type" label="Druh">
							<Block discriminateBy="price" label="Cena"></Block>
							<Block discriminateBy="brand" label="Značka"></Block>
							<Block discriminateBy="withSale" label="Sleva"></Block>
							<Block discriminateBy="onStock" label="Skladem"></Block>
							<Block discriminateBy="attribute" label="Atribut">
								<LocaleSelectField
									label={'Atribut'}
									entities="ProductAttribute"
									localizedField="name"
									field={'attribute'}
								/>
							</Block>
						</DiscriminatedBlocks>
					</Repeater>
					<CheckboxField
						field="groupSizeAttribute"
						label="Seskupit číselné velikosti po intervalech hodnot (10-20, 20-30, ...)"
					/>
				</div>

				<div>
					<HasOne field={'parent'}>
						<CategoryParentFilterView />
					</HasOne>
				</div>
			</div>
			<Repeater
				field={'children'}
				label={'Řazení podkategorií'}
				sortableBy={'order'}
				enableRemoving={false}
				enableAddingNew={false}
			>
				<LocaleSideDimension>
					<Field field={'name'} />
				</LocaleSideDimension>
			</Repeater>
			<ProductCategoryListField field={'relatedCategories'} label={'Související kategorie'} />
			<CategoryTopProducts />
			<Repeater
				label={'Zvýrazněné atributy'}
				field={'attributes'}
				sortableBy={'order'}
				onChildBeforeUpdate={(accessor) => {
					if (
						accessor().getField('brand').value === true &&
						accessor().getEntity('attribute').existsOnServer
					) {
						accessor().disconnectEntityAtField('attribute')
					}
				}}
			>
				<SelectField
					defaultValue={false}
					label="Druh"
					options={[
						{ label: 'Značka', value: true },
						{ label: 'Atribut', value: false },
					]}
					field="brand"
				/>
				<DependentCollapsible field="brand" condition={(brand) => !brand}>
					<div style={{ marginTop: '.5em' }}>
						<LocaleSelectField
							field={'attribute'}
							entities={'ProductAttribute'}
							localizedField={'name'}
							label={'Atribut'}
						/>
					</div>
				</DependentCollapsible>
			</Repeater>
			<Repeater label={'Porovnávané vlastnosti'} field={'comparisonItems.items'} sortableBy={'order'}>
				<HiddenField isNonbearing={false} field={'type'} label={undefined} />
				<DiscriminatedBlocks label={'Typ'} field={'type'} isNonbearing={false}>
					<Block discriminateBy={'price'} label={'Cena'}></Block>
					<Block discriminateBy={'brand'} label={'Značka'}></Block>
					<Block discriminateBy={'attribute'} label={'Atribut'}>
						<LocaleSelectField
							field={'attribute'}
							entities={'ProductAttribute'}
							localizedField={'name'}
							label={'Atribut'}
						/>
					</Block>
				</DiscriminatedBlocks>
			</Repeater>
			<Repeater field={'highlights'} label={'Zdůrazněné vlastnosti'} sortableBy={'order'}>
				<SelectField label={undefined} options={'ProductHighlight.code'} field={'highlight'} />
			</Repeater>
			<Box heading={'Nastavení zobrazení'}>
				<div className={'horizontal-fields'}>
					<ImageField field={'photoImage'} label={'Obrázek s fotkou'} />
					<ImageField field={'productImage'} label={'Obrázek s produktem'} />
				</div>

				<CheckboxField field={'showSubCategories'} label={'Zobrazit podkategorie'} />
				<SelectField
					label={'Vzhled odkazů'}
					options={[
						{ value: 'standard', label: 'Běžné dlaždice' },
						{ value: 'small', label: 'Malé odkazy' },
					]}
					field={'categoryLinksLayout'}
				/>
				<LocaleSideDimension>
					<Repeater label={'Další odkazy'} field={'links'} sortableBy={'order'}>
						<TextField field={'caption'} label={'Text odkazu'} />
						<TextAreaField field={'text'} label={'Popis'} />
						<ImageField field={'image'} label={'Obrázek'} />
						<LinkField field={'link'} />
					</Repeater>
				</LocaleSideDimension>
			</Box>
			<LocaleSideDimension>
				<CollapsibleBox heading={'Nastavení SEO'}>
					<SeoForm titleDerivedFrom={'name'} withDynamicDescriptionFields />
				</CollapsibleBox>
			</LocaleSideDimension>
		</>
	),
	sideForm: (
		<>
			<Box heading={undefined}>
				<CategoryParentSelect />
				<NumberField
					field="code"
					label="Kód"
					onWheel={(e) => {
						e.currentTarget.blur()
					}}
				/>
				<DiscriminatedBlocks field="kind" label="Druh">
					<Block discriminateBy="standard" label="Běžná kategorie" />
					<Block discriminateBy="virtual" label="Virtuální kategorie">
						<SelectField
							label={'Sleva'}
							options={'ProductDiscount.internalName'}
							field={'virtualCategoryDiscount'}
							allowNull
						/>
						<MultiSelectField
							label={'Produkty'}
							options={'ProductRule[isInline=false].internalName'}
							field={'virtualCategoryRules'}
						/>
					</Block>
				</DiscriminatedBlocks>
				<TextField field="margin" label="Maržovost" />
				<CheckboxField field={'hideInMenu'} label={'Skrýt v menu'} />
				<CategoryCodeProvider />
				<PerChannelLocalizedUrlField localizedField={'name'} softPrefix={'c/'} />
			</Box>
		</>
	),
	table: <></>,
	listPageFactory: ({ pageName, entityName, title, pages, table }) => {
		const categoryBody = (
			<>
				<div style={{ float: 'right' }}>
					<span className="endorphin-categoryDeleteButton">
						<DeleteEntityButton Component="a" immediatePersist={true} size="default" />
					</span>
					<span className="endorphin-categoryEditButton">
						<EditButton pageName={pages.edit.name} />
					</span>
				</div>
				<div className={'endorphin-categoryContent'}>
					<span className={'endorphin-categoryContent-code'}>
						<Field field={'code'} />
					</span>
					<LocaleSideDimension>
						<Field field="name" />
					</LocaleSideDimension>
				</div>
				<Field field="parent.id" format={(value) => null} />
			</>
		)
		return (
			<ListPage
				pageName={pageName}
				entities={entityName}
				orderBy={'order'}
				rendererProps={{
					title: title,
					actions: (
						<>
							<PageLinkButton to={pages.create.name}>Přidat</PageLinkButton>
							<PageLinkButton to={'categoriesOrder'}>Seřadit</PageLinkButton>
						</>
					),
					containerComponent: CategoryTreeContainer,
					containerComponentExtraProps: {
						categoryBody: categoryBody,
					},
				}}
			>
				{categoryBody}
			</ListPage>
		)
	},
})

export const CategoryOrderPage = new Page(
	{ path: '/categories/order' },
	(
		<MultiEditPage
			entities={{ entityName: 'ProductCategory', filter: { parent: { id: { isNull: true } } } }}
			pageName={'categoriesOrder'}
			rendererProps={{
				navigation: <NavigateBackButton to={ProductCategoryPages.link('list')}>Výpis</NavigateBackButton>,
				title: 'Řazení hlavních kategorií',
				sortableBy: 'order',
				persistButtonComponent: AltPersistButton,
				enableRemoving: false,
				enableAddingNew: false,
			}}
		>
			<LocaleSideDimension>
				<Field field="name" />
			</LocaleSideDimension>
		</MultiEditPage>
	),
)

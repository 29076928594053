import { Box, TextCell, TextField } from '@contember/admin'
import * as React from 'react'
import { ContentEditor, ImageField } from '../../components'
import { createCrudPages } from '../../utils'

export const GeometryPages = createCrudPages({
	entityName: 'ProductGeometry',
	title: 'Geometrie',
	sideForm: (
		<Box>
			<TextField field="code" label="Kód" />
			<TextField field="internalName" label="Interní název" />
		</Box>
	),
	form: (
		<>
			<Box>
				<ImageField field={'image'} label={'Obrázek'} />
				<ContentEditor leadingElements={[]} />
			</Box>
		</>
	),
	listPageProps: {
		orderBy: 'code',
	},
	grid: (
		<>
			<TextCell field="code" header="Kód" />
			<TextCell field="internalName" header="Interní název" />
		</>
	),
})

import { Collapsible, Component, Field, FieldAccessor, FieldValue, useField } from '@contember/admin'
import * as React from 'react'
import { CollapsibleContext, useIsCollapsed } from './CollapsibleContext'

export interface DependentCollapsibleProps {
	field: string
	condition: (value: FieldValue, accessor: FieldAccessor) => boolean
	children: React.ReactNode
	withoutWrapper?: boolean
}

export const DependentCollapsible = Component<DependentCollapsibleProps>(
	({ children, field, condition, withoutWrapper }) => {
		const fieldAccessor = useField(field)
		const value = fieldAccessor.value
		const isExpanded = condition(value, fieldAccessor)
		const isCollapsedContext = useIsCollapsed()
		const newCollapsedValue = isCollapsedContext || !isExpanded
		if (withoutWrapper) {
			return (
				<CollapsibleContext.Provider value={newCollapsedValue}>
					{isExpanded ? children : null}
				</CollapsibleContext.Provider>
			)
		}
		return (
			<CollapsibleContext.Provider value={newCollapsedValue}>
				<Collapsible expanded={isExpanded}>{children}</Collapsible>
			</CollapsibleContext.Provider>
		)
	},
	(props) => (
		<>
			{props.children}
			<Field field={props.field} />
		</>
	),
)

import { Box, TextCell, TextField } from '@contember/admin'
import * as React from 'react'
import { LinkField, LocaleSideDimension, ProductAttributeMappings } from '../../components'
import { createCrudPages, localizedCell } from '../../utils'

export const AttributeOptionPages = createCrudPages({
	entityName: 'ProductAttributeOption',
	title: 'Honoty atributů',
	form: (
		<>
			<Box>
				<LocaleSideDimension>
					<TextField field="name" label="Název" />
					<LinkField field={'link'} />
				</LocaleSideDimension>
			</Box>
			<ProductAttributeMappings />
		</>
	),
	grid: (
		<>
			{localizedCell((locale) => (
				<TextCell
					field={`locales(locale.code='${locale}').name`}
					header={`Hodnota\u00A0(${locale})`}
					hidden={locale !== 'cs'}
				/>
			))}
			{localizedCell((locale) => (
				<TextCell
					field={`attribute.locales(locale.code='${locale}').name`}
					header={`Atribut\u00A0(${locale})`}
					hidden={locale !== 'cs'}
				/>
			))}
			<TextCell field={'attribute.code'} initialOrder={'asc'} hidden />
		</>
	),
})

import {
	AltPersistButton,
	Box,
	CheckboxField,
	Component,
	DateField,
	EditPage,
	HasOne,
	MultiEditPage,
	MultiSelectField,
	NavigateBackButton,
	Repeater,
	RichTextField,
	SelectField,
	TextAreaField,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { CentsField, DependentCollapsible, EditButton, ImageListField, LinkField } from '../../components'
import { IconSelectField } from '../../components/Content/IconSelectField'

export const channelPageName = 'channel'
export const channelShippingPageName = 'channelShipping'
export const channelPaymentPageName = 'channelPayment'
export const channelFooterPageName = 'footer'
export const channelInformationStripPageName = 'informationStrip'
export const channelHeaderMenuPageName = 'headerMenu'

export const channelRoutes = {
	[channelPageName]: { path: '/settings/channels' },
	[channelShippingPageName]: { path: '/settings/channels/:id/shipping' },
	[channelPaymentPageName]: { path: '/settings/channels/:id/payment' },
	[channelFooterPageName]: { path: '/settings/channels/:id/footer' },
	[channelInformationStripPageName]: { path: '/settings/channels/:id/information-strip' },
	[channelHeaderMenuPageName]: { path: '/settings/channels/:id/header-menu' },
}

export const ChannelPage = (
	<MultiEditPage
		entities="Channel"
		pageName={channelPageName}
		rendererProps={{
			title: 'Kanály',
			persistButtonComponent: AltPersistButton,
		}}
	>
		<TextField label="Název" field="internalName" />
		<CheckboxField field={'enabled'} label={'Zapnuto'} />
		<TextField label="Kód" field="code" />
		<SelectField label={'Jazyk'} field={'locale'} options={'Locale.code'} />
		<SelectField label={'Měna'} field={'currency'} options={'Currency.code'} />
		<EditButton pageName={channelShippingPageName}>Doprava</EditButton>
		<EditButton pageName={channelPaymentPageName}>Platba</EditButton>
		<EditButton pageName={channelFooterPageName}>Patička</EditButton>
		<EditButton pageName={channelHeaderMenuPageName}>Hlavička</EditButton>
		<EditButton pageName={channelInformationStripPageName}>Informační pruh</EditButton>
	</MultiEditPage>
)

const ChannelCheckoutRule = Component((props) => (
	<Box>
		<HasOne field={'rule'}>
			<CheckboxField field={'isUserGroupRule'} label={'Omezeno na uživatelskou skupinu'} defaultValue={false} />
			<DependentCollapsible field={'isUserGroupRule'} condition={(it) => !!it}>
				<MultiSelectField label={'Skupiny'} field={'userGroups'} options={'UserGroup.internalName'} />
			</DependentCollapsible>
			<CheckboxField field={'isAnyProductRule'} label={'Omezeno na některý z produktů'} defaultValue={false} />
			<DependentCollapsible field={'isAnyProductRule'} condition={(it) => !!it}>
				<MultiSelectField
					label={'Pravidla produktů'}
					field={'anyProductRules'}
					options={'ProductRule.internalName'}
				/>
			</DependentCollapsible>
			<CheckboxField field={'isNoneProductRule'} label={'Zakázáno pro některý z produktů'} defaultValue={false} />
			<DependentCollapsible field={'isNoneProductRule'} condition={(it) => !!it}>
				<MultiSelectField
					label={'Pravidla produktů'}
					field={'noneProductRules'}
					options={'ProductRule.internalName'}
				/>
			</DependentCollapsible>
			<CheckboxField field={'isOrderPriceRule'} label={'Omezeno dle ceny objednávky'} defaultValue={false} />
			<DependentCollapsible field={'isOrderPriceRule'} condition={(it) => !!it}>
				<CentsField field={'minOrderCents'} label={'Od'} />
				<CentsField field={'maxOrderCents'} label={'Do'} />
			</DependentCollapsible>

			<CheckboxField field={'isAddressRule'} label={'Omezeno dle adresy'} defaultValue={false} />
			<DependentCollapsible field={'isAddressRule'} condition={(it) => !!it}>
				<SelectField field="country" options="Country.code" label="Země" />
				<TextField field={'postalCodes'} allowNewlines label={'PSČ'} description={'Jedno na řádek'} />
			</DependentCollapsible>
		</HasOne>
	</Box>
))

export const ChannelShippingPage = (
	<MultiEditPage
		pageName={channelShippingPageName}
		entities="ChannelShippingOption[channel.id = $id]"
		setOnCreate="(channel.id = $id)"
		orderBy="order"
		rendererProps={{
			title: 'Možnosti dopravy',
			sortableBy: 'order',
			navigation: <NavigateBackButton to={channelPageName}>Kanály</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<SelectField label="Dopravní metoda" options="ShippingMethod.internalName" field="shippingMethod" />
		<SelectField label="Sazba DPH" options="VatRate.internalName" field="vatRate" />
		<ChannelCheckoutRule />
		<Repeater field={'prices'} label={'Ceny'} orderBy={'priceCents'}>
			<CentsField field={'priceCents'} label={'Cena'} />
			<ChannelCheckoutRule />
		</Repeater>
	</MultiEditPage>
)

export const ChannelPaymentPage = (
	<MultiEditPage
		pageName={channelPaymentPageName}
		entities="ChannelPaymentOption[channel.id = $id]"
		setOnCreate="(channel.id = $id)"
		orderBy="order"
		rendererProps={{
			title: 'Možnosti platby',
			sortableBy: 'order',
			navigation: <NavigateBackButton to={channelPageName}>Kanály</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<SelectField label="Platební metoda" options="PaymentMethod.internalName" field="paymentMethod" />
		<SelectField label="Sazba DPH" options="VatRate.internalName" field="vatRate" />
		<ChannelCheckoutRule />
		<Repeater field={'prices'} label={'Ceny'} orderBy={'priceCents'}>
			<CentsField field={'priceCents'} label={'Cena'} />
			<ChannelCheckoutRule />
		</Repeater>
	</MultiEditPage>
)

export const ChannelFooterPage = (
	<EditPage
		pageName={channelFooterPageName}
		entity="Footer(channel.id = $id)"
		setOnCreate="(channel.id = $id)"
		rendererProps={{
			title: 'Patička',
			navigation: <NavigateBackButton to={channelPageName}>Kanály</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<Box heading="Obrázky">
			<TextField label="Access token pro Instagram feed" field="instagramFeedToken" />
			<ImageListField field={'images'} />
		</Box>

		<Repeater label="Hlavní položky" field="primaryItems" sortableBy="order">
			<TextField label="Název" field="title" />
			<TextAreaField label="Text" field="text" />
			<TextField label="Popisek odkazu" field="linkLabel" />
			<LinkField field="link" />
		</Repeater>

		<Repeater label="Seznamové položky" field="listItems" sortableBy="order">
			<TextField label="Název" field="title" />
			<LinkField field="titleLink" />

			<Repeater label={undefined} field="links" sortableBy="order">
				<TextField label="Popisek" field="label" />
				<LinkField field="link" />
				<IconSelectField label="Ikonka" field="icon" />
			</Repeater>
		</Repeater>
	</EditPage>
)

export const ChannelInformationStripPage = (
	<MultiEditPage
		pageName={channelInformationStripPageName}
		entities="InformationStrip[channel.id = $id]"
		setOnCreate="(channel.id = $id)"
		orderBy="priority"
		rendererProps={{
			title: 'Informační pruhy',
			sortableBy: 'priority',
			navigation: <NavigateBackButton to={channelPageName}>Kanály</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<SelectField
			label={'Barva'}
			options={[
				{ label: 'Žlutá', value: 'yellow' },
				{ label: 'Tyrkysová', value: 'cyan' },
				{ label: 'Červená', value: 'raspberry' },
				{ label: 'Fialová', value: 'purple' },
			]}
			field={'color'}
		/>
		<IconSelectField label="Ikonka" field="icon" />
		<RichTextField field={'text'} label={'Text'} />
		<div className={'horizontal-fields'}>
			<DateField field={'activeSince'} label={'Zobrazovat od'} dateFormat="yyy-MM-dd" />
			<DateField field={'activeUntil'} label={'Zobrazovat do'} dateFormat="yyy-MM-dd" />
		</div>
	</MultiEditPage>
)

export const ChannelHeaderMenuPage = (
	<EditPage
		pageName={channelHeaderMenuPageName}
		entity="HeaderMenu(channel.id = $id)"
		setOnCreate="(channel.id = $id)"
		rendererProps={{
			title: 'Hlavička',
			navigation: <NavigateBackButton to={channelPageName}>Kanály</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<TextField field={'phoneNumber'} label={'Telefon'} />
		<TextField field={'phoneDescription'} label={'Info u telefonu'} />
		<Repeater field={'items'} label={'Položky v menu'} sortableBy={'order'}>
			<TextField field={'caption'} label={'Popis'} />
			<LinkField field={'link'} />
		</Repeater>
	</EditPage>
)

import { Box, Field, TableCell, TextField } from '@contember/admin'
import * as React from 'react'
import { createCrudPages } from '../../utils'

export const TranslationDomainPages = createCrudPages({
	entityName: 'TranslationDomain',
	title: 'Překladové domény',
	listPageProps: {
		orderBy: 'name asc',
	},
	form: (
		<Box>
			<TextField field="identifier" label="Identifier" allowNewlines={false} />
			<TextField field="name" label="Name" allowNewlines={false} />
		</Box>
	),
	table: (
		<>
			<TableCell>
				<Field field="name" />
			</TableCell>
		</>
	),
})

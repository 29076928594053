import { Page } from '../../utils'
import { GenericPage } from '@contember/admin'
import * as React from 'react'
import { ImageImport } from '../../components'

export const ImageUploadPage = new Page(
	{ path: '/image-upload' },
	(
		<GenericPage pageName={'imageUpload'}>
			<h2>Nahrávání obrázků</h2>
			<ImageImport />
		</GenericPage>
	),
)

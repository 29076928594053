import { readXlsx, SpreadsheetFile } from '../../utils'
import * as React from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { Button, FileDropZone, FormGroup } from '@contember/admin'

const readArrayBuffer = (file: File): Promise<Uint8Array> => {
	return new Promise((resolve) => {
		const reader = new FileReader()
		reader.onload = (e) => {
			resolve(new Uint8Array(e.target!.result as ArrayBuffer))
		}
		reader.readAsArrayBuffer(file)
	})
}

interface XlsUploaderProps {
	onUpload: (workbook: SpreadsheetFile[]) => void
}

export const ProductImportXlsUploader: React.FC<XlsUploaderProps> = ({ onUpload }) => {
	const onDropAccepted = React.useCallback(
		async (files: File[]) => {
			const spreadsheets = await Promise.all(
				files
					.map(async (file) => [file, await readArrayBuffer(file)] as const)
					.map(async (result) => {
						const [file, buffer] = await result
						return readXlsx(file.name, buffer)
					}),
			)
			onUpload(spreadsheets)
		},
		[onUpload],
	)
	const onDropRejected = React.useCallback((rejections: FileRejection[]) => {
		const errors = rejections
			.flatMap((it) => it.errors)
			.map((it) => it.message)
			.join('\n')
		alert(`Nepodporovaný soubor: \n${errors}`)
	}, [])

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDropAccepted,
		onDropRejected,
		disabled: false,
		accept: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/wps-office.xlsx'],
		multiple: true,
		noKeyboard: true,
	})

	return (
		<FormGroup label={'Import z XLS'} useLabelElement={false}>
			<div
				{...getRootProps({
					style: {},
				})}
			>
				<input {...getInputProps()} />
				<div className="fileInput">
					<FileDropZone isActive={isDragActive} className="fileInput-dropZone">
						<div className="fileInput-cta">
							<Button size="small">Vyberte soubor k importu</Button>
							<span className="fileInput-cta-label">nebo jej přetáhněte</span>
						</div>
					</FileDropZone>
				</div>
			</div>
		</FormGroup>
	)
}

import { MultiEditPage, TextField, AltPersistButton, NumberField } from '@contember/admin'
import * as React from 'react'

export const vatRatePageName = 'vatRate'

export const vatRateRoutes = {
	[vatRatePageName]: { path: '/settings/vat-rates' },
}

export const VatRatePage = (
	<MultiEditPage
		entities="VatRate"
		pageName={vatRatePageName}
		rendererProps={{
			title: 'DPH sazby',
			persistButtonComponent: AltPersistButton,
		}}
	>
		<TextField label="Interní název" field="internalName" />
		<NumberField
			label="Sazba v %"
			field="rate"
			onWheel={(e) => {
				e.currentTarget.blur()
			}}
		/>
	</MultiEditPage>
)

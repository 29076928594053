import {
	Component,
	EntityAccessor,
	EntityListSubTree,
	Environment,
	Field,
	HasMany,
	Message,
	TextField,
	useEntity,
	useEntityList,
	useEntityListSubTree,
	useEnvironment,
} from '@contember/admin'
import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { LocaleSideDimension } from './LocaleSideDimension'
import slugify from '@sindresorhus/slugify'

export interface PerChannelUrlFieldInnerProps {
	createUrl: (entity: EntityAccessor, channel: EntityAccessor) => string | undefined
	softPrefix?: string
}

export const PerChannelUrlFieldInner = Component<PerChannelUrlFieldInnerProps>(
	({ createUrl, softPrefix }) => {
		const channels = useEntityListSubTree('channels')
		const links = useEntityList('links')
		const entity = useEntity()

		useEffect(() => {
			const linksArr = Array.from(links)
			for (const channel of Array.from(channels)) {
				const matchingLink = linksArr.find((it) => it.getEntity('channel').idOnServer === channel.idOnServer)
				if (matchingLink?.idOnServer) {
					// do not update
					continue
				}
				const url = createUrl(entity, channel)
				if (url === undefined) {
					continue
				}
				const urlWithPrefix = (softPrefix || '') + url
				if (!matchingLink) {
					links.createNewEntity((accessor) => {
						const entity = accessor()
						entity.connectEntityAtField('channel', channel)
						entity.getField('url').updateValue(urlWithPrefix)
					})
				} else if (matchingLink.getField('url').value !== urlWithPrefix) {
					matchingLink.getField('url').updateValue(urlWithPrefix)
				}
			}
		}, [channels, createUrl, entity, links, softPrefix])
		return (
			<>
				<HasMany field={'links'}>
					<Field field={'channel.internalName'} />
					<TextField field={'url'} label={'URL'} />
				</HasMany>
			</>
		)
	},
	() => {
		return (
			<>
				<HasMany field={'links'}>
					<Field field={'channel.id'} />
					<Field field={'channel.internalName'} />
					<Field field={'url'} />
				</HasMany>
				<EntityListSubTree entities="Channel" expectedMutation="none" alias={'channels'}>
					<Field field={'internalName'} />
					<Field field={'locale.code'} />
				</EntityListSubTree>
			</>
		)
	},
)

export const createLocalizedUrlFactory = (localizedField: string, environment: Environment) => {
	return (entity: EntityAccessor, channel: EntityAccessor): string | undefined => {
		const locales = environment.getDimension('locale')
		const matchingLocaleCode = channel.getField<string>('locale.code').value!
		if (!locales.includes(matchingLocaleCode)) {
			return undefined
		}
		const matchingLocaleDim = entity.getEntity({
			field: { field: 'locales', reducedBy: { locale: { code: matchingLocaleCode } } },
		})
		const name = matchingLocaleDim.getField<string>(localizedField).value
		return name ? slugify(name) : undefined
	}
}

interface PerChannelLocalizedUrlFieldProps extends Omit<PerChannelUrlFieldInnerProps, 'createUrl'> {
	localizedField: string
}

export const PerChannelLocalizedUrlField = Component<PerChannelLocalizedUrlFieldProps>(
	({ localizedField, ...innerProps }) => {
		const env = useEnvironment()
		const channels = useEntityListSubTree('channels')
		const locales = env.getDimension('locale')
		const missingDimension = useMemo(() => {
			for (const channel of Array.from(channels)) {
				const matchingLocaleCode = channel.getField<string>('locale.code').value!
				if (!locales.includes(matchingLocaleCode)) {
					return matchingLocaleCode
				}
			}
			return undefined
		}, [channels, locales])

		return (
			<>
				{missingDimension && (
					<Message type={'danger'}>
						Není vybrán jazyk {missingDimension}. URL pro něj nebude založena.
					</Message>
				)}
				<PerChannelUrlFieldInner {...innerProps} createUrl={createLocalizedUrlFactory(localizedField, env)} />
			</>
		)
	},
	({ localizedField, ...innerProps }, env) => {
		return (
			<>
				<LocaleSideDimension>
					<Field field={localizedField} />
				</LocaleSideDimension>
				<PerChannelUrlFieldInner {...innerProps} createUrl={createLocalizedUrlFactory(localizedField, env)} />
			</>
		)
	},
)

const createUrlFactory = (field: string) => {
	return (entity: EntityAccessor): string | undefined => {
		const name = entity.getField<string>(field).value
		return name ? slugify(name) : undefined
	}
}

interface PerChannelUrlFieldProps extends Omit<PerChannelUrlFieldInnerProps, 'createUrl'> {
	field: string
}

export const PerChannelUrlField = Component<PerChannelUrlFieldProps>(
	({ field, ...innerProps }) => <PerChannelUrlFieldInner {...innerProps} createUrl={createUrlFactory(field)} />,
	({ field, ...innerProps }, env) => (
		<>
			<Field field={field} />
			<PerChannelUrlFieldInner {...innerProps} createUrl={createUrlFactory(field)} />
		</>
	),
)

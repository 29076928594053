import {
	Box,
	CheckboxField,
	Component,
	DateField,
	Field,
	GenericCell,
	MultiSelectField,
	NumberField,
	SelectField,
	TextCell,
	TextField,
	useField,
} from '@contember/admin'
import * as React from 'react'
import { Fragment } from 'react'
import { BannerPositionEnumValues } from '../../../../api/model'
import {
	ActiveDateCell,
	CategoryMultiSelectField,
	DependentCollapsible,
	HasManyFilterCell,
	ImageField,
	LinkField,
	ProductCategoriesCell,
} from '../../components'
import { EnumCell } from '../../components/Generic/EnumCell'
import { createCrudPages } from '../../utils'

const positionLabels: Record<BannerPositionEnumValues, string> = {
	firstLevelCategory: 'Na kategorii první úrovně',
	aboveProductListing: 'Nad produkty ve výpisu',
	withinProductListing: 'Mezi produkty ve výpisu',
	productDetail: 'Detail produktu',
	checkout: 'Checkout',
	articleListing: 'Výpis článků',
	content: 'Obsah',
	brandsMenu: 'Megamenu - značky',
	productMenu: 'Megamenu - produty',
	discountMenu: 'Megamenu - slevy',
}

const bannerSize: Record<BannerPositionEnumValues | 'expertHelper', { desktop: string; mobile: string }> = {
	firstLevelCategory: { desktop: '680×500 px', mobile: '327×344 px' },
	withinProductListing: { desktop: '320×474 px', mobile: '158×300 px' },
	aboveProductListing: { desktop: 'šířka 1375 px', mobile: ' šířka 327 px' },
	checkout: { desktop: 'šířka 1375 px', mobile: 'šířka 327 px' },
	productDetail: { desktop: '672×264 px', mobile: '327×360 px' },
	articleListing: { desktop: '405×447 px', mobile: '327×478 px' },
	content: { desktop: 'šířka 1142 px', mobile: 'šířka 327 px' },
	brandsMenu: { desktop: '230×336 px', mobile: '327×478 px' },
	productMenu: { desktop: '230×336 px', mobile: '327×478 px' },
	discountMenu: { desktop: '230×336 px', mobile: '327×478 px' },
	expertHelper: { desktop: '400x400 px', mobile: '200x200 px' },
}

const allowedCategoryPositions: BannerPositionEnumValues[] = [
	'firstLevelCategory',
	'withinProductListing',
	'aboveProductListing',
	'productDetail',
	'productMenu',
]
const allowedBrandsPositions: BannerPositionEnumValues[] = [
	'withinProductListing',
	'aboveProductListing',
	'productDetail',
]

const options = Object.entries(positionLabels).map(([value, label]) => ({
	value: value,
	label: String(label),
}))

const ImageWithLabel = Component<{ field: string; label: string; type: 'desktop' | 'mobile' }>(
	(props) => {
		const position = useField<BannerPositionEnumValues>('position').value
		const identifier = useField<string>('templateKey').value
		const size = position ? bannerSize[identifier === 'expert' ? 'expertHelper' : position] : undefined
		const description = size ? 'Doporučená velikost obrázku: ' + size[props.type] : undefined

		return <ImageField field={props.field} label={props.label} description={description} />
	},
	(props) => <ImageField field={props.field} label={props.label} />,
)
export const BannerPages = createCrudPages({
	entityName: 'Banner',
	title: 'Bannery',
	gridPageProps: {
		// orderBy: [{ position: new Literal('asc') }, { priority: new Literal('asc') }],
		entities: 'Banner[$channelFilter]',
	},
	form: (
		<>
			<Box heading={'Zobrazení'}>
				<SelectField field={'position'} label={'Pozice'} options={options} />
				<TextField field="caption" label="Popis" />
				<LinkField field={'link'} />
				<ImageWithLabel field={'image'} label={'Desktop obrázek'} type={'desktop'} />
				<ImageWithLabel field={'mobileImage'} label={'Mobilní obrázek'} type={'mobile'} />
				<ImageField field={'largeImage'} label={'Velký obrázek (nepovinný)'} />
				<TextField field={'imageTitle'} label={'Titulek obrázku'} />

				<TextField
					field={'templateKey'}
					label={'Identifikátor šablony'}
					description="např: kategorie-pruh, expert"
				/>
				<DependentCollapsible field={'templateKey'} condition={(it) => !!it}>
					<TextField field={'title'} label={'Titulek'} />
					<TextField field={'mainText'} label={'Hlavní text'} allowNewlines />
					<TextField field={'secondaryText'} label={'Sekundární text'} allowNewlines />
					<TextField field={'tertiaryText'} label={'Terciární text'} allowNewlines />
					<TextField field={'buttonText'} label={'Text na tlačítku'} />
				</DependentCollapsible>
			</Box>
		</>
	),
	sideForm: (
		<>
			<Box>
				<TextField field="internalName" label="Interní název" />
				<SelectField field={'channel'} label={'Kanál'} options={'Channel.internalName'} />
			</Box>
			<Box heading={'Nastavení zobrazení'}>
				<NumberField
					field={'priority'}
					label={'Priorita'}
					defaultValue={0}
					onWheel={(e) => {
						e.currentTarget.blur()
					}}
				/>
				<div className={'horizontal-fields'}>
					<DateField field={'activeSince'} label={'Zobrazovat od'} dateFormat="yyy-MM-dd" />
					<DateField field={'activeUntil'} label={'Zobrazovat do'} dateFormat="yyy-MM-dd" />
				</div>
				<CheckboxField
					field={'isUserGroupRequired'}
					label={'Omezeno na uživatelskou skupinu'}
					defaultValue={false}
				/>
				<DependentCollapsible field={'isUserGroupRequired'} condition={(it) => !!it}>
					<MultiSelectField label={'Skupiny'} field={'userGroups'} options={'UserGroup.internalName'} />
				</DependentCollapsible>
				<DependentCollapsible
					field={'position'}
					condition={(it) => allowedCategoryPositions.includes(it as BannerPositionEnumValues)}
				>
					<CheckboxField field={'isCategoryRequired'} label={'Pouze v kategorii'} defaultValue={false} />
					<DependentCollapsible field={'isCategoryRequired'} condition={(it) => !!it}>
						<CategoryMultiSelectField field={'categories'} label={'Kategorie'} />
					</DependentCollapsible>
				</DependentCollapsible>
				<DependentCollapsible
					field={'position'}
					condition={(it) => allowedBrandsPositions.includes(it as BannerPositionEnumValues)}
				>
					<CheckboxField field={'isBrandRequired'} label={'Pouze pro značku'} defaultValue={false} />
					<DependentCollapsible field={'isBrandRequired'} condition={(it) => !!it}>
						<MultiSelectField field={'brands'} label={undefined} options={'Brand.name'} />
					</DependentCollapsible>
				</DependentCollapsible>
			</Box>
		</>
	),
	grid: (
		<>
			<TextCell header={'Název'} field={'internalName'} />
			<GenericCell header={'Kanál'}>
				<Field field="channel.internalName" />
			</GenericCell>
			<ActiveDateCell fromField={'activeSince'} toField={'activeUntil'} header={'Aktivní'} />
			<EnumCell field={'position'} header={'Pozice'} options={positionLabels} />
			<TextCell header={'Priorita'} field={'priority'} />

			<ProductCategoriesCell field="categories" onlyIfField="isCategoryRequired" />

			<HasManyFilterCell
				field="brands"
				onlyIfField="isBrandRequired"
				header="Značky"
				hidden={true}
				createWhere={(query, value) => ({ name: query })}
				render={({ entities }) => (
					<>
						{entities.map((entity) => (
							<Fragment key={entity.key}>
								{entity.getField('name').value}
								<br />
							</Fragment>
						))}
					</>
				)}
			>
				<Field field="name" />
			</HasManyFilterCell>

			<HasManyFilterCell
				field="userGroups"
				onlyIfField="isUserGroupRequired"
				header="Uživatelské skupiny"
				hidden={true}
				createWhere={(query, value) => ({ internalName: query })}
				render={({ entities }) => (
					<>
						{entities.map((entity) => (
							<Fragment key={entity.key}>
								{entity.getField('internalName').value}
								<br />
							</Fragment>
						))}
					</>
				)}
			>
				<Field field="internalName" />
			</HasManyFilterCell>
		</>
	),
})

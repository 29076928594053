import * as React from 'react'
import { Spreadsheet, SpreadsheetFile } from '../../utils'
import { Box, Button, Checkbox, Heading } from '@contember/admin'
import { SheetTable } from './SheetTable'
import { useCallback, useEffect, useMemo, useState } from 'react'

const useMergedSpreadsheet = (sheetFile: SpreadsheetFile): Spreadsheet | null => {
	return useMemo(() => {
		if (sheetFile.sheets.length <= 1) {
			return null
		}
		const sheets = sheetFile.sheets
		const firstSheet = sheets[0]
		for (const sheet of sheets) {
			if (sheet.header.length !== firstSheet.header.length) {
				return null
			}
			for (let i = 0; i < sheet.header.length; i++) {
				if (sheet.header[i].key !== firstSheet.header[i].key) {
					return null
				}
			}
		}
		return {
			header: firstSheet.header,
			name: 'Spojený list',
			data: sheets.flatMap((it) => it.data),
		}
	}, [sheetFile])
}

interface SheetDetailProps {
	sheet: Spreadsheet
	onToggleSheet: (sheet: Spreadsheet) => void
	isSelected: boolean
}

export const SheetDetail: React.FC<SheetDetailProps> = ({ sheet, onToggleSheet, isSelected }) => {
	return (
		<Box
			key={sheet.name}
			heading={
				<>
					<Checkbox onChange={() => onToggleSheet(sheet)} value={isSelected}>
						{sheet.name} ({sheet.data.length} řádků)
					</Checkbox>
				</>
			}
		>
			<SheetTable sheet={sheet} limit={5} />
		</Box>
	)
}

export interface SheetSelectionProps {
	spreadsheets: Spreadsheet[]
	onSave: (sheet: Spreadsheet[]) => void
}

export const SheetSelection: React.FC<SheetSelectionProps> = ({ spreadsheets, onSave }) => {
	const [selectedSheets, setSelectedSheets] = useState(spreadsheets)
	const onToggleSheet = useCallback((spreadsheet: Spreadsheet) => {
		setSelectedSheets((sheets) =>
			sheets.includes(spreadsheet) ? sheets.filter((it) => it !== spreadsheet) : [...sheets, spreadsheet],
		)
	}, [])

	return (
		<>
			<Button onClick={() => setSelectedSheets([])}>Zrušit výber všech</Button>
			{spreadsheets.map((sheet) => (
				<SheetDetail
					key={sheet.name}
					sheet={sheet}
					onToggleSheet={onToggleSheet}
					isSelected={selectedSheets.includes(sheet)}
				/>
			))}
			<Button onClick={() => onSave(selectedSheets)}>Uložit</Button>
		</>
	)
}

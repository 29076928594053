import {
	Block,
	Box,
	CheckboxField,
	Component,
	DateCell,
	DiscriminatedBlocks,
	Field,
	FormGroup,
	GenericCell,
	SelectField,
	TextAreaField,
	TextCell,
	TextField,
	useEntity,
	useEnvironment,
	useField,
} from '@contember/admin'
import * as React from 'react'
import { Fragment } from 'react'
import {
	CollapsibleBox,
	HasManyFilterCell,
	ImageField,
	IsPublishedField,
	LinkField,
	LinkUrlField,
	LocaleMultiSelectField,
	SeoForm,
} from '../../components'
import { ContentEditor } from '../../components/Content/ContentEditor'
import { VideoUploadWithDescriptionField } from '../../components/Content/VimeoUploadField'
import { createCrudPages, localizedCell } from '../../utils'

const ArticlePreviewUrl = Component(
	(props) => {
		const channelCode = useField<string>('channel.code').value
		const url = useField('link.url').value
		const entity = useEntity()
		if (!channelCode || !url) {
			return null
		}
		const baseUrl = useEnvironment().getValue(
			channelCode === 'SK' ? 'SK_FRONTEND_BASEPATH' : 'CZ_FRONTEND_BASEPATH',
		)
		const previewUrl = entity.idOnServer ? `${baseUrl}${url}?preview=${entity.idOnServer}` : undefined
		return (
			<FormGroup
				label={'Náhled článku'}
				labelDescription={
					previewUrl
						? 'URL je pouze pro neveřejný náhled. Nesdílejte ji.'
						: 'Pro zobrazení URL náhledu článek nejdříve uložte.'
				}
			>
				{!!previewUrl && (
					<a href={previewUrl} target="_blank" rel="noopener">
						<small>Otevřít náhled</small>
					</a>
				)}
			</FormGroup>
		)
	},
	() => {
		return (
			<>
				<Field field={'channel.code'} />
			</>
		)
	},
)

export const ArticlePages = createCrudPages({
	entityName: 'Article',
	title: 'Magazín',
	listPageProps: {
		entities: 'Article[$channelFilter]',
	},
	form: (
		<>
			<Box>
				<ImageField field={'image'} />
				<DiscriminatedBlocks field="headerType" label="Hlavička">
					<Block discriminateBy="normal" label="Běžná"></Block>
					<Block discriminateBy="smallCover" label="S malým cover obrázkem"></Block>
					<Block discriminateBy="largeCover" label="S velkým cover obrázkem a videem">
						<VideoUploadWithDescriptionField
							field="header.video"
							videoLabel={undefined}
							descriptionLabel="Popis"
						/>
						<TextField field={'header.primaryText'} label={'Odkaz'} />
						<LinkField field={'header.primaryLink'} />
						<TextField field={'header.secondaryText'} label={'Text vedle odkazu'} />
						<TextField field={'header.title'} label={'Nadpis'} />
						<TextAreaField field={'header.lead'} label={'Perex'} />
						<TextField field={'header.buttonText'} label={'Tlačítko'} />
						<LinkField field={'header.buttonLink'} />
					</Block>
				</DiscriminatedBlocks>
			</Box>
			<Box>
				<ContentEditor
					leadingElements={[
						{
							field: 'title',
							placeholder: 'Titulek',
							format: 'plainText',
							render: (props) => <h1 style={{ fontSize: '2.5em', fontWeight: 600 }}>{props.children}</h1>,
						},
						{
							field: 'lead',
							placeholder: 'Perex',
							format: 'plainText',
							render: (props) => <p style={{ fontSize: '1.5em' }}>{props.children}</p>,
						},
					]}
				/>
				<CollapsibleBox heading={'Nastavení SEO'}>
					<SeoForm titleDerivedFrom={'title'} />
				</CollapsibleBox>
			</Box>
		</>
	),
	sideForm: (
		<Box>
			<IsPublishedField field={'publishedAt'} />
			<CheckboxField field={'hideInListing'} label={'Skrýt ve výpisu magazínu'} defaultValue={false} />
			<SelectField
				field={'channel'}
				label={'Kanál'}
				options={'Channel'}
				renderOption={(accessor) => accessor.getField('internalName').value}
				optionsStaticRender={
					<>
						<Field field={'code'} />
						<Field field={'internalName'} />
					</>
				}
			/>
			<LinkUrlField derivedFrom={'title'} label={'URL'} persistedSoftPrefix={'magazin/'} />
			<ArticlePreviewUrl />
			<LocaleMultiSelectField
				field={'categories'}
				entities={'ProductCategory'}
				localizedField={'name'}
				label={'Kategorie'}
			/>
			<LocaleMultiSelectField field={'topics'} entities={'ArticleTopic'} localizedField={'name'} label={'Téma'} />
		</Box>
	),
	grid: (
		<>
			<TextCell field="title" header="Titulek" />
			<DateCell
				field="publishedAt"
				header="Datum publikace"
				initialOrder="desc"
				format={(date) => date.toLocaleDateString()}
			/>
			{localizedCell((locale) => (
				<HasManyFilterCell
					field="categories"
					header={`Kategorie\u00A0(${locale})`}
					hidden={locale !== 'cs'}
					createWhere={(query) => ({
						locales: {
							locale: { code: { eq: locale } },
							name: query,
						},
					})}
					render={({ entities }) => (
						<>
							{entities.map((entity) => (
								<Fragment key={entity.key}>
									{entity.getField(`locales(locale.code='${locale}').name`).value}
									<br />
								</Fragment>
							))}
						</>
					)}
				>
					<Field field={`locales(locale.code='${locale}').name`} />
				</HasManyFilterCell>
			))}

			{localizedCell((locale) => (
				<HasManyFilterCell
					field="topics"
					header={`Témata\u00A0(${locale})`}
					hidden={locale !== 'cs'}
					createWhere={(query) => ({
						locales: {
							locale: { code: { eq: locale } },
							name: query,
						},
					})}
					render={({ entities }) => (
						<>
							{entities.map((entity) => (
								<Fragment key={entity.key}>
									{entity.getField(`locales(locale.code='${locale}').name`).value}
									<br />
								</Fragment>
							))}
						</>
					)}
				>
					<Field field={`locales(locale.code='${locale}').name`} />
				</HasManyFilterCell>
			))}
			<TextCell field="channel.internalName" header="Web" />
			<GenericCell header="Zveřejněno">
				<Field
					field="publishedAt"
					format={(value) => {
						if (value) {
							const now = new Date()
							const from = new Date(value.toString())
							if (from <= now) {
								return 'Ano'
							}
						}
						return 'Ne'
					}}
				/>
			</GenericCell>
		</>
	),
	gridPageProps: {
		enableRemoving: true,
	},
})

import {
	Component,
	DataBindingProvider,
	DateFieldView,
	EntityListSubTree,
	FeedbackRenderer,
	Field,
	TableCell,
	TableRenderer,
	useField,
} from '@contember/admin'
import { ProductImportBatchStatus } from './ProductImportBatchStatus'
import { EditButton } from '../Generic/EditButton'
import * as React from 'react'

const BatchButton = Component<ImportBatchListProps>(
	({ viewPageName, mappingFieldName }) => {
		const mappedAt = useField('mappedAt')
		const approvedAt = useField('approvedAt')
		if (!mappedAt.value) {
			return <EditButton pageName={mappingFieldName}>Namapovat</EditButton>
		}
		return <EditButton pageName={viewPageName}>{approvedAt.value ? 'Zobrazit' : 'Ke schválení'}</EditButton>
	},
	() => (
		<>
			<Field field={'mappedAt'} />
			<Field field={'approvedAt'} />
		</>
	),
)

interface ImportBatchListProps {
	viewPageName: string
	mappingFieldName: string
}

export const ProductImportBatchList: React.FC<ImportBatchListProps> = (props) => {
	return (
		<DataBindingProvider stateComponent={FeedbackRenderer}>
			<EntityListSubTree
				entities={{
					entityName: 'ProductImportBatch',
					filter: { importedAt: { isNull: true } },
				}}
				orderBy={'createdAt desc'}
				listComponent={TableRenderer}
			>
				<TableCell>
					<Field field={'fileName'} />
				</TableCell>
				<TableCell>
					<ProductImportBatchStatus />
				</TableCell>
				<TableCell>
					Vytvořeno v <DateFieldView field="createdAt" />
				</TableCell>
				<TableCell>
					<BatchButton {...props} />
				</TableCell>
			</EntityListSubTree>
		</DataBindingProvider>
	)
}

import { AltPersistButton, MultiEditPage, TextField } from '@contember/admin'
import React from 'react'
import { Page } from '../../utils'

export const IconPage = new Page(
	{ path: '/icons' },
	(
		<MultiEditPage
			entities="Icon"
			pageName={'icon'}
			rendererProps={{
				title: 'Ikonky',
				persistButtonComponent: AltPersistButton,
			}}
		>
			<TextField label="Kód" field="code" />
			<TextField label="Interní název" field="internalName" />
		</MultiEditPage>
	),
)

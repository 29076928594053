import { ProjectConfig } from '@contember/admin'
import { csCZ } from '@contember/admin-i18n'
import * as pages from './src/pages'
import { Page, PageSet, tuple } from './src/utils'

const sets = Object.values({ ...pages }).filter((it): it is PageSet => it instanceof PageSet)
const singlePageRoutes = Object.fromEntries(
	Object.values({ ...pages })
		.filter((it): it is Page<any> => it instanceof Page)
		.map((it) => tuple(it.pageName, it.route)),
)
const setRoutes = sets.map((it) => it.routes)
const routes = {
	dashboard: { path: '/' },
	translationDomainList: { path: `/translations/domain` },
	translationDomainCreate: { path: `/translations/domain/new` },
	translationDomainEdit: { path: `/translations/domain/:id` },

	translationCatalogueList: { path: `/translations/catalogue` },
	translationCatalogueCreate: { path: `/translations/catalogue/new` },
	translationCatalogueEdit: { path: `/translations/catalogue/:id` },

	translationValue: { path: `/translations/value` },
	...pages.localeRoutes,
	...pages.vatRateRoutes,
	...pages.currencyRoutes,
	...pages.channelRoutes,
	...singlePageRoutes,
}
const config: ProjectConfig[] = [
	{
		project: 'endorphinrepublic',
		stage: 'live',
		component: () => import('./src'),
		routes: setRoutes.reduce((it, acc) => ({ ...acc, ...it }), routes),
		defaultLocale: 'cs-CZ',
		dictionaries: {
			'cs-CZ': csCZ,
		},
	},
]

export default config

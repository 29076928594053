import { AltPersistButton, MultiEditPage, TextField } from '@contember/admin'
import * as React from 'react'
import { Page } from '../../utils'
import { LocaleSideDimension } from '../../components'

export const ServiceTypesPage = new Page(
	{ path: '/service-types' },
	(
		<MultiEditPage
			entities="ServiceType"
			pageName={'serviceTypes'}
			rendererProps={{
				title: 'Typy služeb',
				persistButtonComponent: AltPersistButton,
				sortableBy: 'order',
			}}
		>
			<LocaleSideDimension>
				<TextField label="Název" field="name" />
			</LocaleSideDimension>
		</MultiEditPage>
	),
)
